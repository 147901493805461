import { createSelector } from 'reselect';
import { isEmpty } from 'lodash-es';
import { ICatalogProduct } from 'types/catalog';
import { IState } from 'store/root-reducer';
import { IFacet, ISelectedFacet, IFilter } from 'types/facets';
import { decorateFacets } from 'utils/facet-utils';
import { ISearchMeta, ISearchProductsState, ISearchCategory } from './reducer';

export const productsEntitySelector = (state: IState): ISearchProductsState => state.app.searchProducts;

export const selectSearchProducts = createSelector(productsEntitySelector, ({ items }): ICatalogProduct[] => items);
export const selectSearchProductsMeta = createSelector(
  productsEntitySelector,
  ({ searchMeta }): ISearchMeta => searchMeta
);
export const selectSearchProductsFacets = createSelector(productsEntitySelector, ({ facets }): IFacet[] =>
  decorateFacets(facets)
);

export const selectAllSearchDawnBrandsFacets = createSelector(
  selectSearchProductsFacets,
  (facets): string[] => facets.find((item) => item.key === 'brand')?.values || []
);

export const selectSearchProductsDawnBrands = createSelector(productsEntitySelector, ({ dawnBrands }): IFilter[] =>
  dawnBrands.map((item) => {
    return { key: 'brand', value: item };
  })
);

export const selectSearchProductsSelectedFacets = createSelector(
  productsEntitySelector,
  ({ selectedFacets }): ISelectedFacet[] => selectedFacets
);

export const selectSearchProductsCategories = createSelector(
  productsEntitySelector,
  ({ categories }): ISearchCategory[] => categories.categoriesList
);

export const selectSearchProductsSelectedCategories = createSelector(
  productsEntitySelector,
  ({ categories }): string[] => categories.selectedCategories
);

export const selectSearchPaginationCurrent = createSelector(
  selectSearchProductsMeta,
  ({ offset, limit }) => offset / limit
);
export const selectSearchPaginationTotal = createSelector(selectSearchProductsMeta, ({ total, limit }) =>
  Math.ceil(total / limit)
);
export const selectCurrentSearchProductsRange = createSelector(selectSearchProductsMeta, ({ offset, total, limit }) => {
  return [offset + 1, Math.min(total, offset + limit)];
});
export const selectSearchProductsTotal = createSelector(selectSearchProductsMeta, (meta) => meta.total);

export const selectSearchSkusTotal = createSelector(selectSearchProductsMeta, (meta) => meta.totalSkus);

export const selectSearchSuggestions = createSelector(
  productsEntitySelector,
  ({ searchSuggestions }) => searchSuggestions
);
export const selectTemporarySearchProductsCategories = createSelector(
  productsEntitySelector,
  ({ temporaryCategories }): ISearchCategory[] => temporaryCategories.categoriesList
);
export const selectTemporarySearchProductsSelectedCategories = createSelector(
  productsEntitySelector,
  ({ temporaryCategories }): string[] => temporaryCategories.selectedCategories
);
export const selectTemporarySearchFacets = createSelector(productsEntitySelector, ({ temporaryFacets }) =>
  decorateFacets(temporaryFacets)
);
export const selectTemporarySearchSelectedFacets = createSelector(
  productsEntitySelector,
  ({ temporarySelectedFacets }): ISelectedFacet[] => temporarySelectedFacets
);

export const selectExactMatchProductInfo = createSelector(productsEntitySelector, ({ items, exactMatchSku }) => {
  return !exactMatchSku || isEmpty(items)
    ? null
    : {
        productKey: items[0].productKey,
        sku: exactMatchSku,
      };
});
export const selectCorporateSearchLinks = createSelector(
  productsEntitySelector,
  ({ corporateSearchLinks }) => corporateSearchLinks
);
