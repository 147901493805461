import { takeLatest, put, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import {
  setActiveRegistrationStep,
  validationWithCustomerNumberAndZipCode,
  validationWithCustomerNumberAndZipCodeActionConstants,
} from '../actions';

export interface IValidationWithCustomerNumberAndZipCodePayload {
  customerNumber: string;
  zipcode: string;
  onSuccessCallBack?: (payload: IValidationWithCustomerNumberAndZipCodePayload) => void;
}

export function* validationWithCustomerNumberAndZipCodeSagaHandler({
  payload,
}: IAction<IValidationWithCustomerNumberAndZipCodePayload>) {
  yield put(
    doRequest<IValidationWithCustomerNumberAndZipCodePayload>(
      validationWithCustomerNumberAndZipCode,
      Endpoints.VALIDATION_WITH_CUSTOMER_NUMBER_AND_ZIP_CODE,
      'post',
      payload
    )
  );
}

export function* validationWithCustomerNumberAndZipCodeSuccessSagaHandler({ payload }: IAction<any>) {
  const data = yield take(validationWithCustomerNumberAndZipCodeActionConstants[Actions.SUCCESS]);

  if (payload.onSuccessCallBack && !data.payload.locations.length) {
    yield put(setActiveRegistrationStep(0));
    payload.onSuccessCallBack();
  }
}

export default function* validationWithCustomerNumberAndZipCodeSaga() {
  yield takeLatest(
    validationWithCustomerNumberAndZipCodeActionConstants[Actions.REQUEST],
    validationWithCustomerNumberAndZipCodeSagaHandler
  );
  yield takeLatest(
    validationWithCustomerNumberAndZipCodeActionConstants[Actions.REQUEST],
    validationWithCustomerNumberAndZipCodeSuccessSagaHandler
  );
}
