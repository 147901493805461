import { FC, HTMLAttributes } from 'react';
import { get } from 'lodash-es';
import { useContentstackContent } from 'hooks/use-contentstack-content';

interface IContentstackLinkComponentProps extends HTMLAttributes<HTMLAnchorElement> {
  contentKey: string;
  target?: string;
}

export interface IContentstackLink {
  href?: string;
  title?: string;
}

export const ContentstackLink: FC<IContentstackLinkComponentProps> = (props) => {
  const { contentKey, target, ...elementProps } = props;
  const content = useContentstackContent();
  const link: IContentstackLink = get(content, contentKey, {});
  return (
    <a {...elementProps} href={link.href} target={target}>
      {link.title}
    </a>
  );
};
