import React, { FC, useEffect, useRef, useState } from 'react';
import { COR_NAV_ORANGE, COR_NAV_PANEL_HEADER } from 'corporate-ui/cor-constants';
import { mapCategoryKeyToUrl } from 'utils/map-category-key';
import { IAllProducts, IAllProductsBreadcrumb } from 'components/layout/cor-header/cor-header.interface';
import { IconMenuArrow } from 'components/icons/icon-menu-arrow';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { HEADER_STATIC_LABELS } from 'components/layout/cor-header/cor-header.constants';

import './main-nav-drill-down-submenu.scss';

export interface ISubnavPanel {
  data: IAllProducts;
  activePanels: string[];
  clickPanel: any;
  setHeight: any;
  isOpen: boolean;
}

export const MainNavDrillDownSubmenu: FC<ISubnavPanel> = ({
  data,
  activePanels,
  clickPanel,
  setHeight,
  isOpen = false,
}) => {
  const [isActive, setIsActive] = useState<boolean>();
  const { isMobile } = useBreakpoint();
  const noderef = useRef<any>();
  const { links, id, back } = data;
  const isPrevious: boolean = activePanels.includes(id) && !isActive;

  // try to get the height of the container, then return 0...
  const getNodeHeight = (node, height, count) =>
    !!node && height < 1 && count < 10 ? getNodeHeight(node, node.clientHeight, count + 1) : height;

  useEffect(() => {
    if (activePanels[activePanels.length - 1] === id) {
      setHeight(isOpen ? getNodeHeight(noderef.current, 0, 1) : 0);
    }
    setIsActive(activePanels[activePanels.length - 1] === id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePanels, id, setHeight, isOpen, data]);

  const breadcrumbLength: number = back ? back?.breadcrumbs.length : 2;

  const buildCrumb = (crumb) => {
    if (!crumb) return;
    const { key, name } = crumb;
    const isRoot = key === 'root';
    return {
      key: isRoot ? key : 'all',
      name: isRoot ? name : HEADER_STATIC_LABELS.ALL_CATEGORIES,
    };
  };

  const crumb: any =
    (back && back?.breadcrumbs[breadcrumbLength - 2]) || buildCrumb(back?.breadcrumbs[breadcrumbLength - 1]);

  const buildProductPath = (breadcrumbs: IAllProductsBreadcrumb[]) =>
    breadcrumbs.length ? mapCategoryKeyToUrl(breadcrumbs[breadcrumbs.length - 1].key) : '/';

  const handleClickItem = (e, next_catkey, crumbs, hasNext): void => {
    // if the item has a next/child item, the lable remains a link but the arrow activates the child panel
    // if the item doesn't have a next/child, both the label and the arrow are links
    if (hasNext) {
      clickPanel(null, next_catkey, false, false);
    } else {
      clickPanel(e, next_catkey, buildProductPath(crumbs || []), true);
    }
    return;
  };

  return (
    <div ref={noderef} className={`subnav-group${isActive ? ' is-active' : ''}${isPrevious ? ' is-previous' : ''}`}>
      {!back ||
        (back !== undefined &&
          (crumb.key !== 'root' ? (
            <button
              className="subnav-group__back-button"
              aria-label={`Back to ${crumb.name}`}
              onClick={() => clickPanel(null, back.id, false)}
            >
              <IconMenuArrow dir={'left'} color={COR_NAV_ORANGE} />
              <span className="back-button-label">Back to {crumb.name}</span>
            </button>
          ) : (
            <span className="subnav-group__heading">{crumb.name}</span>
          )))}
      <ul className={`columns-${Math.ceil(links.length / 5)}`}>
        {links.map(({ categoryKey, categoryName, next, hasNext, breadcrumbs }, index) => {
          return (
            <li key={`${id}-${index}`}>
              <div className={`subnav-group__link${!back ? ' is-root' : ''}`}>
                <button
                  className="subnav-group__link__link"
                  aria-label={categoryName}
                  onClick={(event) => handleClickItem(event, categoryKey, breadcrumbs, hasNext)}
                >
                  {categoryName}
                </button>
                {isMobile && (
                  <button
                    className="subnav-group__link__next"
                    aria-label={`Go to ${next}`}
                    onClick={(event) => {
                      handleClickItem(event, next, breadcrumbs || false, hasNext);
                    }}
                  >
                    <IconMenuArrow
                      color={isMobile ? COR_NAV_PANEL_HEADER : COR_NAV_ORANGE}
                      height={isMobile ? 14 : 12}
                    />
                  </button>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
