import './cor-small-card-product.scss';

import React from 'react';
import {
  ICorFeaturedContentCardVariant,
  ICorFeaturedContentEntry,
} from 'corporate-components/cor-featured-content/cor-featured-content.interface';
import { CorContentstackImage, CorContentstackLink } from 'corporate-components/cor-contentstack';
import { CorTypography } from 'corporate-ui';
import { useContent } from 'hooks/use-content';

const CorSmallCardProduct = ({ contentKeyBase }: ICorFeaturedContentCardVariant) => {
  const { getContentByKey } = useContent();
  const entry: ICorFeaturedContentEntry = getContentByKey(contentKeyBase, {});

  return (
    <div className="cor-featured-content__card-product cor-featured-content__card cor-featured-content__card--small-size">
      <CorContentstackLink
        className="cor-featured-content__card-product__image__container cor-featured-content__card__image__container"
        contentKey={`${contentKeyBase}.pageUrl`}
        fallbackElement="div"
      >
        {!!entry?.image && (
          <CorContentstackImage
            contentKey={`${contentKeyBase}.image`}
            className="cor-featured-content__card-product__image cor-featured-content__card__image"
          />
        )}
      </CorContentstackLink>
      <div className="cor-featured-content__card-product__description">
        {!!entry?.title && (
          <CorTypography
            variant="h4"
            className="cor-featured-content__card-product__description__category"
            data-testid="cor-featured-content__card-product__title"
          >
            {entry?.title}
          </CorTypography>
        )}

        {!!entry?.description && (
          <CorTypography
            variant="body-regular"
            className="cor-featured-content__card-product__description__content"
            data-testid="cor-featured-content__card-product__description"
          >
            {entry?.description}
          </CorTypography>
        )}
      </div>
    </div>
  );
};

export default React.memo(CorSmallCardProduct);
