import { useState, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { CorContentstackHtml } from 'corporate-components';
import { Modal } from 'components/modals/common/modal';
import { ModalHeader } from 'components/modals/common/modal/components';
import { useContent } from 'hooks/use-content';
import { ContentstackText } from 'components/contentstack';
import { CustomCheckbox } from 'components/custom-checkbox';
import { CorButton } from 'components/cor-button';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { IModalProps } from 'components/modals/common/modal';
import { useLocalStorage } from 'hooks/use-local-storage';
import { CorContentStackLanguagesMap } from 'constants/cor-locale.enum';

import './geolocation-redirect-modal.scss';

const PATH_TO_MODAL = 'modals.0.geolocation_redirect_modal.0';
const REDIRECT_TEXT_LINK_ID = 'geolocation-redirect-modal__description--link-text';

export interface IGeolocationRedirectModalProps extends IModalProps {
  isOpen: boolean;
  countrySite: string;
  regionSite: string;
  onClose: () => void;
}

interface IGeolocationCountry {
  value: string;
  key: string;
}

export const GeolocationRedirectModal: FC<IGeolocationRedirectModalProps> = ({
  isOpen,
  regionSite,
  countrySite,
  onClose,
}) => {
  const { getContentByKey } = useContent();
  const history = useHistory();
  const [, setLocaleValue] = useLocalStorage('locale', null);
  const [, setPreferenceValue] = useLocalStorage('preference', null);
  const { isDesktop, isMobile } = useBreakpoint();
  const [isSelectedRemember, setIsSelectedRemember] = useState(false);
  const countriesList = getContentByKey(`${PATH_TO_MODAL}.countries_list.0.countries_list.value`, []);
  const country: IGeolocationCountry = countriesList?.filter(
    (country: IGeolocationCountry) => country?.key === countrySite
  )?.[0];

  const setRedirect = (url) => {
    const localeCode = CorContentStackLanguagesMap.get(url);
    if (isSelectedRemember) {
      setPreferenceValue({ [regionSite]: localeCode });
    }
    setLocaleValue(localeCode);
    onClose();
  };

  const handleRedirect = () => {
    setRedirect(countrySite);
    history.push(countrySite);
  };

  const handleStay = () => {
    setRedirect(regionSite);
  };

  return (
    <Modal
      className="geolocation-redirect-modal__content"
      isOpen={isOpen}
      onClose={onClose}
      size="medium"
      withBackdropClick
    >
      <ModalHeader titleContentstackPath={`${PATH_TO_MODAL}.heading`} />
      <div className="geolocation-redirect-modal__description">
        <CorContentstackHtml
          onClick={(e) => {
            const target = e.target as Element;
            if (target?.classList.contains(REDIRECT_TEXT_LINK_ID)) handleRedirect();
          }}
          contentKey={`${PATH_TO_MODAL}.description`}
          interpolateParams={{
            country: country?.value,
            link: `<span class="${REDIRECT_TEXT_LINK_ID}">${getContentByKey(
              `${PATH_TO_MODAL}.description_link`,
              ''
            )}</span>`,
          }}
        />
      </div>
      {isMobile && (
        <CustomCheckbox
          ariaLabel="remember"
          id="geolocation-redirect-modal__checkbox"
          className="geolocation-redirect-modal__checkbox"
          label={getContentByKey(`${PATH_TO_MODAL}.checkbox_label`, '')}
          selected={isSelectedRemember}
          onCheckboxClick={() => {
            setIsSelectedRemember(!isSelectedRemember);
          }}
        />
      )}

      <div className="geolocation-redirect-modal__buttons">
        <CorButton color="secondary" className="geolocation-redirect-modal__buttons--redirect" onClick={handleRedirect}>
          <ContentstackText
            contentKey={`${PATH_TO_MODAL}.redirect_button`}
            interpolateParams={{ country: country?.value }}
          />
        </CorButton>
        <CorButton color="primary" className="geolocation-redirect-modal__buttons--stay" onClick={handleStay}>
          <ContentstackText contentKey={`${PATH_TO_MODAL}.stay_button`} />
        </CorButton>
      </div>
      {isDesktop && (
        <CustomCheckbox
          ariaLabel="remember"
          id="geolocation-redirect-modal__checkbox"
          className="geolocation-redirect-modal__checkbox"
          label={getContentByKey(`${PATH_TO_MODAL}.checkbox_label`, '')}
          selected={isSelectedRemember}
          onCheckboxClick={() => {
            setIsSelectedRemember(!isSelectedRemember);
          }}
        />
      )}
    </Modal>
  );
};
