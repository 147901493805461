import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  selectIsMsmUser,
  selectIsMsrUser,
  selectIsCsrUser,
  selectIsInvoiceOnlyCustomer,
  selectIsBlockedAccount,
  selectIsImpersonateModeOn,
} from 'store/auth/selectors';
import { selectQuotesRequested } from 'store/quotes/selectors';
import { getQuotesRequested } from 'store/quotes/actions';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { PAYMENTS_ROUTE_PARTIAL, Routes } from 'constants/routes.enum';
import { ContentstackImage, ContentstackLink, ContentstackText } from 'components/contentstack/';
import { PermissionAccess } from 'components/permission-access';
import { Permissions } from 'constants/permissions';
import { AccessDefinitions } from 'constants/access-definitions.enum';
import iconDashboard from 'assets/images/icons/icon-dashboard.svg';
import iconQuotes from 'assets/images/icons/icon-quote-list.svg';
import { MyAccountMenu } from 'features/my-account-menu';
import { isShopLocaleInternational } from 'utils/is-shop-locale-international';
import { AccountWarning } from 'features/account-warning';
import { EnvironmentNotification } from 'components/environment-notification';
import { useEnvironment } from 'hooks/use-environment';
import { ImpersonationModeHeader } from 'components/impersonation-mode-header';

import './internal-user-header.scss';

interface IInternalUserHeader {
  contentstackPath?: string;
}

export const InternalUserHeader: FC<IInternalUserHeader> = ({ contentstackPath = 'header[0]' }) => {
  const dispatch = useDispatch();
  const { isDesktop, isMobile } = useBreakpoint();
  const logoUrl = isDesktop ? `${contentstackPath}.logo.logo` : `${contentstackPath}.logo.mobile_logo`;
  const isMsr = useSelector(selectIsMsrUser);
  const isMsm = useSelector(selectIsMsmUser);
  const isCsr = useSelector(selectIsCsrUser);
  const numberOfQuotes = useSelector(selectQuotesRequested);
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);
  const isImpersonateModeOn = useSelector(selectIsImpersonateModeOn);
  const isBlockedAccount = useSelector(selectIsBlockedAccount);
  const location = useLocation();
  const { isProduction } = useEnvironment();

  useEffect(() => {
    if (isMsr || isMsm) {
      dispatch(getQuotesRequested.request());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isPageUnderPayment = location.pathname.includes(PAYMENTS_ROUTE_PARTIAL);

  return (
    <>
      {!isProduction && (isInvoiceOnlyUser || isShopLocaleInternational()) && <EnvironmentNotification />}
      {isImpersonateModeOn && <ImpersonationModeHeader />}
      <div className="internal-user-header">
        <div className="grid-container">
          <div className="internal-user-header__top-row-inner">
            <div className="internal-user-header__logo">
              <ContentstackImage contentKey={logoUrl} />
            </div>
            <div className="internal-user-header__controls">
              <div className="internal-user-header__account-menu">
                <MyAccountMenu contentstackPath={contentstackPath} />
              </div>
            </div>
            {isMsr && (
              <div className="internal-user-header__sharepoint-link">
                {isDesktop && <ContentstackLink contentKey="page_content.sharepoint_training_link" target="_blank" />}
                {isMobile && (
                  <ContentstackLink contentKey="page_content.sharepoint_training_link_mobile" target="_blank" />
                )}
              </div>
            )}
            {!isShopLocaleInternational() && (
              <PermissionAccess requiredPermissions={Permissions[AccessDefinitions.DASHBOARD_QUOTES_PAGE_ACCESS]}>
                <div className="internal-user-header__navigation">
                  <div className="internal-user-header__navigation-item">
                    <Link to={Routes.DashboardQuotesPage}>
                      <div className="internal-user-header__icon-wrapper">
                        <img src={iconQuotes} alt="Special Order Quotes List" />
                        {numberOfQuotes > 0 && (
                          <span className="internal-user-header__navigation-item-counter">{numberOfQuotes}</span>
                        )}
                      </div>
                      {isDesktop && (
                        <span className="internal-user-header__navigation-item-text">
                          <ContentstackText contentKey="header[0].navigation.quotes_link_label" />
                        </span>
                      )}
                    </Link>
                  </div>
                  <div className="internal-user-header__navigation-item">
                    <Link to={Routes.DashboardPage}>
                      <img src={iconDashboard} alt="Dashboard" />
                      {isDesktop && (
                        <span className="internal-user-header__navigation-item-text">
                          <ContentstackText contentKey="header[0].navigation.dashboard_link_label" />
                        </span>
                      )}
                    </Link>
                  </div>
                </div>
              </PermissionAccess>
            )}
            {isCsr && (
              <div className="internal-user-header__sharepoint-link">
                {isDesktop && <ContentstackLink contentKey="page_content.sharepoint_training_link" target="_blank" />}
                {isMobile && (
                  <ContentstackLink contentKey="page_content.sharepoint_training_link_mobile" target="_blank" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {isBlockedAccount && (isInvoiceOnlyUser || isShopLocaleInternational()) && !isPageUnderPayment && (
        <AccountWarning contentstackPath="common[0]" messageType="notifications" messageId="MSG026" />
      )}
    </>
  );
};
