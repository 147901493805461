import { CommonBluesnapErrorIds } from 'constants/common-bluesnap-error-ids.enum';
import { useAddPaymentMethod } from './use-add-payment-method';
import { useRemovePaymentMethod } from './use-remove-payment-method';

export const useAddOrRemovePaymentMethodSuccesOrErrorModal = () => {
  const { showRemovePaymentMethodSuccessModal, showRemovePaymentMethodFailModal } = useRemovePaymentMethod({});

  const { showAddPaymentMethodFailModal, showAddPaymentMethodSuccessModal } = useAddPaymentMethod();

  const removePaymentMethodSuccessModal = () => {
    showRemovePaymentMethodSuccessModal({ messageId: 'MSG188' });
  };

  const removePaymentMethodFailModal = (errorId: string, errorMessageId: string) => {
    showRemovePaymentMethodFailModal({
      messageId: errorId === CommonBluesnapErrorIds.INVALID_PAYMENT_INPUT ? errorMessageId : 'MSG189',
    });
  };

  const addPaymentMethodSuccessModal = () => {
    showAddPaymentMethodSuccessModal({ messageId: 'MSG187' });
  };

  const addPaymentMethodFailModal = () => {
    showAddPaymentMethodFailModal();
  };

  return {
    removePaymentMethodSuccessModal,
    removePaymentMethodFailModal,
    addPaymentMethodFailModal,
    addPaymentMethodSuccessModal,
  };
};
