import { FC } from 'react';
import classNames from 'classnames';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { ContentstackLink } from 'components/contentstack/contentstack-link';
import { CustomLink } from 'components/custom-link';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { onNestedLinkClick } from 'utils/link-utils';
import { useContent } from 'hooks/use-content';
import { IGuideGifImage, IGuideItem } from 'types/guide-tile';

import './guide-section.scss';

export interface IGuideSectionProps {
  contentStackPath: string;
  withButton?: boolean;
}

export const GuideSection: FC<IGuideSectionProps> = ({ contentStackPath, withButton }) => {
  const PATH_TO_GUIDE_SECTION = `${contentStackPath}.guide_section.guide_section_tiles[0]`;
  const { getContentByKey } = useContent();
  const { isDesktop, isMobile } = useBreakpoint();

  const gifImage: IGuideGifImage = getContentByKey(`${PATH_TO_GUIDE_SECTION}.gif_file`, {
    url: '',
    title: '',
  });

  const hasGifImage = gifImage.url && gifImage.url.match(/\.gif/g);
  const DISPLAYED_TILES_LIMIT = hasGifImage ? 3 : 4;

  const guideItems: IGuideItem[] = getContentByKey(`${PATH_TO_GUIDE_SECTION}.guide_tiles`, []);

  const renderGuideTileItem = (item: IGuideItem) => (
    <div className="guide-tile__content">
      <div className="guide-tile__icon-wrapper">
        <img src={item.icon.url} alt="guide-tile-icon" className="guide-tile__icon" />
      </div>
      <div className="guide-tile__description" onClick={onNestedLinkClick}>
        <div
          dangerouslySetInnerHTML={{
            __html: item.description,
          }}
        />
      </div>
    </div>
  );

  const renderGuideTiles = () =>
    guideItems.slice(0, DISPLAYED_TILES_LIMIT).map((item: IGuideItem, index: number) => {
      return (
        <div
          className={classNames('guide-tile__item', { 'guide-tile__item--with-link': item.link })}
          key={`${item.title}-${index}`}
        >
          {item.link ? (
            <CustomLink url={item.link} className="guide-tile__link">
              {renderGuideTileItem(item)}
            </CustomLink>
          ) : (
            renderGuideTileItem(item)
          )}
        </div>
      );
    });

  return (
    <section
      className={classNames('guide-section', {
        'guide-section--with-gif-image': hasGifImage,
      })}
    >
      {hasGifImage && isDesktop && (
        <div className="guide-section__gif-image">
          <img src={gifImage.url} alt={gifImage.title} />
        </div>
      )}
      <div className="guide-section__content">
        <div className="guide-section__title">
          <ContentstackText contentKey={`${PATH_TO_GUIDE_SECTION}.guide_section_title`} />
        </div>
        <div className="guide-section__description">
          <ContentstackText contentKey={`${PATH_TO_GUIDE_SECTION}.guide_section_description`} />
        </div>
        <div>
          {hasGifImage && isMobile && (
            <div className="guide-section__gif-image">
              <img src={gifImage.url} alt={gifImage.title} />
            </div>
          )}
          <div className="guide-section__tiles">{renderGuideTiles()}</div>
        </div>
        {withButton && (
          <div className="guide-section__footer">
            <ContentstackLink
              className="button guide-section__get-started-btn"
              contentKey={`${contentStackPath}.get_started_link`}
            />
          </div>
        )}
      </div>
    </section>
  );
};
