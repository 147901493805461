import './expandable-section.scss';

import {
  FC,
  SyntheticEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  ReactElement,
  PropsWithChildren,
} from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { IIconWithHoverProps } from 'components/icon-with-hover';

export interface IExtendedTitleProps {
  onExtendedTitleCLick: (e: SyntheticEvent) => void;
  isExtendedTitleOpen: boolean;
}

export interface IExpandableSectionProps extends PropsWithChildren {
  title?: string | ReactNode;
  extendedTitle?: ({ onExtendedTitleCLick, isExtendedTitleOpen }: IExtendedTitleProps) => ReactNode;
  forceIsExpanded?: boolean;
  withoutAnimation?: boolean;
  className?: string;
  unmountOnExit?: boolean;
  ExpandableIcon?: ReactElement<IIconWithHoverProps>;
  onExpandSectionClick?: () => void;
}

export const ExpandableSection: FC<IExpandableSectionProps> = ({
  title,
  extendedTitle,
  forceIsExpanded = false,
  withoutAnimation = false,
  children,
  className = '',
  unmountOnExit = true,
  ExpandableIcon,
  onExpandSectionClick,
}) => {
  const [isOpened, setIsOpened] = useState(forceIsExpanded);
  const [isExtendedTitleOpen, setExtendedTitleOpen] = useState(false);

  useEffect(() => {
    setIsOpened(forceIsExpanded);
  }, [forceIsExpanded]);

  const onTitleClick = useCallback(() => {
    if (!isOpened) {
      onExpandSectionClick && onExpandSectionClick();
    }

    if (isOpened) {
      setExtendedTitleOpen(false);
    }

    setIsOpened(!isOpened);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened, onExpandSectionClick]);

  const onExtendedTitleCLick = (e: SyntheticEvent) => {
    e.stopPropagation();
    setExtendedTitleOpen(!isExtendedTitleOpen);
  };

  const expandableSectionClassName = classNames('expandable-section', {
    'expandable-section--expanded': isOpened,
    'expandable-section--title-expanded': isExtendedTitleOpen,
    'expandable-section--without-animation': withoutAnimation,
    [className]: !!className,
  });

  return (
    <div className={expandableSectionClassName}>
      <div className="expandable-section__title" onClick={onTitleClick}>
        {title && <p data-testid="expandable-section__title">{title}</p>}
        {extendedTitle && <div>{extendedTitle({ onExtendedTitleCLick, isExtendedTitleOpen })}</div>}
        {!!ExpandableIcon ? ExpandableIcon : <span className="expandable-section__expand-icon" />}
      </div>
      <CSSTransition
        in={isOpened}
        timeout={withoutAnimation ? 0 : 200}
        classNames="expandable-section__inner"
        unmountOnExit={unmountOnExit}
      >
        <div className="expandable-section__inner">{children}</div>
      </CSSTransition>
    </div>
  );
};
