import React, { FC, PropsWithChildren } from 'react';
import iconClose from 'assets/images/icons/icon-close.svg';

import './mobile-panel-closer.scss';

export interface IMobilePanelCloserProps extends PropsWithChildren {
  label: string;
  handleMenuOpen: any;
}

export const MobilePanelCloser: FC<IMobilePanelCloserProps> = ({ label, handleMenuOpen, children }) => {
  return (
    <div className="mobile-panel-header">
      <div className="mobile-panel-header__heading">
        <button onClick={(event: any) => handleMenuOpen(event)}>
          <img src={iconClose} alt="close" />
        </button>
        <span>{label}</span>
      </div>
      {children}
    </div>
  );
};
