import './cor-footer-newsletter-modal.scss';

import React from 'react';
import { CorForm } from 'corporate-components/cor-form';
import classNames from 'classnames';
import { Modal } from 'components/modals/common/modal';
import { ContentstackContext } from 'context/contentstack';
import { useContent } from 'hooks/use-content';

const CorFooterNewsletterModal = ({ isOpen = false, onClose = () => {}, contentKey = '' }) => {
  const { getContentByKey } = useContent();
  const form: any = getContentByKey(contentKey, {});
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);

  const onFormSubmitted = React.useCallback(() => {
    setIsFormSubmitted(true);
  }, []);
  const onCloseWrapped = React.useCallback(() => {
    onClose();
    setIsFormSubmitted(false);
  }, [onClose]);

  return (
    <ContentstackContext.Provider value={{ form: { form: [form] } }}>
      <Modal
        className={classNames('cor-footer-newsletter-modal', {
          'cor-footer-newsletter-modal--submitted': isFormSubmitted,
        })}
        isOpen={isOpen}
        withBackdropClick
        size="medium_large"
        hideCloseButton={false}
        onClose={onClose}
      >
        <CorForm onParentModalClose={onCloseWrapped} onFormSubmitted={onFormSubmitted} />
      </Modal>
    </ContentstackContext.Provider>
  );
};

export default React.memo(CorFooterNewsletterModal);
