import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { Routes } from 'constants/routes.enum';
import { CorButton } from 'components/cor-button';
import { ContentstackText, ContentstackMessage } from 'components/contentstack';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { selectReorderOriginalToReplacementItemsMap } from 'store/shopping-cart/selectors';
import { useGetOriginalToReplacementsMap } from 'hooks/use-get-original-to-replacement-map';
import { selectDiscontinuedItemsWithReplacementsLoading } from 'store/items-replacements/selectors';
import { IItemizedSkuInfo } from 'types/order-details';
import { LoadingIndicator } from 'components/loading-indicator';
import {
  useHideGlobalModalHandler,
  useRequestQuoteModal,
  useRequestQuoteSuccessMessageModal,
} from 'hooks/use-global-modal';
import { DiscontinuedWithReplacementSlider } from 'features/discontinued-items-with-replacements';
import { clearDiscontinuedItemsReplacements } from 'store/items-replacements/actions';
import { clearOriginalToReplacementSkuMap } from 'store/shopping-cart/actions';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './all-items-added-to-cart-result-message-modal.scss';

export interface IAllItemsAddedToCartResultMessageModalProps extends IModalProps {
  messages: {
    successfullyAddedItems: number;
    errors: string[];
    item?: IItemizedSkuInfo | null;
  };
  showControls?: boolean;
}

/**
 * This modal is used to display success or error message to user
 * when they add items (with checkboxes) to cart (ex. order history -> product view)
 **/
export const AllItemsAddedToCartResultMessageModal: FC<IAllItemsAddedToCartResultMessageModalProps> = ({
  isOpen = false,
  onClose = () => {},
  messages: { successfullyAddedItems, errors: errorMessages, item },
  showControls = false,
}) => {
  const contentstackPath = 'modals.0.add_to_cart_messages.0';
  const history = useHistory();
  const dispatch = useDispatch();
  const type = successfullyAddedItems ? 'success' : errorMessages.length ? 'error' : undefined;

  const originalToReplacementsSkusMap = useSelector(selectReorderOriginalToReplacementItemsMap);
  const originalToReplacementMap: any = useGetOriginalToReplacementsMap(
    !!successfullyAddedItems || !isEmpty(errorMessages),
    originalToReplacementsSkusMap
  );
  const discontinuedItemsWithReplacementsLoading = useSelector(selectDiscontinuedItemsWithReplacementsLoading);
  const [activeSlide, setActiveSlide] = useState(0);

  const hideGlobalModal = useHideGlobalModalHandler();
  const showRequestQuoteSuccessModal = useRequestQuoteSuccessMessageModal();

  const showRequestQuoteModal = useRequestQuoteModal({
    requestQuoteSuccessCallBack: () => showRequestQuoteSuccessModal(),
    requestQuoteFailCallBack: hideGlobalModal,
  });

  const createRequestQuoteClickHandler = (sku: string) => () => {
    showRequestQuoteModal({ skuKey: sku });
  };

  const onCloseModal = () => {
    dispatch(clearDiscontinuedItemsReplacements());
    dispatch(clearOriginalToReplacementSkuMap());
    onClose && onClose();
  };

  useEffect(() => {
    if (showControls) {
      return;
    }
    let timer: number;
    if (successfullyAddedItems && !errorMessages.length && isEmpty(originalToReplacementsSkusMap)) {
      timer = window.setTimeout(() => onClose(), 3000);
    }
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullyAddedItems, errorMessages, onClose]);

  const renderFooterButtons = () => (
    <>
      <CorButton color="tertiary continue-shopping-btn" onClick={onClose}>
        <ContentstackText contentKey={`${contentstackPath}.success_modal.continue_button_label`} />
      </CorButton>
      <CorButton color="primary checkout-btn" onClick={() => history.push(Routes.ShoppingCartPage)}>
        <ContentstackText contentKey={`${contentstackPath}.success_modal.view_cart_button_label`} />
      </CorButton>
    </>
  );

  const hasErrorsFlag =
    !isEmpty(errorMessages) && isEmpty(originalToReplacementMap) && !discontinuedItemsWithReplacementsLoading;

  return (
    <Modal
      className="all-items-added-to-cart-result-message"
      isOpen={isOpen}
      onClose={onCloseModal}
      hideCloseButton={
        isEmpty(originalToReplacementMap) && ((!successfullyAddedItems && isEmpty(errorMessages)) || !showControls)
      }
      withBackdropClick
    >
      <ModalHeader iconType={type} contentstackPathIcon={`${contentstackPath}.${type}_modal.${type}_icon`}>
        {!!successfullyAddedItems ? (
          <ContentstackMessage
            type="success"
            messageId="MSG078"
            interpolateParams={{ itemsQuantity: successfullyAddedItems }}
          />
        ) : (
          <ContentstackText contentKey={`${contentstackPath}.error_modal.title`} />
        )}
      </ModalHeader>
      <div>
        {(hasErrorsFlag || !isEmpty(originalToReplacementsSkusMap)) && (
          <div
            className={classNames('all-items-added-to-cart-result-message__context', {
              'all-items-added-to-cart-result-message__context-with-slider': !isEmpty(originalToReplacementsSkusMap),
            })}
          >
            {hasErrorsFlag && (
              <>
                <div className="all-items-added-to-cart-result-message__text">
                  {errorMessages.map((error) => {
                    return (
                      <div key={error}>
                        <ContentstackMessage
                          type="error"
                          messageId={error}
                          interpolateParams={{ sku: item?.sku, name: item?.skuName || '' }}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {!isEmpty(originalToReplacementsSkusMap) && (
              <>
                {discontinuedItemsWithReplacementsLoading ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <p className="all-items-added-to-cart-result-message__message">
                      <ContentstackMessage type="notifications" messageId="MSG024" />
                    </p>
                    <DiscontinuedWithReplacementSlider
                      originalToReplacementMap={originalToReplacementMap}
                      createRequestQuoteClickHandler={createRequestQuoteClickHandler}
                      activeSlide={activeSlide}
                      onSlideChange={setActiveSlide}
                    />
                  </>
                )}
              </>
            )}
          </div>
        )}

        {!isEmpty(errorMessages) && (
          <>
            <ModalFooter className="all-items-added-to-cart-result-message__footer">
              {successfullyAddedItems > 0 ? (
                renderFooterButtons()
              ) : (
                <CorButton color="tertiary cancel-button" onClick={onClose}>
                  <ContentstackText contentKey={`${contentstackPath}.error_modal.cancel_button_label`} />
                </CorButton>
              )}
            </ModalFooter>
          </>
        )}
        {successfullyAddedItems > 0 && isEmpty(errorMessages) && showControls && (
          <ModalFooter className="all-items-added-to-cart-result-message__footer all-items-added-to-cart-result-message__footer-success">
            {renderFooterButtons()}
          </ModalFooter>
        )}
      </div>
    </Modal>
  );
};
