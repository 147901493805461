import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { CorContentstackLink } from 'corporate-components';
import { isEmpty } from 'lodash';
import { StickyView } from 'components/sticky-view';
import { useEnvironment } from 'hooks/use-environment';
import { NavigationStateContextProvider } from 'context/navigation-state';
import {
  selectIsAuthorised,
  selectIsInternalUser,
  selectUser,
  selectIsBlockedAccount,
  selectIsUserLocationDeleted,
  selectIsHouseAccountUser,
  selectIsMsrUser,
  selectIsMsmUser,
  selectIsCsrUser,
  selectHasTemporaryPassword,
  selectIsInvoiceOnlyCustomer,
} from 'store/auth/selectors';
import { getLocations } from 'store/customer-login/actions';
import { AccountWarning } from 'features/account-warning';
import { useLocalStorage } from 'hooks/use-local-storage';
import { useContentstackSearchTags } from 'hooks/use-contentstack-search-tags';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { IUserInfo } from 'types/user-info';
import { EnvironmentNotification } from 'components/environment-notification';
import { ImpersonationModeHeader } from 'components/impersonation-mode-header';
import { getCartInfo } from 'store/shopping-cart/actions';
import { PAYMENTS_ROUTE_PARTIAL, Routes } from 'constants/routes.enum';
import { getCategoriesNavigation } from 'store/categories-navigation/actions';
import { selectItemsCounter } from 'store/shopping-cart/selectors';
import { selectCustomerLocations } from 'store/customer-login/selectors';
import { handleUpdateUtilityDataWithCounts } from 'components/layout/cor-header/utilities/handle-get-counts';
import { selectIsImpersonateModeOn } from 'store/auth/selectors';
import { selectCustomerNotifications } from 'store/notifications/selectors';
import { getCustomerNotifications } from 'store/notifications/actions';
import { HeaderProvider, useMainNavState } from 'components/layout/cor-header/context/headerContext';
import { HEADER_CONTENTSTACK_CONFIG, HEADER_STATIC_LABELS } from 'components/layout/cor-header/cor-header.constants';
import { SiteWideBanner } from 'components/site-wide-banner';
import { SessionTimeoutWarning } from 'components/session-timeout-warning';
import { selectIsClientIdle } from 'store/customer-login/selectors';
import { getSearchKeys } from 'store/guest/actions';
import { setIsHeaderCollapsed } from 'store/ui/actions';
import { StickySectionsKeys } from 'context/sticky-sections-heights-context';
import { useStickyOffsets } from 'hooks/use-sticky-offsets';
import { isExternalUrl } from 'utils/is-external-url';
import { selectIsHeaderCollapsed } from 'store/ui/selectors';
import { isInternationalSite } from 'utils/get-locale-params';
import { CorButton } from 'components/cor-button';
import { isShopLocaleInternational } from 'utils/is-shop-locale-international';
import {
  ICSUtilityNavData,
  IUtilityNavData,
  ICSUtilityNavLogo,
  ICSUtilityNavSearchBar,
  ICSHeaderCorpNavMenuOut,
} from './cor-header.interface';
import { resolveCorporateMenu, resolveShopMenu } from './cor-header.resolvers';
import { handleOpenMenuBodyStyling } from './utilities/handle-menus';
import { MobileSearchPanel } from './components/mobile-search-panel';
import { MainNav, UtilityNav, MobilePanelCloser, AlgoliaSearch } from './components';
import { utilityNavMapper, corporateMenuMapper, mergedContactMenuItemIntoCorporate } from './mappers';

import './cor-header.scss';

export interface ICorporateHeader {
  isMobile: boolean;
  dawnLogo: ICSUtilityNavLogo;
  searchField: ICSUtilityNavSearchBar;
  utilityData: IUtilityNavData;
  corporateMenu: ICSHeaderCorpNavMenuOut;
  shopMenu?: ICSHeaderCorpNavMenuOut;
  isLoggedInUser: boolean;
  updatedUtilityData: any;
  mainNavState: any;
  labels: INavHeaderLabels;
}

interface INavHeaderLabels {
  [key: string]: string;
}

interface INavHeader {
  isImpersonateModeOn: boolean;
  userIsFullyAuthorized: boolean;
  isLoggedInUser: boolean;
  corporateMenuData: ICSUtilityNavData;
}

export type TScrollDirection = 'up' | 'down';

const CorHeader: FC<ICorporateHeader> = ({
  isMobile,
  dawnLogo,
  corporateMenu,
  shopMenu,
  isLoggedInUser,
  updatedUtilityData,
  mainNavState,
  labels,
}) => {
  // custom hooks
  // state

  const isImpersonationModeOn = useSelector(selectIsImpersonateModeOn);
  const { shopNavOpen, setShopNavOpen, mobileMenuOpen, setMobileMenuOpen, setOpenedMenu } = mainNavState;
  // ref
  const corporateNavRef = useRef<any>(undefined);
  const corporateHeaderTopRef = useRef<any>(undefined);
  const navRef = useRef<any>(undefined);
  const [locale] = useLocalStorage('locale', null);
  const isLoggedInUSAUser = !isInternationalSite(locale) && isLoggedInUser;

  const handleHamburgerMenu = () => {
    if (isMobile) {
      setMobileMenuOpen((current: boolean) => !current);
      if (!mobileMenuOpen) {
        setOpenedMenu({});
      }
      return;
    }
    setShopNavOpen((current: boolean) => !current);
  };

  const closeHamburgerMenuHandler = (url) => {
    if (isMobile && window.location.pathname === url) {
      mobileMenuOpen && setMobileMenuOpen(false);
      shopNavOpen && setShopNavOpen(false);
    }
  };

  const handleCSSTranstions = (data) => {
    if (isMobile) {
      handleOpenMenuBodyStyling(data);
    }
  };

  if (!isMobile) {
    handleOpenMenuBodyStyling({ overflow: 'auto', margin: 'auto' });
  }

  useStickyOffsets({
    ref: corporateHeaderTopRef,
    sectionKey: StickySectionsKeys.header,
  });

  useStickyOffsets({
    ref: navRef,
    sectionKey: StickySectionsKeys.navHeader,
    isDesktopOnly: true,
  });

  /** CSS TRANSITION FOR HEADER OPEN/CLOSE
   * @summary: make the header appear to open seamlessly
   */
  return (
    <CSSTransition
      ref={corporateNavRef}
      in={mobileMenuOpen}
      timeout={225}
      classNames="corp-header"
      onExit={() => {
        // right when the user closes the header, add the scrollbars back
        // it should look like they were always there and hidden by the header
        handleCSSTranstions({ overflow: 'auto' });
      }}
      onEntered={() => {
        // after the header is open, hide the scroll bars
        handleCSSTranstions({ overflow: 'hidden' });
      }}
    >
      <section ref={corporateNavRef} className="corp-header">
        <header className="grid-y">
          <section
            ref={corporateHeaderTopRef}
            className={classNames('corp-header__top', { 'corp-header__top--impersonation': isImpersonationModeOn })}
          >
            <div className="utility-logo grid-x">
              {(isMobile || isLoggedInUSAUser) && (
                <button
                  className={`corporate-hamburger-menu ${
                    !isLoggedInUSAUser || shopNavOpen || isMobile ? '' : 'is-active'
                  }`}
                  name={'Corporate Menu Button'}
                  aria-label={'Corporate Menu Button'}
                  onClick={handleHamburgerMenu}
                >
                  <div aria-hidden={true} className="horiz-line" />
                </button>
              )}
              <CorContentstackLink
                data-testid={'dawn-logo-link'}
                contentKey={''}
                data={{ href: dawnLogo?.link_url.href, title: '' }}
                aria-label={dawnLogo?.image_alt_text}
                opensInNewTab={dawnLogo?.link_url.href ? isExternalUrl(dawnLogo.link_url.href) : false}
              >
                <img alt={dawnLogo?.image_alt_text} src={dawnLogo?.image.url} />
              </CorContentstackLink>
            </div>
            <div className="utility-nav">
              <UtilityNav
                className={'utility-nav__menu'}
                shopMenu={shopMenu}
                closeHamburgerMenuHandler={closeHamburgerMenuHandler}
                {...updatedUtilityData}
              />
            </div>
          </section>
          {isMobile && !isLoggedInUSAUser && (
            <div className="utility-search">
              <div className="grid-container">
                <AlgoliaSearch id={'utilitiySearchIn'} isLoggedIn={isLoggedInUser} expandable />
              </div>
            </div>
          )}
        </header>
        {(!isLoggedInUSAUser || isMobile) && (
          <section
            className={classNames('corp-header__navigation', {
              'corp-header__navigation--impersonation': isImpersonationModeOn,
            })}
            style={{
              transform: `translateX(${mobileMenuOpen || !isMobile ? '0' : '-100%'})`,
            }}
            ref={navRef}
          >
            <MobilePanelCloser label={labels?.mobile_menu_header} handleMenuOpen={() => setMobileMenuOpen(false)} />
            <MainNav
              corporateMenu={corporateMenu}
              shopMenu={shopMenu}
              utilityItems={updatedUtilityData.menuItems}
              labels={labels}
              userInfo={updatedUtilityData.userInfo}
            />
          </section>
        )}
      </section>
    </CSSTransition>
  );
};

const HeaderWrapper: React.FC<INavHeader> = ({
  isImpersonateModeOn,
  userIsFullyAuthorized,
  isLoggedInUser,
  corporateMenuData,
}) => {
  const { isMobile } = useBreakpoint();
  const userSessionStatus = localStorage.getItem('session');
  const isClientIdle = useSelector(selectIsClientIdle);
  const { getOffset } = useStickyOffsets();
  const isHouseAccount = useSelector(selectIsHouseAccountUser);
  const isHeaderCollapsed = useSelector(selectIsHeaderCollapsed);
  const isMsmUser = useSelector(selectIsMsmUser);
  const isMsrUser = useSelector(selectIsMsrUser);
  const isCsrUser = useSelector(selectIsCsrUser);
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);
  const location = useLocation();
  const [locale] = useLocalStorage('locale', null);
  const isLoggedInUSAUser = !isInternationalSite(locale) && isLoggedInUser;
  const history = useHistory();

  const user: IUserInfo = useSelector(selectUser);
  const customerLocations = useSelector(selectCustomerLocations);
  const userLocation = customerLocations.find((item) => item.value === user.locationId);

  const [shouldDisplayMobileSearch, setShouldDisplayMobileSearch] = useState(true);

  const hideCartIconInHeader = isImpersonateModeOn && !isMsmUser && !isMsrUser && !isCsrUser;

  // fetch shop header data from contentstack.
  const shopMenuData: ICSUtilityNavData = useContentstackSearchTags([
    {
      contentstackParams: HEADER_CONTENTSTACK_CONFIG.PARAM_AUTHENTICATED,
      contentTypeName: HEADER_CONTENTSTACK_CONFIG.TYPE,
      include: [
        HEADER_CONTENTSTACK_CONFIG.REF_SHOP,
        HEADER_CONTENTSTACK_CONFIG.REF_SUPPORT,
        HEADER_CONTENTSTACK_CONFIG.LABELS,
      ],
      ...(isInternationalSite(locale) && { locale: locale }),
    },
  ])[0];

  // if the user is fully authorized, use the shop header data. otherwise, the shop menu will be undefined.
  const approvedShopMenuData = userIsFullyAuthorized && !isInvoiceOnlyUser && !!shopMenuData ? shopMenuData : undefined;

  const utilityData: IUtilityNavData = utilityNavMapper(
    isLoggedInUSAUser,
    isHouseAccount,
    hideCartIconInHeader,
    userLocation?.isDeleted || false,
    !!approvedShopMenuData ? approvedShopMenuData : corporateMenuData,
    corporateMenuData?.header_labels?.shop_label || HEADER_STATIC_LABELS.SHOP_LABEL,
    user
  );
  // if the user is fully authorized, use the shop header utility data. otherwise, use the corporate header utility data.
  const dawnLogo: ICSUtilityNavLogo = corporateMenuData?.dawn_logo[0];
  const searchField: ICSUtilityNavSearchBar = corporateMenuData?.search_bar;
  const corporateMenu: any = corporateMenuMapper(resolveCorporateMenu(corporateMenuData?.header_menu_reference));
  const shopMenu: ICSHeaderCorpNavMenuOut | undefined =
    userIsFullyAuthorized && !!approvedShopMenuData
      ? corporateMenuMapper(resolveShopMenu(approvedShopMenuData))
      : undefined;

  const dispatch = useDispatch();
  const cartCount = useSelector(selectItemsCounter);
  const notifications: any = useSelector(selectCustomerNotifications);
  const {
    setShopNavOpen,
    mobileMenuOpen,
    setMobileMenuOpen,
    setIsLoggedInUser,
    isSearchMobileOpen,
    setIsSearchMobileOpen,
    ...restMainNavState
  } = useMainNavState();

  useEffect(() => {
    if (!isLoggedInUSAUser || isInvoiceOnlyUser || isShopLocaleInternational()) return;

    dispatch(getCustomerNotifications.request());
  }, [dispatch, isLoggedInUSAUser, isInvoiceOnlyUser, locale]);

  useEffect(() => {
    setIsLoggedInUser(isLoggedInUSAUser);
    setShopNavOpen(isLoggedInUSAUser);
  }, [isLoggedInUSAUser, setIsLoggedInUser, setShopNavOpen]);

  useEffect(() => {
    dispatch(setIsHeaderCollapsed(false));
  }, [dispatch]);

  const updatedUtilityData = handleUpdateUtilityDataWithCounts(
    utilityData,
    notifications,
    cartCount,
    isMobile,
    isLoggedInUSAUser
  );

  const handlePageScroll = () => {
    if (window.scrollY > 0) {
      if (!isHeaderCollapsed) {
        dispatch(setIsHeaderCollapsed(true));
      }

      if (shouldDisplayMobileSearch && isMobile) {
        setShouldDisplayMobileSearch(false);
      }
      return;
    }

    if (window.scrollY === 0) {
      if (isHeaderCollapsed) {
        dispatch(setIsHeaderCollapsed(false));
      }

      if (!shouldDisplayMobileSearch && isMobile) {
        setShouldDisplayMobileSearch(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handlePageScroll);
    return () => {
      window.removeEventListener('scroll', handlePageScroll);
    };
  });

  const transitionStyle = () => {
    return {
      transform: `translateX(${mobileMenuOpen || !isMobile ? '0' : '-100%'})`,
    };
  };

  return (
    <div className="corp-header__wrapper">
      <StickyView
        className={classNames('corp-header__sticky-wrapper', {
          'corp-header__sticky-wrapper--authorized': isLoggedInUSAUser,
        })}
        offsetTop={getOffset(StickySectionsKeys.header)}
      >
        <CorHeader
          isMobile={isMobile}
          dawnLogo={dawnLogo}
          searchField={searchField}
          utilityData={utilityData}
          corporateMenu={mergedContactMenuItemIntoCorporate(corporateMenu, utilityData.menuItems)}
          shopMenu={shopMenu}
          isLoggedInUser={userIsFullyAuthorized && !isInternationalSite(locale)}
          updatedUtilityData={updatedUtilityData}
          labels={corporateMenuData?.header_labels}
          mainNavState={{
            setShopNavOpen,
            mobileMenuOpen,
            setMobileMenuOpen,
            setIsLoggedInUser,
            ...restMainNavState,
          }}
        />
      </StickyView>
      {location.pathname.includes(Routes.LoginPage) && isClientIdle && userSessionStatus === null && (
        <SessionTimeoutWarning />
      )}
      {isMobile && shouldDisplayMobileSearch && isLoggedInUSAUser && (
        <div className={classNames('utility-search', { 'utility-search--impersonation': isImpersonateModeOn })}>
          <div className="grid-container grid-x utility-search__panel">
            <AlgoliaSearch
              id={'utilitiySearchIn'}
              isLoggedIn={isLoggedInUSAUser}
              isSearchVisibleOnMobile={shouldDisplayMobileSearch}
              expandable
            />
            <CorButton
              className="utility-search__shop-btn"
              color="primary"
              onClick={() => history.push(Routes.ShopHomePage)}
            >
              {corporateMenuData?.header_labels?.shop_label || HEADER_STATIC_LABELS.SHOP_LABEL}
            </CorButton>
          </div>
        </div>
      )}
      {isLoggedInUSAUser && !isMobile && (
        <section className="corp-header__navigation" style={transitionStyle()}>
          <MainNav
            corporateMenu={corporateMenu}
            shopMenu={shopMenu}
            utilityItems={updatedUtilityData.menuItems}
            labels={corporateMenuData?.header_labels}
          />
        </section>
      )}
      {isMobile && isLoggedInUSAUser && isSearchMobileOpen && (
        <MobileSearchPanel
          setIsSearchMobileOpen={setIsSearchMobileOpen}
          label={shopMenu?.mobile_search_panel_title || ''}
        />
      )}
    </div>
  );
};

const CMSCorporateHeaderWrapper = () => {
  const hasTempPassword = useSelector(selectHasTemporaryPassword);
  const isInternalUser = useSelector(selectIsInternalUser);
  const isImpersonateModeOn = useSelector(selectIsImpersonateModeOn);
  const isLoggedInUser: boolean = useSelector(selectIsAuthorised);
  const isBlockedAccount = useSelector(selectIsBlockedAccount);
  const isUserLocationDeleted = useSelector(selectIsUserLocationDeleted);
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);
  const [locale] = useLocalStorage('locale', null);
  const dispatch = useDispatch();

  const location = useLocation();

  const isPageUnderPayment = location.pathname.includes(PAYMENTS_ROUTE_PARTIAL);
  const isPagePayment = location.pathname.includes(PAYMENTS_ROUTE_PARTIAL);

  const [userIsFullyAuthorized, setUserIsFullyAuthorized] = useState(false);
  useEffect(() => {
    if (!isLoggedInUser || isInvoiceOnlyUser || isShopLocaleInternational()) return;

    // if the user has a temp password and they are on the update password page, then they are not fully authorized
    const invokeTempPasswordFlag = hasTempPassword && location.pathname.includes(Routes.UpdatePasswordPage);

    setUserIsFullyAuthorized(isLoggedInUser && !invokeTempPasswordFlag);

    dispatch(
      getCartInfo.request({
        fullValidation: location.pathname === Routes.ShoppingCartPage,
      })
    );
    dispatch(getCategoriesNavigation.request());
  }, [dispatch, isLoggedInUser, hasTempPassword, isInvoiceOnlyUser, location.pathname, locale]);

  useEffect(() => {
    if (!isLoggedInUser || isInvoiceOnlyUser || isShopLocaleInternational()) return;
    if (!isInternalUser || (isInternalUser && isImpersonateModeOn)) {
      dispatch(getLocations.request());
    }
  }, [dispatch, isImpersonateModeOn, isInternalUser, isLoggedInUser, isInvoiceOnlyUser, locale]);

  useEffect(() => {
    dispatch(getSearchKeys.request());
  }, [dispatch]);

  const { isProduction } = useEnvironment();

  const isShopPages = location.pathname.includes(Routes.ShopHomePage);

  const corporateMenuData = useContentstackSearchTags([
    {
      contentstackParams: HEADER_CONTENTSTACK_CONFIG.PARAM_UNAUTHENTICATED,
      contentTypeName: HEADER_CONTENTSTACK_CONFIG.TYPE,
      include: [
        HEADER_CONTENTSTACK_CONFIG.REF_CORP,
        HEADER_CONTENTSTACK_CONFIG.REF_SUPPORT,
        HEADER_CONTENTSTACK_CONFIG.LABELS,
      ],
      ...(isInternationalSite(locale) && { locale: locale }),
    },
  ])[0];

  if (isEmpty(corporateMenuData)) {
    return null;
  }

  const header_labels = corporateMenuData?.header_labels?.[0].key_value_pair.value.reduce((item, next) => {
    item[next.key] = next.value;
    return item;
  }, {});

  return (
    <NavigationStateContextProvider>
      {!isProduction && <EnvironmentNotification />}
      {isInternalUser && <ImpersonationModeHeader />}
      <HeaderProvider header={{ ...corporateMenuData, header_labels: header_labels }}>
        <HeaderWrapper
          isImpersonateModeOn={isImpersonateModeOn}
          userIsFullyAuthorized={userIsFullyAuthorized}
          isLoggedInUser={isLoggedInUser && !isInvoiceOnlyUser && !isShopLocaleInternational()}
          corporateMenuData={{ ...corporateMenuData, header_labels: header_labels }}
        />
      </HeaderProvider>
      {userIsFullyAuthorized && <SiteWideBanner />}
      {userIsFullyAuthorized && isShopPages && isBlockedAccount && !isPageUnderPayment && (
        <AccountWarning contentstackPath="common[0]" messageType="notifications" messageId="MSG026" />
      )}
      {userIsFullyAuthorized && isShopPages && isUserLocationDeleted && !isPagePayment && (
        <AccountWarning
          contentstackPath="common[0]"
          className="deleted-location-notification"
          messageType="notifications"
          messageId="MSG069"
        />
      )}
    </NavigationStateContextProvider>
  );
};

export default CMSCorporateHeaderWrapper;
