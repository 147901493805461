import { FC } from 'react';
import iconNotice from 'assets/images/icons/icon-notice.svg';
import { ContentstackMessage } from 'components/contentstack';

import './session-timeout-warning.scss';

export const SessionTimeoutWarning: FC = () => {
  return (
    <div className="session-timeout-warning">
      <div className="grid-container">
        <div className="cell small-12 medium-8">
          <div className="session-timeout-warning__content">
            <img src={iconNotice} alt="icon-notice" />
            <ContentstackMessage type="notifications" messageId="MSG182" />
          </div>
        </div>
      </div>
    </div>
  );
};
