import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import Slider, { CustomArrowProps } from 'react-slick';
import { useReorder } from 'hooks/use-reorder';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { selectRecentOrdersInfoOrders } from 'store/home/selectors';
import { stripLeadingZeros } from 'utils/string-format';
import { reorderProducts } from 'store/shopping-cart/actions';
import { IReorderProductToCartPayload } from 'store/shopping-cart/sagas/reorder-products-to-cart';
import { PermissionAccess } from 'components/permission-access';
import { Permissions } from 'constants/permissions';
import { AccessDefinitions } from 'constants/access-definitions.enum';
import { Routes } from 'constants/routes.enum';
import { ORDER_STATUSES_ENUM } from 'constants/order-statuses.enum';
import { selectIsUserLocationDeleted } from 'store/auth/selectors';
import { CorButton } from 'components/cor-button';

import './recent-orders-slider.scss';

const RecentOrdersSliderArrow: FC<CustomArrowProps> = ({ className, onClick }) => {
  return <button className={className} onClick={onClick} />;
};

export const RecentOrdersSlider: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const recentOrders = useSelector(selectRecentOrdersInfoOrders);

  const isUserLocationDeleted = useSelector(selectIsUserLocationDeleted);
  const [isReorderButtonDisabled, setReorderButtonDisabled] = useState(false);

  const contentstackPath = 'page_content.order_updates.0.recent_orders';

  const sliderSettings = {
    speed: 500,
    touchMove: false,
    dots: true,
    dotsClass: 'recent-orders-slider__dots slick-dots',
    prevArrow: <RecentOrdersSliderArrow />,
    nextArrow: <RecentOrdersSliderArrow />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          touchMove: true,
        },
      },
    ],
  };

  const handleReorderProducts = (ctId: string) => {
    setReorderButtonDisabled(true);
    setTimeout(() => setReorderButtonDisabled(false), 1000);

    dispatch(
      reorderProducts.request<IReorderProductToCartPayload>({
        orderId: ctId,
      })
    );
  };

  useReorder();

  return (
    <Slider className="recent-orders-slider" {...sliderSettings}>
      {recentOrders?.map(({ orderNumber, orderDate, orderStatus, ctId, isQuoteOrder }, index) => (
        <div
          className={classNames('recent-orders-slider__content', {
            'recent-orders-slider__content--single-order': recentOrders?.length === 1,
          })}
          key={ctId}
        >
          <div className="recent-orders-slider__counter grid-container">
            <ContentstackText
              contentKey="page_content.order_updates.0.recent_orders.order_counter"
              interpolateParams={{ currentOrderIndex: ++index, ordersCount: recentOrders.length }}
            />
          </div>
          <div className="recent-orders-slider__info">
            <div className="grid-x grid-container">
              <div className="recent-orders-slider__info-block small-7">
                <span className="recent-orders-slider__info-block-label">
                  <ContentstackText contentKey={`${contentstackPath}.order_date_label`} />
                </span>
                <span className="recent-orders-slider__info-block-value">{orderDate}</span>
              </div>
              <div className="recent-orders-slider__info-block small-4">
                <span className="recent-orders-slider__info-block-label">
                  <ContentstackText contentKey={`${contentstackPath}.order_number_label`} />
                </span>
                <span className="recent-orders-slider__info-block-value">
                  {!orderNumber || orderStatus === ORDER_STATUSES_ENUM.SUBMITTED ? (
                    <ContentstackText contentKey={`${contentstackPath}.order_number_unavailable`} />
                  ) : (
                    stripLeadingZeros(orderNumber)
                  )}
                </span>
              </div>
              <div className="recent-orders-slider__info-block small-7">
                <span className="recent-orders-slider__info-block-label">
                  <ContentstackText contentKey={`${contentstackPath}.order_status_label`} />
                </span>
                <span className="recent-orders-slider__info-block-value">{orderStatus}</span>
              </div>
            </div>
          </div>
          <div className="grid-x grid-container">
            {!isQuoteOrder && (
              <PermissionAccess requiredPermissions={Permissions[AccessDefinitions.SHOPPING_CART_ACTIONS]}>
                <CorButton
                  className="recent-orders-slider__button small-12"
                  color="tertiary"
                  data-testid={`reorder-${ctId}`}
                  disabled={isUserLocationDeleted || isReorderButtonDisabled}
                  onClick={() => handleReorderProducts(ctId)}
                >
                  <ContentstackText contentKey={`${contentstackPath}.reorder_button_label`} />
                </CorButton>
              </PermissionAccess>
            )}
            <CorButton
              onClick={() => history.push(`${Routes.OrderDetails}/${ctId}`)}
              className="recent-orders-slider__button small-12"
              color="tertiary"
            >
              <ContentstackText contentKey={`${contentstackPath}.view_link_text`} />
            </CorButton>
          </div>
        </div>
      ))}
    </Slider>
  );
};
