import { FC, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { useStickyOffsets } from 'hooks/use-sticky-offsets';
import { StickySectionsKeys } from 'context/sticky-sections-heights-context';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { StickyView } from 'components/sticky-view';
import { useContent } from 'hooks/use-content';

import './environment-notification.scss';

export const EnvironmentNotification: FC = () => {
  const contentstackPath = 'common[0].environment_message[0].environment_message';
  const { getContentByKey } = useContent();
  const envNotificationMessage = getContentByKey('common[0].environment_message[0].environment_message', '');
  const ref = useRef<HTMLDivElement>(null);

  const [content, setContent] = useState<string>();
  const { offset } = useStickyOffsets({
    ref,
    sectionKey: StickySectionsKeys.environmentNotification,
    updateHeightDependency: content,
  });

  useEffect(() => {
    setContent(envNotificationMessage);
  }, [envNotificationMessage]);

  const handlePageScroll = () => {
    if (window.scrollY > 0 && !isEmpty(content)) {
      setContent('');
      return;
    }

    if (window.scrollY === 0 && isEmpty(content)) {
      setContent(envNotificationMessage);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handlePageScroll);
    return () => {
      window.removeEventListener('scroll', handlePageScroll);
    };
  });

  return (
    <StickyView
      offsetTop={offset}
      className="environment-notification-wrapper"
      style={{ height: isEmpty(content) && ref.current ? `${ref.current.offsetHeight}px` : 'auto' }}
    >
      <div className="environment-notification" ref={ref}>
        <div className="grid-container environment-notification__text">
          <ContentstackText contentKey={contentstackPath} />
        </div>
      </div>
    </StickyView>
  );
};
