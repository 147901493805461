import './cor-footer-shop-products.scss';

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { CorContentstackLink } from 'corporate-components/cor-contentstack';
import { CorSalesRep } from 'corporate-components/cor-layout/cor-sales-rep';
import iconUserOutlined from 'assets/images/icons/icon-user-outlined.svg';
import { ContentstackText } from 'components/contentstack';
import { IconWithHover } from 'components/icon-with-hover';
import { useContent } from 'hooks/use-content';
import { selectSalesRepInformationEntity } from 'store/account/selectors';
import { getSalesRepInformation } from 'store/account/actions';
import { formatPhoneNumber } from 'utils/string-format';
import { Routes } from 'constants/routes.enum';
import { useScrollTopOnClick } from 'hooks/use-scroll-top-on-click';
import { selectIsInvoiceOnlyCustomer } from 'store/auth/selectors';
import { isShopLocaleInternational } from 'utils/is-shop-locale-international';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';

const SHOP_PRODUCTS_CTA_KEY = 'shop_products_cta';
const ACCOUNT_CTA_KEY = 'account_cta';
const LABELS_KEY = 'footer_labels[0].key_value_pair.value';
const FALLBACK_SALES_REPRESENTATIVE_KEY = 'support_block.0';
const CUSTOM_IS_LOGGED_IN_KEY = 'custom_is_logged_in';

const CorFooterShopProducts = ({ onLogout = (e: React.SyntheticEvent<HTMLAnchorElement>) => {} }) => {
  const { getContentByKey, getLabelsByKey } = useContent();
  const shopProductsCTA: any = getContentByKey(SHOP_PRODUCTS_CTA_KEY, {});
  const fallbackSalesRepresentative = getContentByKey<any>(FALLBACK_SALES_REPRESENTATIVE_KEY, {});
  const labels = getLabelsByKey<Record<string, string>>(LABELS_KEY, {});
  const accountCTA: any = getContentByKey(ACCOUNT_CTA_KEY, {});
  const customIsLoggedIn = getContentByKey(CUSTOM_IS_LOGGED_IN_KEY, false);
  const dispatch = useDispatch();
  const { dcPhoneNumber = '', salesRepInfo } = useSelector(selectSalesRepInformationEntity);
  const hasCustomerServicePhone = Boolean(dcPhoneNumber || fallbackSalesRepresentative?.phone?.title);
  const clickHandler = useScrollTopOnClick();
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);

  const getLinkHref = useCallback(
    (index) => {
      return getContentByKey(`${ACCOUNT_CTA_KEY}.account_links[${index}].link`, { href: '' }).href;
    },
    [getContentByKey]
  );

  React.useEffect(() => {
    if (!customIsLoggedIn) return;
    dispatch(getSalesRepInformation.request());
  }, [dispatch, customIsLoggedIn]);

  if (customIsLoggedIn)
    return (
      <div className="cor-footer-shop-products cor-footer-shop-products--logged" data-testid="cor-footer-shop-products">
        <ul>
          {shopProductsCTA?.include_shop_cta && !isInvoiceOnlyUser && (
            <li className="shop-products--no-padding" data-testid="shop-products">
              <CorContentstackLink
                contentKey={`${SHOP_PRODUCTS_CTA_KEY}.button.button`}
                className={classNames(
                  'cor-footer__menu-link__title cor-footer__menu-link__title--no-menu-links shop-products__cta'
                )}
                opensInNewTab={!!shopProductsCTA?.button?.opens_in_a_new_tab}
                onClick={($event) => clickHandler($event, Routes.ShopHomePage)}
              >
                <ContentstackText contentKey={`${SHOP_PRODUCTS_CTA_KEY}.button.button.title`} />
                <span className="cor-text-link__caret__isolated" />
              </CorContentstackLink>
            </li>
          )}
          {(!!salesRepInfo?.email || !!fallbackSalesRepresentative?.email) && (
            <li className="sales-representative" data-testid="sales-representative">
              <CorSalesRep showPhone={false} isHeader={false} />
            </li>
          )}
          <li className="customer-service" data-testid="customer-service">
            {hasCustomerServicePhone && (
              <ul>
                <h4 className="cor-footer__menu-link__sub-title">{labels?.customer_service || 'CUSTOMER SERVICE'}</h4>
                <li>
                  <a
                    className={'customer-service__phone'}
                    href={`tel:${!!dcPhoneNumber ? dcPhoneNumber : fallbackSalesRepresentative?.phone?.title}`}
                  >
                    {formatPhoneNumber(!!dcPhoneNumber ? dcPhoneNumber : fallbackSalesRepresentative?.phone?.title)}
                  </a>
                </li>
              </ul>
            )}
            <ul>
              <h4 className="cor-footer__menu-link__sub-title">{labels?.account || 'ACCOUNT'}</h4>
              {accountCTA?.account_links?.map(({ opens_in_a_new_tab = false }, index) => {
                if (
                  (isInvoiceOnlyUser || isShopLocaleInternational()) &&
                  getLinkHref(index) === Routes.AccountOrderHistoryPage
                ) {
                  return null;
                }
                return (
                  <li key={index.toString()}>
                    <CorContentstackLink
                      contentKey={`${ACCOUNT_CTA_KEY}.account_links[${index}].link`}
                      className={classNames('menu-link__menu-link')}
                      opensInNewTab={opens_in_a_new_tab}
                      onClick={($event) => clickHandler($event, getLinkHref(index))}
                    />
                  </li>
                );
              })}
              <a href={getLocalizedShopUrl(Routes.LoginPage)} className="menu-link__menu-link" onClick={onLogout}>
                {labels?.log_out || 'Log Out'}
              </a>
            </ul>
          </li>
        </ul>
      </div>
    );

  return (
    <div className="cor-footer-shop-products" data-testid="cor-footer-shop-products">
      <ul>
        {shopProductsCTA?.include_shop_cta && (
          <li data-testid="shop-products">
            <CorContentstackLink
              contentKey={`${SHOP_PRODUCTS_CTA_KEY}.button.button`}
              className={classNames(
                'cor-footer__menu-link__title cor-footer__menu-link__title--no-menu-links shop-products__cta'
              )}
              opensInNewTab={!!shopProductsCTA?.button?.opens_in_a_new_tab}
            >
              <ContentstackText contentKey={`${SHOP_PRODUCTS_CTA_KEY}.button.button.title`} />
              <span className="cor-text-link__caret__isolated" />
            </CorContentstackLink>
          </li>
        )}

        {accountCTA?.include_account_cta && (
          <li data-testid="account-login">
            <CorContentstackLink
              contentKey={`${ACCOUNT_CTA_KEY}.button.button`}
              className={classNames(
                'cor-footer__menu-link__title cor-footer__menu-link__title--no-menu-links shop-products__cta'
              )}
              opensInNewTab={!!accountCTA?.button?.opens_in_a_new_tab}
              onClick={($event) => clickHandler($event, Routes.LoginPage)}
            >
              <IconWithHover icon={iconUserOutlined} hoverIcon={iconUserOutlined} alt="Menu Item Icon" />{' '}
              <ContentstackText contentKey={`${ACCOUNT_CTA_KEY}.button.button.title`} />
            </CorContentstackLink>
          </li>
        )}
      </ul>
    </div>
  );
};

export default React.memo(CorFooterShopProducts);
