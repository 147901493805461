enum productsActionTypes {
  GET_PRODUCTS = 'GET_PRODUCTS',
  PRODUCTS_PAGINATION_CHANGE = 'PRODUCTS_PAGINATION_CHANGE',
  SET_PRODUCTS_SORT_PARAMS = 'SET_PRODUCTS_SORT_PARAMS',
  SET_PRODUCTS_LIMIT = 'SET_PRODUCTS_LIMIT',
  SET_SELECTED_FACETS = 'SET_SELECTED_FACETS',
  CLEAR_SELECTED_FACETS = 'CLEAR_SELECTED_FACETS',
  RESET_SELECTED_FACETS = 'RESET_SELECTED_FACETS',
  SET_SELECTED_CATEGORY_KEY = 'SET_SELECTED_CATEGORY_KEY',
  UPDATE_SELECTED_CATEGORY_KEY = 'UPDATE_SELECTED_CATEGORY_KEY',
  RESET_DAWN_BRANDS = 'RESET_DAWN_BRANDS',
  GET_CAROUSEL_PRODUCTS = 'GET_CAROUSEL_PRODUCTS',
  GET_PRODUCTS_BY_SKUS = 'GET_PRODUCTS_BY_SKUS',
  CLEAR_PRODUCTS_META_STATE = 'CLEAR_PRODUCTS_META_STATE',
  //Temporary selected filters (category, facets) for mobile filters feature
  GET_TEMPORARY_FILTERS_INFO = 'GET_TEMPORARY_FILTERS_INFO',
  SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS',
  INIT_TEMPORARY_SELECTED_FILTERS = 'INIT_TEMPORARY_SELECTED_FILTERS',
  SET_TEMPORARY_SELECTED_FILTERS = 'SET_TEMPORARY_SELECTED_FILTERS',
  CONFIRM_TEMPORARY_SELECTED_FILTERS = 'CONFIRM_TEMPORARY_SELECTED_FILTERS',
  RESET_TEMPORARY_SELECTED_FILTERS = 'RESET_TEMPORARY_SELECTED_FILTERS',
  SET_TEMPORARY_SELECTED_CATEGORY = 'SET_TEMPORARY_SELECTED_CATEGORY',
  SET_TEMPORARY_SELECTED_FACETS = 'SET_TEMPORARY_SELECTED_FACETS',
  CLEAR_TEMPORARY_SELECTED_FACETS = 'CLEAR_TEMPORARY_SELECTED_FACETS',
  PRESELECT_SELECTED_FACETS_FROM_URL = 'PRESELECT_SELECTED_FACETS_FROM_URL',
}

export default productsActionTypes;
