import { createSelector } from 'reselect';
import { ILocationCardProps } from 'components/locations-list/location-card';
import { IState } from 'store/root-reducer';
import { ICustomerRegistrationState } from './reducer';

export const getCustomerRegistrationEntity = (state: IState): ICustomerRegistrationState =>
  state.app.customerRegistration;

export const selectAccountRetrievalForm = createSelector(
  getCustomerRegistrationEntity,
  (customerRegistrationEntity) => customerRegistrationEntity.accountRetrievalForm
);

export const selectValidateWithoutInvoiceForm = createSelector(
  getCustomerRegistrationEntity,
  (customerRegistrationEntity) => customerRegistrationEntity.validateWithoutInvoiceForm
);

export const selectVerificationEmailSent = createSelector(
  getCustomerRegistrationEntity,
  (customerRegistrationEntity) => customerRegistrationEntity.resendVerification.isVerificationEmailSent
);

export const selectAccountRetrievalFormServerError = createSelector(
  selectAccountRetrievalForm,
  (accountRetrievalForm) => accountRetrievalForm.serverError
);

export const selectAccountRetrievalFormServerErrorId = createSelector(
  selectAccountRetrievalForm,
  (accountRetrievalForm) => accountRetrievalForm.serverErrorId
);

export const selectAccountRetrievalFormIsSubmitting = createSelector(
  selectAccountRetrievalForm,
  (accountRetrievalForm) => accountRetrievalForm.isSubmitting
);

export const selectValidateWithoutInvoiceFormServerErrorId = createSelector(
  selectValidateWithoutInvoiceForm,
  (validateWithoutInvoiceForm) => validateWithoutInvoiceForm.serverErrorId
);

export const selectValidateWithoutInvoiceFormIsSubmitting = createSelector(
  selectValidateWithoutInvoiceForm,
  (validateWithoutInvoiceForm) => validateWithoutInvoiceForm.isSubmitting
);

export const getAccountRetrieval = createSelector(
  getCustomerRegistrationEntity,
  (customerRegistrationEntity) => customerRegistrationEntity.accountRetrieval || {}
);

export const getAccountRetrievalLocations = createSelector(
  getAccountRetrieval,
  (accountRetrieval): ILocationCardProps[] => {
    return !accountRetrieval.locations
      ? []
      : accountRetrieval.locations?.map(({ name, locationId, address: { street, city, state, zipCode } }) => ({
          name,
          locationId,
          street,
          city,
          state,
          zipCode,
        }));
  }
);

export const getActiveRegistrationStep = createSelector(
  getCustomerRegistrationEntity,
  (customerRegistrationEntity) => customerRegistrationEntity.activeStep
);

export const selectPersonalInformationForm = createSelector(
  getCustomerRegistrationEntity,
  (customerRegistrationEntity) => customerRegistrationEntity.personalInformationForm
);

export const selectPersonalInformationFormServerErrorId = createSelector(
  selectPersonalInformationForm,
  (personalInformationForm) => personalInformationForm.serverErrorId
);

export const selectPersonalInformationFormIsSubmitting = createSelector(
  selectPersonalInformationForm,
  (personalInformationForm) => personalInformationForm.isSubmitting
);

export const getCustomerProfile = createSelector(
  getCustomerRegistrationEntity,
  (customerRegistrationEntity) => customerRegistrationEntity.customerProfile
);

export const getCustomerVerificationStatus = createSelector(
  getCustomerRegistrationEntity,
  (customerRegistrationEntity) => customerRegistrationEntity.isVerifiedToken
);

export const getCustomerVerificationEmailSendStatus = createSelector(
  getCustomerRegistrationEntity,
  (customerRegistrationEntity) => customerRegistrationEntity.resendVerification.isVerificationEmailSent
);

export const getPreregisteredEmailFromToken = createSelector(
  getCustomerRegistrationEntity,
  (customerRegistrationEntity) => customerRegistrationEntity.preregisteredEmailFromToken || ''
);

export const selectIsAccountRetrievalFormDirty = createSelector(
  selectAccountRetrievalForm,
  (accountRetrievalForm) => accountRetrievalForm.isDirty
);

export const selectIsValidateWithoutInvoiceFormDirty = createSelector(
  selectValidateWithoutInvoiceForm,
  (validateWithoutInvoiceForm) => validateWithoutInvoiceForm.isDirty
);

export const selectIsPersonalInfoFormDirty = createSelector(
  selectPersonalInformationForm,
  (personalInformationForm) => personalInformationForm.isDirty
);

export const selectInvalidTokenError = createSelector(getCustomerRegistrationEntity, (cre) => cre.invalidTokenError);
