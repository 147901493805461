import './cor-footer-shop-products.scss';

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { CorSalesRep } from 'corporate-components/cor-layout/cor-sales-rep';
import { CorContentstackLink } from 'corporate-components/cor-contentstack';
import iconUserOutlined from 'assets/images/icons/icon-user-outlined.svg';
import { IconWithHover } from 'components/icon-with-hover';
import { useContent } from 'hooks/use-content';
import { ContentstackText } from 'components/contentstack';
import { getSalesRepInformation } from 'store/account/actions';
import { formatPhoneNumber } from 'utils/string-format';
import { Routes } from 'constants/routes.enum';
import { selectSalesRepInformationEntity } from 'store/account/selectors';
import { useScrollTopOnClick } from 'hooks/use-scroll-top-on-click';
import { isShopLocaleInternational } from 'utils/is-shop-locale-international';
import { selectIsInvoiceOnlyCustomer } from 'store/auth/selectors';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';

const SHOP_PRODUCTS_CTA_KEY = 'shop_products_cta';
const ACCOUNT_CTA_KEY = 'account_cta';
const LABELS_KEY = 'footer_labels[0].key_value_pair.value';
const CUSTOM_IS_LOGGED_IN_KEY = 'custom_is_logged_in';
const FALLBACK_SALES_REPRESENTATIVE_KEY = 'support_block.0';

const CorFooterShopProducts = ({ onLogout = (e: React.SyntheticEvent<HTMLAnchorElement>) => {} }) => {
  const { getContentByKey, getLabelsByKey } = useContent();
  const labels = getLabelsByKey<Record<string, string>>(LABELS_KEY, {});
  const shopProductsCTA: any = getContentByKey(SHOP_PRODUCTS_CTA_KEY, {});
  const accountCTA: any = getContentByKey(ACCOUNT_CTA_KEY, {});
  const { dcPhoneNumber = '', salesRepInfo } = useSelector(selectSalesRepInformationEntity);
  const fallbackSalesRepresentative = getContentByKey<any>(FALLBACK_SALES_REPRESENTATIVE_KEY, {});
  const dispatch = useDispatch();
  const customIsLoggedIn = getContentByKey(CUSTOM_IS_LOGGED_IN_KEY, false);
  const hasRepInfo = !!salesRepInfo?.email && !!dcPhoneNumber;
  const hasCustomerServicePhone = (hasRepInfo && !!dcPhoneNumber) || !!fallbackSalesRepresentative?.phone?.title;
  const clickHandler = useScrollTopOnClick();
  const getLinkHref = useCallback(
    (index) => getContentByKey(`${ACCOUNT_CTA_KEY}.account_links[${index}].link`, { href: '' }).href,
    [getContentByKey]
  );
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);

  React.useEffect(() => {
    if (!customIsLoggedIn) return;
    dispatch(getSalesRepInformation.request());
  }, [dispatch, customIsLoggedIn]);

  return (
    <ul className="cor-footer-mobile__menu-links cor-footer-mobile__menu-links--shop-products">
      {shopProductsCTA?.include_shop_cta && (
        <li className="cor-footer-mobile__menu-link" data-testid="shop-products">
          <CorContentstackLink
            contentKey={`${SHOP_PRODUCTS_CTA_KEY}.button.button`}
            className={classNames(
              'grid-container cor-footer__menu-link__title cor-footer__menu-link__title--no-menu-links shop-products__cta'
            )}
            opensInNewTab={!!shopProductsCTA?.button?.opens_in_a_new_tab}
            onClick={($event) => clickHandler($event, Routes.ShopHomePage)}
          >
            <ContentstackText contentKey={`${SHOP_PRODUCTS_CTA_KEY}.button.button.title`} />
            <span className="cor-text-link__caret__isolated" />
          </CorContentstackLink>
        </li>
      )}
      {!customIsLoggedIn && (
        <li className="cor-footer-mobile__menu-link" data-testid="account-login">
          <CorContentstackLink
            contentKey={`${ACCOUNT_CTA_KEY}.button.button`}
            className={classNames(
              'grid-container cor-footer__menu-link__title cor-footer__menu-link__title--no-menu-links shop-products__cta'
            )}
            opensInNewTab={!!accountCTA?.button?.opens_in_a_new_tab}
            onClick={($event) => clickHandler($event, Routes.LoginPage)}
          >
            <IconWithHover icon={iconUserOutlined} hoverIcon={iconUserOutlined} alt="User Icon" />
            <ContentstackText contentKey={`${ACCOUNT_CTA_KEY}.button.button.title`} />
          </CorContentstackLink>
        </li>
      )}
      {customIsLoggedIn && (
        <li
          className="cor-footer-mobile__menu-link cor-footer-mobile__menu-link__logged"
          data-testid="sales-representative"
        >
          <CorSalesRep showPhone={false} isHeader={false} />
          <ul className="cor-footer-mobile__customer-service">
            {hasCustomerServicePhone && (
              <li data-testid="customer-service">
                <h4 className="cor-footer__menu-link__sub-title">{labels?.customer_service || 'CUSTOMER SERVICE'}</h4>
                <ul>
                  <li>
                    <a href={`tel:${hasRepInfo ? dcPhoneNumber : fallbackSalesRepresentative?.phone?.title}`}>
                      {formatPhoneNumber(hasRepInfo ? dcPhoneNumber : fallbackSalesRepresentative?.phone?.title)}
                    </a>
                  </li>
                </ul>
              </li>
            )}
            <li data-testid="account-login">
              <h4 className="cor-footer__menu-link__sub-title">{labels?.account || 'ACCOUNT'}</h4>
              <ul>
                {accountCTA?.account_links?.map(({ opens_in_a_new_tab = false }, index) => {
                  if (
                    (isInvoiceOnlyUser || isShopLocaleInternational()) &&
                    getLinkHref(index) === Routes.AccountOrderHistoryPage
                  ) {
                    return null;
                  }
                  return (
                    <li key={index.toString()}>
                      <CorContentstackLink
                        contentKey={`${ACCOUNT_CTA_KEY}.account_links[${index}].link`}
                        className={classNames('menu-link__menu-link')}
                        opensInNewTab={opens_in_a_new_tab}
                        onClick={($event) => clickHandler($event, getLinkHref(index))}
                      />
                    </li>
                  );
                })}
                <li>
                  <a href={getLocalizedShopUrl(Routes.LoginPage)} className="menu-link__menu-link" onClick={onLogout}>
                    {labels?.log_out || 'Log Out'}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      )}
    </ul>
  );
};

export default React.memo(CorFooterShopProducts);
