import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { CorFooter } from 'corporate-components';
import { useHistory } from 'react-router-dom';
import { PageLayout } from 'components/layout';
import { InternalUserHeader } from 'components/layout/internal-user-header';
import { CorHeader } from 'components/layout/cor-header';
import { selectIsInvoiceOnlyCustomer } from 'store/auth/selectors';
import { isShopLocaleInternational } from 'utils/is-shop-locale-international';
import { Routes } from 'constants/routes.enum';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';

interface IAccountLayoutProps extends PropsWithChildren {}

export const AccountLayout: FC<IAccountLayoutProps> = ({ children }) => {
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);
  const history = useHistory();
  const contentstackPath = history.location.pathname.includes(getLocalizedShopUrl(Routes.PaymentDetails))
    ? 'header[0]'
    : 'tabs[6].payment.content[0].header[0]';

  return isInvoiceOnlyUser || isShopLocaleInternational() ? (
    <PageLayout
      classModifier="default"
      header={() => <InternalUserHeader contentstackPath={contentstackPath} />}
      footer={CorFooter}
    >
      {children}
    </PageLayout>
  ) : (
    <PageLayout classModifier="default" header={CorHeader} footer={CorFooter}>
      {children}
    </PageLayout>
  );
};
