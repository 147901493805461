import api from 'api';
import { ICorProductTileProps } from 'corporate-components/cor-product-list/components/cor-product-tile';
import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Endpoints } from 'constants/endpoints.enum';

const PRODUCTS_MAX_TOTAL = 10;

export type ProductContextType = {
  products?: ICorProductTileProps[];
  toggleSelected: (value) => void;
  unSelectProducts: () => void;
};

export interface IProductProviderProps extends PropsWithChildren {
  skus: string[];
}

const defaultProductContextValue: ProductContextType = {
  products: [],
  toggleSelected: (value) => value,
  unSelectProducts: () => {},
};

const setIsSelected = (product: ICorProductTileProps): boolean => {
  const { isDiscontinued, isOutOfStock, isInactive, name } = product;
  if (isInactive || isDiscontinued || isOutOfStock || !name) {
    return false;
  }
  return true;
};

export const ProductContext = React.createContext<ProductContextType>(defaultProductContextValue);

export const ProductProvider: FC<IProductProviderProps> = ({ children, skus }) => {
  const [products, setProducts] = useState<ICorProductTileProps[]>();
  const unavailableParam = '?showInactiveSkus=True';
  const getProducts = useCallback(() => {
    api
      .get(`${Endpoints.CATALOG_SKUS}/${skus.join(',')}${unavailableParam}`, { withCredentials: true })
      .then(({ data }: { data: ICorProductTileProps[] }) =>
        setProducts(
          data
            .filter((product, idx) => idx < PRODUCTS_MAX_TOTAL)
            .map((product) => ({
              ...product,
              isSelected: setIsSelected(product),
            }))
        )
      );
  }, [skus]);

  useEffect(() => {
    if (!!skus.length) {
      return getProducts();
    }
  }, [skus, getProducts]);

  const toggleSelected = useCallback(
    (sku) => {
      if (!!products?.length) {
        const toggledProductState = products.map((product) => {
          if (product?.sku === sku) {
            return { ...product, isSelected: !product.isSelected };
          }
          return product;
        });
        setProducts([...toggledProductState] as ICorProductTileProps[]);
      }
    },
    [products]
  );

  const unSelectProducts = useCallback(
    () =>
      setProducts(
        products?.map((product) => ({
          ...product,
          isSelected: false,
        }))
      ),
    [products]
  );

  return (
    <ProductContext.Provider value={{ products, toggleSelected, unSelectProducts }}>{children}</ProductContext.Provider>
  );
};
