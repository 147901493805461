import React, { useCallback, useState } from 'react';
import { stringify } from 'qs';
import { ICSUtilityNavData } from 'components/layout/cor-header/cor-header.interface';
import { Endpoints } from '../constants/endpoints.enum';
import api from '../api';

interface IUseContentstackSearchTagsProps {
  contentstackParams: string;
  include?: string[];
  contentTypeName: string;
  locale?: string;
}

export const useContentstackSearchTags = (
  additionalContentTypeToFetch: IUseContentstackSearchTagsProps[] | undefined
): ICSUtilityNavData[] => {
  const [content, setContent] = useState<ICSUtilityNavData[]>([]);

  const fetch = useCallback(async () => {
    if (!additionalContentTypeToFetch || !Array.isArray(additionalContentTypeToFetch)) {
      return [];
    }

    const additionalContent = await Promise.all(
      additionalContentTypeToFetch.map(async ({ contentstackParams, contentTypeName = '', include = [], locale }) => {
        if (!contentstackParams || !contentTypeName) {
          return;
        }

        try {
          const params = {
            params: contentstackParams,
            contentTypeName: contentTypeName || '',
            ...(locale && { locale: locale }),
            ...(!!include?.length && { include }),
          };
          const { data } = await api.get(Endpoints.CONTENTSTACK_TAGS, {
            params,
            paramsSerializer: (params) => stringify(params, { arrayFormat: 'indices' }),
          });

          if (data) {
            return additionalContentTypeToFetch.length > 1 ? { [contentstackParams]: data[0] } : data[0];
          }
        } catch (e) {
          console.error(e);
          return [];
        }
      })
    );
    setContent(additionalContent as ICSUtilityNavData[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(additionalContentTypeToFetch)]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  return content;
};
