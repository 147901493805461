enum paymentMethodsActionTypes {
  ADD_CREDIT_CARD = 'ADD_CREDIT_CARD',
  GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS',
  CLEAR_SERVER_ERRORS = 'CLEAR_SERVER_ERRORS',
  SET_ADD_CREDIT_CARD_FORM_DIRTY = 'SET_ADD_CREDIT_CARD_FORM_DIRTY',
  DELETE_CREDIT_CARD = 'DELETE_CREDIT_CARD',
  GET_CREDIT_CARDS = 'GET_CREDIT_CARDS',
  ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT',
  ADD_BANK_ACCOUNT_EFT_PAD = 'ADD_BANK_ACCOUNT_EFT_PAD',
  REMOVE_BANK_ACCOUNT = 'REMOVE_BANK_ACCOUNT',
  REMOVE_BANK_ACCOUNT_EFT_PAD = 'REMOVE_BANK_ACCOUNT_EFT_PAD',
  CLEAR_ADD_CREDIT_CARD_FROM_ERRORS = 'CLEAR_ADD_CREDIT_CARD_FROM_ERRORS',
  CLEAR_ADD_BANK_ACCOUNT_FROM_ERRORS = 'CLEAR_ADD_BANK_ACCOUNT_FROM_ERRORS',
  CLEAR_ADD_BANK_ACCOUNT_EFT_PAD_FORM_ERRORS = 'CLEAR_ADD_BANK_ACCOUNT_EFT_PAD_FORM_ERRORS',
}

export default paymentMethodsActionTypes;
