import * as Yup from 'yup';
import { isEmpty } from 'lodash-es';
import { BankAccountType } from 'constants/payment-methods.enum';

interface IValidationMessages {
  required?: string;
  wrongFormat?: string;
  doNotMatch?: string;
  min?: number;
  max?: number;
}

const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[#$^+=!*()@%& _-])[A-Za-z\d#$^+=!*()@%& _-]{7,127}[A-Za-z\d#$^+=!*()@%&_-]$/;
const namePattern = /^[a-zA-Z-'àâäæèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+(\s+[a-zA-Z-'àâäæèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+)*$/;
const phonePattern = /^\d{10}$/;
const alphanumericPattern = /^[a-zA-Z0-9-\s]*$/;
export const pricePattern = /^[0-9]{1,7}(\.[0-9]{1,2})?$/;
export const payAmountPattern = /^[0-9]{1,7}([.,][0-9]{1,2})?$/;
const expireDatePattern = /^(0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])[/-]\d{2}$/;
const bankRoutingAndAccountNumberPattern = /^[0-9]+$/;

export const requiredValidator = (required: IValidationMessages['required']) => Yup.string().required(required);

export const nameValidator = ({ required = '', wrongFormat = '' }: IValidationMessages) =>
  Yup.string().required(required).matches(namePattern, wrongFormat).max(70, wrongFormat);

export const companyNameValidator = ({ required = '' }: IValidationMessages, refName: string) =>
  Yup.string()
    .notRequired()
    .when(refName, {
      is: (val: string) => val === BankAccountType.BUSINESS,
      then: Yup.string().required(required),
      otherwise: Yup.string().notRequired(),
    });

export const phoneValidator = ({ required = '', wrongFormat = '' }: IValidationMessages) =>
  Yup.string().required(required).matches(phonePattern, wrongFormat);

export const zipCodeValidator = ({ required = '', wrongFormat = '' }: IValidationMessages) =>
  Yup.string().required(required).matches(alphanumericPattern, wrongFormat).max(20);

export const priceValidator = ({ required = '', wrongFormat = '' }: IValidationMessages) =>
  Yup.string().required(required).matches(pricePattern, wrongFormat);

export const payAmountValidator = ({ required = '', wrongFormat = '' }: IValidationMessages) =>
  Yup.string().required(required).matches(payAmountPattern, wrongFormat);

export const bankRoutingAndAccountNumberValidator = ({
  required = '',
  wrongFormat = '',
  min = 1,
  max = 99999,
}: IValidationMessages) =>
  Yup.string()
    .required(required)
    .matches(bankRoutingAndAccountNumberPattern, wrongFormat)
    .min(min, wrongFormat)
    .max(max, wrongFormat);

export const quoteCommentValidator = ({ required = '' }: IValidationMessages, refName: string, initVal: string) =>
  Yup.string()
    .notRequired()
    .when(refName, {
      is: (val: string) => {
        return String(val) !== String(initVal);
      },
      then: Yup.string().required(required),
      otherwise: Yup.string().notRequired(),
    });

export const expireDateValidator = ({ required = '', wrongFormat = '' }: IValidationMessages) =>
  Yup.string().required(required).matches(expireDatePattern, wrongFormat);

export const newPasswordValidator = ({ required = '', wrongFormat = '' }: IValidationMessages, refName: string) =>
  Yup.string()
    .required(required)
    .matches(passwordPattern, wrongFormat)
    .test('matches-with-confirm-password', ' ', function (value) {
      const refValue = this.parent[refName];

      if (isEmpty(refValue)) return true;
      if (!passwordPattern.test(refValue)) return true;

      return refValue === value;
    });

export const confirmPasswordValidator = (
  { required = '', wrongFormat = '', doNotMatch = '' }: IValidationMessages,
  refName: string
) =>
  Yup.string()
    .required(required)
    .matches(passwordPattern, wrongFormat)
    .test('matches-with-password', doNotMatch, function (value?: string) {
      const refValue = this.parent[refName];
      return refValue === value;
    });

export const emailValidator = ({ required, wrongFormat }: IValidationMessages) =>
  Yup.string().email(wrongFormat).min(1, wrongFormat).max(254, wrongFormat).required(required);

export const newEmailValidator = ({ required, wrongFormat }: IValidationMessages, refName: string) => {
  const emailValidationSchema = emailValidator({ required, wrongFormat });

  return emailValidationSchema.concat(
    Yup.string().test('matches-with-confirm-email', ' ', function (value) {
      const refValue = this.parent[refName];

      if (!emailValidationSchema.isValidSync(refValue)) return true;

      return refValue === value;
    })
  );
};

export const confirmEmailValidator = (
  { required, wrongFormat, doNotMatch = '' }: IValidationMessages,
  refName: string
) =>
  Yup.string()
    .email(wrongFormat)
    .min(1, wrongFormat)
    .max(254, wrongFormat)
    .required(required)
    .test('matches-with-email', doNotMatch, function (value?: string) {
      const refValue = this.parent[refName];
      return refValue === value;
    });
