import React, { createContext, useState } from 'react';

export interface IHeaderContext {
  shopNavOpen: boolean;
  mobileMenuOpen: boolean;
  openedMenu: any;
  isLoggedInUser: boolean;
  setShopNavOpen: any;
  setOpenedMenu: any;
  setIsSearchMobileOpen: (isOpen: boolean) => void;
  isSearchMobileOpen: boolean;
  setMobileMenuOpen: any;
  setIsLoggedInUser: any;
  header: any;
}

const HeaderContext = createContext<IHeaderContext | undefined>(undefined);

function HeaderProvider(props) {
  const { children, header } = props;
  const [shopNavOpen, setShopNavOpen] = useState<boolean>(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [openedMenu, setOpenedMenu] = useState<any>({});
  const [isSearchMobileOpen, setIsSearchMobileOpen] = useState<boolean>(false);
  const [isLoggedInUser, setIsLoggedInUser] = useState<boolean>(false);

  const value = {
    shopNavOpen,
    setShopNavOpen,
    mobileMenuOpen,
    setMobileMenuOpen,
    openedMenu,
    setOpenedMenu,
    isLoggedInUser,
    setIsLoggedInUser,
    setIsSearchMobileOpen,
    isSearchMobileOpen,
    header,
  };
  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
}

function useMainNavState() {
  const context = React.useContext(HeaderContext);
  if (context === undefined) {
    throw new Error('useMainNavState must be used within a HeaderProvider');
  }
  return context;
}

export { HeaderProvider, useMainNavState };
