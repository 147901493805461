import { FC } from 'react';
import Slider, { CustomArrowProps } from 'react-slick';
import { ContentstackText } from 'components/contentstack';
import { IGuideItem } from 'types/guide-tile';
import { CorButton } from 'components/cor-button';

import './empty-shopping-list-slider.scss';

const EmptyShoppingListSliderrArrow: FC<CustomArrowProps> = ({ className, onClick }) => {
  return <button className={className} onClick={onClick} />;
};

interface IEmptyShoppingListSliderProps {
  contentPath?: string;
  guideTabs?: IGuideItem[];
  deletedLocation?: boolean;
  openCreateListModal?(): void;
}

export const EmptyShoppingListSlider: FC<IEmptyShoppingListSliderProps> = ({
  contentPath,
  guideTabs,
  deletedLocation,
  openCreateListModal,
}) => {
  const sliderSettings = {
    speed: 500,
    touchMove: false,
    dots: true,
    dotsClass: 'empty-shopping-list-slider__dots slick-dots',
    prevArrow: <EmptyShoppingListSliderrArrow />,
    nextArrow: <EmptyShoppingListSliderrArrow />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          touchMove: true,
        },
      },
    ],
  };

  return (
    <Slider className="empty-shopping-list-slider" {...sliderSettings}>
      {guideTabs?.map(({ icon, description, title }) => {
        return (
          <div className="empty-shopping-list-slider__wrapper">
            <div className="empty-shopping-list-slider__content">
              <h2>
                <ContentstackText contentKey={`${contentPath}.mobile_title`} />
              </h2>
              <p>
                <ContentstackText contentKey={`${contentPath}.mobile_description`} />
              </p>
            </div>
            <div className="empty-shopping-list-slider__guide-tiles">
              <div className="grid-container grid-x small-10 align-center">
                <div className="empty-shopping-list-slider__guide-tiles__icon">
                  <img src={icon.url} alt={title} />
                </div>
                <div
                  className="empty-shopping-list-slider__guide-tiles__description small-8"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </div>
            </div>
            <div className="empty-shopping-list-slider__button">
              <CorButton color="tertiary" disabled={deletedLocation} onClick={openCreateListModal}>
                <ContentstackText contentKey={`${contentPath}.create_list_button`} />
              </CorButton>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};
