import {
  useAddNewBankAccountEftPadModal,
  useAddNewBankAccountModal,
  useAddPaymentCardModal,
  usePaymentMethodsErrorModal,
  useSuccessErrorMessageModal,
} from './use-global-modal';

export const useAddPaymentMethod = () => {
  const showAddPaymentCardModal = useAddPaymentCardModal({});
  const showAddNewBankAccountModal = useAddNewBankAccountModal({});
  const showAddNewBankAccountEftPadModal = useAddNewBankAccountEftPadModal({});

  const showAddPaymentMethodFailModal = usePaymentMethodsErrorModal({
    messageId: 'MSG189',
  });

  const showAddPaymentMethodSuccessModal = useSuccessErrorMessageModal({
    type: 'success',
    hasCrossButton: true,
  });

  return {
    showAddNewBankAccountModal,
    showAddPaymentCardModal,
    showAddPaymentMethodFailModal,
    showAddPaymentMethodSuccessModal,
    showAddNewBankAccountEftPadModal,
  };
};
