import './cor-detail-hero-banner.scss';
import { ICSCorImage } from 'corporate-interfaces';
import { CorTypography } from 'corporate-ui';
import {
  CorContentstackImageAsset,
  corContentstackImageLinkResolver,
} from 'corporate-utils/cor-contentstack-image-link-resolver';
import React, { FC } from 'react';
import { useBreakpoint } from 'hooks/use-breakpoint';

export type CSCorIconTitle = {
  icon: ICSCorImage;
  title: string;
};

export type IconTitle = {
  title: string;
  image?: CorContentstackImageAsset;
};

export interface ICorDetailHeroBannerProps {
  tags?: string;
  heading?: string;
  description?: string;
  image?: ICSCorImage;
  dateAuthor?: string;
  iconTitleGroup?: CSCorIconTitle[];
}

export const CorDetailHeroBanner: FC<ICorDetailHeroBannerProps> = ({
  heading,
  description,
  image,
  tags,
  dateAuthor,
  iconTitleGroup,
}) => {
  const imageAsset = image ? corContentstackImageLinkResolver(image) : undefined;
  const tileIconsElementsMax = 6;
  const titleIcons: IconTitle[] | undefined = iconTitleGroup
    ?.slice(0, tileIconsElementsMax)
    ?.map(({ icon, title }) => ({
      image: icon ? corContentstackImageLinkResolver(icon) : undefined,
      title,
    }));
  const { isMobile } = useBreakpoint();

  if (!tags?.length && !heading && !description && !imageAsset) {
    return null;
  }

  return (
    <div className={'cor-detail-hero-banner'}>
      <div className="cor-detail-hero-banner__container">
        <div className="cor-detail-hero-banner__text-content">
          {!!tags && (
            <div className="cor-detail-hero-banner__tags">
              {
                <CorTypography
                  className={'cor-detail-hero-banner__tag'}
                  variant={'small-caps'}
                  color={'dawn-red'}
                  component={'span'}
                >
                  {tags}
                </CorTypography>
              }
            </div>
          )}
          {heading && (
            <CorTypography
              component={'h1'}
              className={'cor-detail-hero-banner__heading'}
              variant={'h1'}
              color={'dark-gray'}
            >
              {heading}
            </CorTypography>
          )}
          {description && (
            <CorTypography
              className={'cor-detail-hero-banner__description'}
              variant={'body-regular'}
              color={'mid-gray'}
              component={'div'}
            >
              {description}
            </CorTypography>
          )}

          {!!titleIcons?.length && (
            <div
              data-testid={'cor-detail-hero-banner__icons-titles'}
              className={'cor-detail-hero-banner__icons-titles'}
            >
              {titleIcons.map(({ title, image }, index) => (
                <div key={index.toString()} className={'cor-detail-hero-banner__icon-title'}>
                  {image && (
                    <img className="cor-detail-hero-banner__icon" src={image.url} alt={''} aria-hidden={true} />
                  )}
                  <CorTypography
                    className="cor-detail-hero-banner__icon-text"
                    variant={isMobile ? 'body-small' : 'navigation-body-1'}
                    color={'mid-gray'}
                  >
                    {title}
                  </CorTypography>
                </div>
              ))}
            </div>
          )}
          {dateAuthor && (
            <CorTypography
              className={'cor-detail-hero-banner__date-author'}
              variant={'body-regular'}
              color={'dawn-red'}
              component={'div'}
            >
              {dateAuthor}
            </CorTypography>
          )}
        </div>
        {imageAsset && (
          <div className="cor-detail-hero-banner__graphic">
            <img className={'cor-detail-hero-banner__image'} src={imageAsset.url} alt={imageAsset.image_alt_text} />
          </div>
        )}
      </div>
    </div>
  );
};
