import 'corporate-components/cor-accordion/components/accordion-item/cor-accordion-item.scss';

import React, { FC, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ICSCorButton } from 'corporate-interfaces';
import { CorTypography } from 'corporate-ui';
import { CorContentstackHtml } from 'corporate-components/cor-contentstack/cor-contentstack-html';
import { get } from 'lodash-es';
import { corContentstackAssetLinkResolver } from 'corporate-utils/cor-contentstack-asset-link-resolver';
import { CorButtonLink } from 'components/cor-button-link';

export interface ICSAccordionItemStep {
  button: ICSCorButton;
  default_to_expanded: boolean;
  accordion_description: string;
  accordion_heading: string;
}

export const CorAccordionItem: FC<{
  step: ICSAccordionItemStep;
  stepIdx: number;
}> = ({ step, stepIdx }) => {
  const { button, default_to_expanded } = step;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (default_to_expanded) {
      setOpen(default_to_expanded);
    }
  }, [default_to_expanded]);

  return (
    <div key={stepIdx.toString()} className={'cor-accordion-item'}>
      <div className={'cor-accordion-item__head'}>
        <CorTypography className={'cor-accordion-item__heading'} variant={'h3'} color={'dark-gray'} component={'div'}>
          {step.accordion_heading}
        </CorTypography>
        <div className="cor-accordion-item__button" onClick={() => setOpen(!open)}>
          {!open && (
            <span className={'cor-accordion-item__icon cor-accordion-item__icon--plus'}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 0V14" stroke="#E03C31" strokeWidth="2" />
                <path d="M14 7L1.78814e-07 7" stroke="#E03C31" strokeWidth="2" />
              </svg>
            </span>
          )}
          {open && (
            <span className={'cor-accordion-item__icon cor-accordion-item__icon--minus'}>
              <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 1L1.78814e-07 0.999999" stroke="#E03C31" strokeWidth="2" />
              </svg>
            </span>
          )}
        </div>
      </div>
      <CSSTransition in={open} timeout={200}>
        <div className={'cor-accordion-item__description'}>
          <CorTypography
            variant={'body-large'}
            color={'mid-gray'}
            component={'div'}
            className={'cor-accordion-item__rich-text cor-rich-text'}
          >
            <CorContentstackHtml contentKey={'accordion_description'} />
          </CorTypography>
          {(button?.button?.href || button?.button?.title) && (
            <div className={'cor-accordion-item__link-button'}>
              <CorButtonLink
                color={(button?.button_style || 'primary').toLowerCase()}
                href={button?.button?.href}
                target={button?.open_in_a_new_tab ? '_blank' : '_self'}
                iconUrl={get(button, 'button_icon[0].cdn_url')}
                iconPosition={(button?.button_alignment as any) || 'left'}
                asset={corContentstackAssetLinkResolver(button)?.url}
              >
                {button?.button?.title}
              </CorButtonLink>
            </div>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};
