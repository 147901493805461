import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from 'lodash-es';
import { useIdleTimer } from 'react-idle-timer';
import { history } from 'store';
import { Routes } from 'constants/routes.enum';
import { setIsClientIdle } from 'store/customer-login/actions';
import { selectIsClientIdle } from 'store/customer-login/selectors';
import { selectIsLoggedInUser } from 'store/auth/selectors';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';

export const SessionTimer: FC = () => {
  const dispatch = useDispatch();
  const timeout = 1000 * 60 * 15.6;
  const isClientIdle = useSelector(selectIsClientIdle);

  const setSessionTimeout = () => {
    history.push(getLocalizedShopUrl(Routes.LoginPage));
    dispatch(setIsClientIdle(true));
    localStorage.removeItem('session');
  };

  const setSessionActive = () => {
    if (isClientIdle && window.location.pathname.includes(Routes.LoginPage)) {
      throttle(() => {
        dispatch(setIsClientIdle(false));
      }, 250);
    }
  };

  const isLoggedInUser = useSelector(selectIsLoggedInUser);

  const { pause, resume } = useIdleTimer({
    timeout: timeout,
    onIdle: setSessionTimeout,
    onActive: setSessionActive,
  });

  useEffect(() => {
    if (isLoggedInUser) {
      resume();
    } else {
      pause();
    }
  }, [dispatch, isLoggedInUser, resume, pause]);

  return null;
};
