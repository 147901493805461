import React, { FC } from 'react';
import classNames from 'classnames';
import { ICSCorButton } from 'corporate-interfaces';
import { get } from 'lodash-es';
import { corContentstackAssetLinkResolver } from 'corporate-utils/cor-contentstack-asset-link-resolver';
import { CorButtonLink } from 'components/cor-button-link';

export interface ICorButtonsGroupProps {
  buttons: ICSCorButton[];
  className?: string;
  onPrint?: ($event) => void;
  onDownload?: ($event) => void;
}

export const CorButtonsGroupRow: FC<ICorButtonsGroupProps> = ({ buttons, className, onDownload, onPrint }) => {
  const assignClickEvent = (buttonType, $event) => {
    if (buttonType === 'download' && onDownload) {
      return onDownload($event);
    }

    if (buttonType === 'print' && onPrint) {
      return onPrint($event);
    }

    return;
  };

  return (
    <div className={classNames('cor-buttons-group__row', className)} data-testid="buttons-group-row">
      {buttons.map((button: ICSCorButton, index) => (
        <CorButtonLink
          color={(button?.button_style || 'primary').toLowerCase()}
          href={button?.button?.href}
          target={button?.open_in_a_new_tab ? '_blank' : '_self'}
          iconUrl={get(button, 'button_icon[0].cdn_url')}
          iconPosition={button?.button_alignment}
          key={`btn-link-${index}`}
          clickHandler={($event) => assignClickEvent(button.button_style, $event)}
          asset={corContentstackAssetLinkResolver(button)?.url}
        >
          {button?.button.title}
        </CorButtonLink>
      ))}
    </div>
  );
};
