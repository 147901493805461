import { FC, ChangeEvent, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash-es';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Routes } from 'constants/routes.enum';
import { Permissions } from 'constants/permissions';
import { Modal } from 'components/modals/common/modal';
import { CustomTooltip } from 'components/custom-tooltip';
import { ProductImage } from 'components/product-image';
import { useContent } from 'hooks/use-content';
import { useLocalStorage } from 'hooks/use-local-storage';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { CorButton } from 'components/cor-button';
import { QuantityControl } from 'components/quantity-control';
import { SkuQuantityLimitationAlert } from 'components/sku-quantity-limitation-alert';
import { SkuQuantityLimitationWarning } from 'components/sku-quantity-limitation-warning';
import { ContentstackMessage, ContentstackHtml, ContentstackText } from 'components/contentstack';
import { getSkuTotalAmount } from 'store/quick-shop/actions';
import { stripLeadingZeros } from 'utils/string-format';
import { LoadingIndicator } from 'components/loading-indicator';
import { formatCurrency, formatSegmentationCurrency } from 'utils/currency-format';
import { ProductGroupSelector } from 'features/product-group-selector';
import { ProductVariantSelector } from 'features/product-variant-selector';
import { ProductStatusLabels } from 'components/product-status-labels';
import { ProductIndicators } from 'components/product-indicators';
import { PermissionAccess } from 'components/permission-access';
import { AccessDefinitions } from 'constants/access-definitions.enum';
import { selectQuickShopLoading, selectSkuTotalAmountLoading } from 'store/quick-shop/selectors';
import { selectTotalAmount } from 'store/quick-shop/selectors';
import { addProductToCart } from 'store/shopping-cart/actions';
import { getProductDetails, resetProductDetails, setSelectedSku } from 'store/product-details/actions';
import { IGetProductDetailsPayload } from 'store/product-details/sagas/get-product-details';
import { selectProductInfo, selectProductSelectedSkuInfo } from 'store/product-details/selectors';
import { getSkuReplacements } from 'store/items-replacements/actions';
import { IGetSkuReplacementsPayload } from 'store/items-replacements/sagas/get-sku-replacements';
import { selectSkuReplacementsData, selectSkuReplacementsLoading } from 'store/items-replacements/selectors';
import { requestQuote } from 'store/quotes/actions';
import { IRequestQuotePayload } from 'store/quotes/sagas/request-quote';
import { IAddProductToCartPayload } from 'store/shopping-cart/sagas/add-product-to-cart';
import iconAddedToCart from 'assets/images/icons/icon-added-to-cart.svg';
import iconInfoDisabled from 'assets/images/icons/icon-info-disabled.svg';
import { selectIsKeyAccountUser } from 'store/auth/selectors';
import iconInfo from 'assets/images/icons/icon-info.svg';
import { HierarchyAccountsSpecialOrderMessage } from 'components/hierarchy-accounts-special-order-message';
import { SimilarItems } from 'features/similar-items';
import { clearSimilarItems, getSimilarItems } from 'store/similar-items/actions';
import { IGetSimilarItemsPayload } from 'store/similar-items/sagas/get-similar-items';
import { selectSimilarItemsData, selectSimilarItemsLoading } from 'store/similar-items/selectors';
import { ModalFooter } from 'components/modals/common/modal/components';
import { selectLineItems } from 'store/shopping-cart/selectors';
import { ProductReplacement } from 'pages/product-details/components/product-replacement';

import './quick-shop-modal.scss';

export interface IQuickShopModalProps {
  isOpen: boolean;
  onClose: () => void;
  overrideTooltipPosition?: boolean;
  onRequestQuoteCtaClick: (skuKey: string, isOpenedWithinQuickShop: boolean) => void;
  productKey?: string;
  selectedSkuNumber?: string;
  requestQuoteSuccessCallBack?: () => void;
  requestQuoteFailCallBack?: () => void;
}

export const QuickShopModal: FC<IQuickShopModalProps> = ({
  isOpen,
  onClose,
  overrideTooltipPosition = true,
  productKey,
  selectedSkuNumber = '',
  onRequestQuoteCtaClick,
  requestQuoteSuccessCallBack,
  requestQuoteFailCallBack,
}) => {
  const contentstackPath = 'modals.0.quick_shop_modal.0';
  const { isDesktop, isMobile } = useBreakpoint();
  const { getContentByKey } = useContent();
  const dispatch = useDispatch();
  const [showSegmentationPrice] = useLocalStorage('showSegmentationPrice', false);

  const isProductBeingUpdated = useSelector(selectSkuTotalAmountLoading);
  const isQuickShopLoading = useSelector(selectQuickShopLoading);
  const skuTotalAmount = useSelector(selectTotalAmount);
  const isKeyAccountUser = useSelector(selectIsKeyAccountUser);
  const productsInCart = useSelector(selectLineItems);
  const quantityInputRef = useRef<HTMLInputElement>(null);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [productKeyNumber, setProductKeyNumber] = useState(productKey);
  const [skuNumber, setSkuNumber] = useState(selectedSkuNumber);
  const imageDesktopSize = { width: 296, height: 296 };
  const imageMobileSize = { width: 328, height: 328 };
  const skuQuantity = quantityInputRef.current?.value;
  const oneSkuSelected = skuQuantity && Number(skuQuantity) === 1;

  const selectedSku = useSelector(selectProductSelectedSkuInfo);
  const productInfo = useSelector(selectProductInfo);
  const similarItems = useSelector(selectSimilarItemsData);

  const defaultSku = productInfo?.skus?.find(({ isDefault }) => isDefault);

  const sku = selectedSku || defaultSku;
  const isDiscontinued = sku?.isDiscontinued;
  const isPhasingOut = sku?.isPhasingOut;
  const isSpecialOrder = sku?.isSpecialOrder;
  const isOutOfStock = sku?.isOutOfStock;
  const minimumOrderQuantity = sku?.minimumOrderQuantity || 1;
  const maximumOrderQuantity = sku?.maximumQuantity;
  const hasMaximumQuantityLimitation = !!maximumOrderQuantity && maximumOrderQuantity > 0;
  const hasMinimumQuantityLimitation = minimumOrderQuantity > 1;
  const isShortSupplySku = sku?.maximumQuantity === 0;
  const isInCart = productsInCart?.some((product) => product.itemNumber === sku?.sku);

  const skuReplacementsData = useSelector(selectSkuReplacementsData);
  const skuReplacementsLoading = useSelector(selectSkuReplacementsLoading);
  const skuSimilarItemsLoading = useSelector(selectSimilarItemsLoading);
  const isDiscountinuedWithReplacement = sku?.skuReplacements.length > 0 && isDiscontinued && skuReplacementsData[0];

  useEffect(() => {
    if (isOpen && productKeyNumber) {
      dispatch(
        getProductDetails.request<IGetProductDetailsPayload>({
          productId: productKeyNumber,
          selectedSku: skuNumber ? skuNumber : '',
        })
      );
    }

    return () => {
      dispatch(resetProductDetails());
      setMessageText('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen, productKeyNumber, skuNumber]);

  useEffect(() => {
    if (isOpen && sku?.skuReplacements.length) {
      dispatch(
        getSkuReplacements.request<IGetSkuReplacementsPayload>({
          skus: sku?.skuReplacements.join(','),
        })
      );
    }
  }, [dispatch, isOpen, sku?.skuReplacements]);

  useEffect(() => {
    if (isOpen && !isEmpty(sku?.similarItems)) {
      dispatch(
        getSimilarItems.request<IGetSimilarItemsPayload>({
          skus: sku.similarItems.join(','),
        })
      );
    }

    return () => {
      dispatch(clearSimilarItems());
    };
  }, [dispatch, isOpen, sku?.similarItems]);

  useEffect(() => {
    if (!isOpen) return;

    if (isSpecialOrder) {
      setMessageText('');
    } else {
      dispatch(getSkuTotalAmount.request({ skuNum: sku?.sku, amount: quantityInputRef.current?.value }));
    }
  }, [dispatch, isOpen, isSpecialOrder, sku]);

  const addProduct = () => {
    if (quantityInputRef.current) {
      dispatch(
        addProductToCart.request<IAddProductToCartPayload>({
          quantity: Number(quantityInputRef.current.value),
          sku: sku?.sku,
          sendGA: true,
        })
      );
    }
  };

  const onProductButtonClick = () => () => {
    setIsButtonActive(true);

    addProduct();

    setButtonDisabled(true);
    setTimeout(() => {
      setIsButtonActive(false);
      setButtonDisabled(false);
    }, 1000);
  };

  const onRequestQuoteClick = () => {
    dispatch(
      requestQuote.request<IRequestQuotePayload>({
        sku: sku?.sku,
        comment: messageText,
        quantity: Number(quantityInputRef.current?.value) || Number(minimumOrderQuantity) || 1,
        onSuccessCallback: requestQuoteSuccessCallBack,
        onFailCallback: requestQuoteFailCallBack,
      })
    );
  };

  const setQuantity = (quantity?: number) => {
    if (quantity && !isSpecialOrder) {
      dispatch(getSkuTotalAmount.request({ skuNum: sku?.sku, amount: quantity }));
    }
  };

  // TODO: P12020-12794 remove after done
  // const getTotalSkuDefaultQuantity = () => {
  //   if (!isSpecialOrder) {
  //     dispatch(getSkuTotalAmount.request({ skuNum: sku?.sku }));
  //   }
  // };

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    setMessageText(inputValue);
  };

  const contactMsrButtonClass = classNames('quick-shop-modal__contact-msr-button', { active: isButtonActive });
  const addToCartButtonClass = classNames('quick-shop-modal__add-to-cart', {
    active: isButtonActive,
  });

  const modalClasses = classNames('quick-shop-modal', {
    'quick-shop-modal--with-loading-indicator': isQuickShopLoading || skuReplacementsLoading || skuSimilarItemsLoading,
    'quick-shop-modal--with-bottom-padding': !isEmpty(sku?.similarItems),
  });

  const skuClasses = classNames('quick-shop-modal__content', {
    'quick-shop-modal__sku--discontinued-with-replacement': isDiscontinued && isDiscountinuedWithReplacement,
    'quick-shop-modal__sku--discontinued-no-replacement': isDiscontinued && !isDiscountinuedWithReplacement,
    'quick-shop-modal__sku--out-of-stock-with-similar': isOutOfStock && !isEmpty(similarItems),
    'quick-shop-modal__sku--special-order': isSpecialOrder,
    'quick-shop-modal__sku--short-supply': isShortSupplySku,
  });

  const getValidQuantity = (quantity: number) => {
    if (minimumOrderQuantity && quantity < minimumOrderQuantity) {
      return minimumOrderQuantity;
    } else if (maximumOrderQuantity && quantity > maximumOrderQuantity) {
      return maximumOrderQuantity;
    } else {
      return quantity || minimumOrderQuantity;
    }
  };

  const getQuantityWarningMessage = () => {
    if (isShortSupplySku) return 'MSG091';
    if (hasMinimumQuantityLimitation && !hasMaximumQuantityLimitation) return 'MSG015';
    if (hasMaximumQuantityLimitation && hasMinimumQuantityLimitation) return 'MSG093';
    if (hasMaximumQuantityLimitation && !hasMinimumQuantityLimitation) return 'MSG094';
    return null;
  };
  const quantityWarningMessageId = getQuantityWarningMessage();

  const onProductGroupSelectorChange = (sku, productKey) => {
    setProductKeyNumber(productKey);
    setSkuNumber(sku);
    dispatch(setSelectedSku(sku));
  };

  return (
    <Modal size="large" isOpen={isOpen} className={modalClasses} onClose={onClose}>
      {isQuickShopLoading || skuReplacementsLoading || skuSimilarItemsLoading ? (
        <LoadingIndicator />
      ) : (
        <div className={skuClasses}>
          <div className="quick-shop-modal__product-details grid-x">
            <div className="cell medium-5 small-12">
              <div className="quick-shop-modal__image-msg-wrapper">
                <div
                  className={classNames('quick-shop-modal__image', {
                    'quick-shop-modal__image-has-warning-msg': quantityWarningMessageId,
                  })}
                >
                  <Link
                    className="product-card__link"
                    to={`${Routes.ProductDetailsPage}/${productKeyNumber}?selectedSku=${sku?.sku}`}
                  >
                    <ProductIndicators size="large" isNoLongerAvailable={isDiscontinued} isLowInStock={isPhasingOut} />
                    <ProductImage
                      src={sku?.imageUrls[0]}
                      altText={sku?.name}
                      desktopSize={imageDesktopSize}
                      mobileSize={imageMobileSize}
                      withOverlay={isOutOfStock && !isDiscontinued}
                    />
                    {(isDiscontinued || isSpecialOrder || isOutOfStock) && (
                      <div className="quick-shop-modal__statuses-wrapper">
                        <ProductStatusLabels
                          isDiscontinued={isDiscontinued}
                          isSpecialOrder={isSpecialOrder}
                          isOutOfStock={isOutOfStock}
                        />
                      </div>
                    )}
                  </Link>
                </div>
                <div className="quick-shop-modal__quantity-warning-msg">
                  {quantityWarningMessageId && (
                    <SkuQuantityLimitationWarning className="quick-shop-modal__quantity-warning">
                      <ContentstackMessage type="notifications" messageId={quantityWarningMessageId} />
                    </SkuQuantityLimitationWarning>
                  )}
                </div>
              </div>
            </div>
            <div className="cell medium-7 small-12">
              <div className="quick-shop-modal__product-info">
                <div className="quick-shop-modal__product-info-main">
                  <p className="quick-shop-modal__brand">{sku?.brand}</p>
                  <p className="quick-shop-modal__name">
                    <Link
                      className="product-card__link"
                      to={`${Routes.ProductDetailsPage}/${productKeyNumber}?selectedSku=${sku?.sku}`}
                    >
                      {sku?.name}
                    </Link>
                  </p>
                  <p className="quick-shop-modal__info-item">
                    <span className="quick-shop-modal__label">
                      <ContentstackText contentKey={`${contentstackPath}.item_number_label`} />
                    </span>
                    <span className="quick-shop-modal__info-value">{sku?.sku && stripLeadingZeros(sku.sku)}</span>
                  </p>
                  {sku?.skuDetails.color && (
                    <p className="quick-shop-modal__info-item">
                      <span className="quick-shop-modal__label small-4">
                        <ContentstackText contentKey={`${contentstackPath}.color_label`} />
                      </span>
                      <span className="quick-shop-modal__info-value">{sku.skuDetails.color}</span>
                    </p>
                  )}
                  {sku?.skuDetails.flavor && (
                    <p className="small-12 quick-shop-modal__info-item">
                      <span className="quick-shop-modal__label">
                        <ContentstackText contentKey={`${contentstackPath}.flavor_label`} />
                      </span>
                      <span className="quick-shop-modal__info-value">{sku?.skuDetails.flavor}</span>
                    </p>
                  )}
                  <p className="quick-shop-modal__info-item">
                    {(sku?.skuDetails.weight || sku?.skuDetails.grossWtLbs) && (
                      <>
                        <span className="quick-shop-modal__label">
                          <ContentstackText contentKey={`${contentstackPath}.variant_selector.sku_weight_label`} />
                        </span>
                        <span className="quick-shop-modal__sku-weight">
                          {sku?.skuDetails.weight && (
                            <>
                              {' '}
                              <ContentstackHtml
                                contentKey={`${contentstackPath}.variant_selector.net_wt_label`}
                                component="span"
                                interpolateParams={{
                                  weight: sku?.skuDetails.weight,
                                }}
                              />
                            </>
                          )}
                          {sku?.skuDetails.grossWtLbs && (
                            <>
                              {' '}
                              <ContentstackHtml
                                contentKey={`${contentstackPath}.variant_selector.gross_wt_label`}
                                component="span"
                                interpolateParams={{
                                  grossWtLbs: sku?.skuDetails.grossWtLbs,
                                }}
                              />
                            </>
                          )}
                        </span>
                      </>
                    )}
                  </p>
                </div>

                {productInfo?.groupSelectors ? (
                  <ProductGroupSelector onSelectorChange={onProductGroupSelectorChange} />
                ) : (
                  <ProductVariantSelector
                    modifyUrl={false}
                    className="quick-shop-modal__product-variant-dropdown"
                    contentstackPath={`${contentstackPath}.variant_selector`}
                  />
                )}

                {isDiscountinuedWithReplacement && (
                  <div className="quick-shop-modal__discountinued-message">
                    <ContentstackText contentKey={`${contentstackPath}.discountinued_message`} />
                  </div>
                )}
                {isOutOfStock && !isEmpty(similarItems) && (
                  <span className="quick-shop-modal__out-of-stock-message">
                    <ContentstackMessage type="notifications" messageId="MSG091" />
                  </span>
                )}
                {!isDiscontinued && !isShortSupplySku && !(isSpecialOrder && isKeyAccountUser) && !isOutOfStock && (
                  <>
                    <div
                      className={classNames('quick-shop-modal__quantity-block', {
                        'quick-shop-modal__quantity-block-with-selectors': productInfo?.groupSelectors,
                        'quick-shop-modal__quantity-block-special-order': sku?.isSpecialOrder,
                      })}
                    >
                      <div className="quick-shop-modal__quantity-segmentation">
                        <div className="quick-shop-modal__quantity-label-area">
                          <div className="quick-shop-modal__quantity-label">
                            <ContentstackText contentKey={`${contentstackPath}.quantity_label`} />
                          </div>
                          <QuantityControl
                            className="quick-shop-modal__quanty-control"
                            contentStackPath="modals.0.quick_shop_modal.0"
                            quantity={getValidQuantity(Number(quantityInputRef.current?.value))}
                            minQuantity={minimumOrderQuantity}
                            maxQuantity={sku?.maximumQuantity}
                            onDecreaseQuantityHandler={setQuantity}
                            onIncreaseQuantityHandler={setQuantity}
                            // TODO: P12020-12794 remove after done
                            // setDefaultQuantity={getTotalSkuDefaultQuantity}
                            setCurrentQuantity={setQuantity}
                            inputRef={quantityInputRef}
                            disabled={isProductBeingUpdated}
                            forceChange
                          />
                        </div>
                        {showSegmentationPrice && sku?.priceModel && sku?.priceModel.segmentationAmount && isMobile && (
                          <div className="quick-shop-modal__segmentation-amount">
                            <div className="quick-shop-modal__segmentation-price-label">
                              <ContentstackText contentKey={`${contentstackPath}.desktop_segmentation_price_label`} />
                            </div>
                            <div className="quick-shop-modal__segmentation-price">
                              {formatSegmentationCurrency(sku.priceModel)}
                            </div>
                          </div>
                        )}
                      </div>

                      {hasMinimumQuantityLimitation && (
                        <SkuQuantityLimitationAlert className="quick-shop-modal__quantity-notification">
                          <ContentstackMessage
                            type="notifications"
                            messageId="MSG077"
                            interpolateParams={{
                              minimumOrderQuantity: minimumOrderQuantity,
                            }}
                          />
                        </SkuQuantityLimitationAlert>
                      )}
                      {hasMaximumQuantityLimitation && (
                        <SkuQuantityLimitationAlert className="quick-shop-modal__quantity-notification">
                          <ContentstackMessage
                            type="notifications"
                            messageId="MSG092"
                            interpolateParams={{
                              maximumOrderQuantity: sku?.maximumQuantity,
                            }}
                          />
                        </SkuQuantityLimitationAlert>
                      )}
                    </div>
                    {isSpecialOrder && (
                      <div className="quick-shop-modal__message-block">
                        <p className="quick-shop-modal__message-block-label">
                          <ContentstackText contentKey={`${contentstackPath}.message_label`} />
                        </p>
                        <textarea
                          className="quick-shop-modal__message-block-input"
                          placeholder={getContentByKey<string>(`${contentstackPath}.message_placeholder`, '')}
                          value={messageText}
                          maxLength={300}
                          onChange={handleInputChange}
                          aria-label="Message (Optional)"
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {(isDiscountinuedWithReplacement || !isEmpty(similarItems)) && (
            <div
              className={classNames('quick-shop-modal__replacements', {
                'quick-shop-modal__replacements-discontinued-with-replacement':
                  isDiscountinuedWithReplacement || (isDiscontinued && !isEmpty(similarItems)),
              })}
            >
              {!isEmpty(similarItems) && (
                <div className="quick-shop-modal__similar-items">
                  <SimilarItems similarItems={similarItems} onRequestQuoteCtaClick={onRequestQuoteCtaClick} />
                </div>
              )}
              {isDiscountinuedWithReplacement && <ProductReplacement />}
            </div>
          )}

          {!isDiscountinuedWithReplacement && (
            <ModalFooter
              className={classNames('quick-shop-modal__footer', {
                'quick-shop-modal__footer-with-similar-items': !isEmpty(similarItems),
                'quick-shop-modal__footer-with-no-variants': !productInfo?.groupSelectors,
                'quick-shop-modal__footer-discontinued-with-replacement':
                  isDiscountinuedWithReplacement || (isOutOfStock && !isEmpty(similarItems)),
              })}
            >
              {showSegmentationPrice && sku?.priceModel && sku?.priceModel.segmentationAmount && isDesktop && (
                <div
                  className={classNames('quick-shop-modal__segmentation-amount', {
                    'quick-shop-modal__segmentation-amount-desktop': isDesktop,
                  })}
                >
                  <div
                    className={classNames('quick-shop-modal__segmentation-price-label', {
                      'quick-shop-modal__segmentation-price-label-desktop': isDesktop,
                    })}
                  >
                    <ContentstackText contentKey={`${contentstackPath}.desktop_segmentation_price_label`} />
                  </div>
                  <div className="quick-shop-modal__segmentation-price">
                    {formatSegmentationCurrency(sku.priceModel)}
                  </div>
                </div>
              )}
              {!isSpecialOrder ? (
                <div className="quick-shop-modal__price-footer">
                  <div className="quick-shop-modal__sub-total">
                    {!isDiscontinued && !isShortSupplySku && !isOutOfStock && (
                      <>
                        <p className="quick-shop-modal__price">
                          {oneSkuSelected ? sku?.priceModel.currencySymbol : skuTotalAmount?.currencySymbol}
                          {oneSkuSelected ? sku?.priceModel.amount : skuTotalAmount?.amount}
                        </p>
                        {sku?.priceModel && (
                          <div className="quick-shop-modal__amount">
                            <div className="quick-shop-modal__sku-price">
                              <span className="quick-shop-modal__unit-price">
                                {formatCurrency(sku.priceModel)}
                                <span className="quick-shop-modal__price-seperator">
                                  <ContentstackText contentKey={`${contentstackPath}.price_seperator`} />
                                </span>
                              </span>
                              <span className="quick-shop-modal__unit-price-label">
                                <ContentstackText contentKey={`${contentstackPath}.unit_price_label`} />
                              </span>
                            </div>
                          </div>
                        )}{' '}
                      </>
                    )}
                  </div>

                  <PermissionAccess requiredPermissions={Permissions[AccessDefinitions.SHOPPING_CART_ACTIONS]}>
                    <div
                      className={classNames('quick-shop-modal__add-to-cart-wrapper', {
                        'quick-shop-modal__add-to-cart-wrapper-disabled':
                          (isDiscontinued || isShortSupplySku || (isOutOfStock && isEmpty(similarItems))) && isMobile,
                      })}
                    >
                      {isInCart && <img className="icon-added-to-cart" src={iconAddedToCart} alt="added to cart" />}
                      <CorButton
                        disabled={
                          buttonDisabled ||
                          isDiscontinued ||
                          isShortSupplySku ||
                          (isOutOfStock && isEmpty(similarItems))
                        }
                        className={addToCartButtonClass}
                        onClick={onProductButtonClick()}
                      >
                        <ContentstackText
                          contentKey={
                            isButtonActive
                              ? `${contentstackPath}.add_to_cart_button_label_active`
                              : `${contentstackPath}.add_to_cart_button_label`
                          }
                        />
                      </CorButton>
                    </div>
                  </PermissionAccess>
                </div>
              ) : (
                !(isSpecialOrder && isKeyAccountUser) && (
                  <div className="quick-shop-modal__special-order-footer">
                    <CorButton
                      disabled={buttonDisabled || isDiscontinued || isShortSupplySku}
                      className={contactMsrButtonClass}
                      onClick={onRequestQuoteClick}
                    >
                      <ContentstackText contentKey={`${contentstackPath}.request_quote_button_label`} />
                    </CorButton>
                    <CustomTooltip
                      tooltipId={`productCardSpecialOrder-${sku}`}
                      overrideTooltipPosition={overrideTooltipPosition}
                      showTooltip={!isDiscontinued || !isShortSupplySku}
                      customIcon={isDiscontinued || isShortSupplySku ? iconInfoDisabled : iconInfo}
                    >
                      <ContentstackMessage type="tooltips" messageId="MSG107" />
                    </CustomTooltip>
                  </div>
                )
              )}
              {isSpecialOrder && isKeyAccountUser && <HierarchyAccountsSpecialOrderMessage />}
            </ModalFooter>
          )}
        </div>
      )}
    </Modal>
  );
};
