import { FC, ReactNode, useState } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import iconInfo from 'assets/images/icons/icon-info.svg';

import './custom-tooltip.scss';

export interface ICustomTooltipProps {
  className?: string;
  tooltipId: string;
  overrideTooltipPosition?: boolean;
  children?: ReactNode;
  customIcon?: string;
  hoverIcon?: string;
  hasClickHandling?: boolean;
  showTooltip?: boolean;
  title?: ReactNode | string;
}

export const CustomTooltip: FC<ICustomTooltipProps> = ({
  className,
  children,
  tooltipId,
  overrideTooltipPosition = true,
  customIcon = '',
  hoverIcon = '',
  hasClickHandling,
  showTooltip = true,
  title,
}) => {
  const overridePosition = (
    { left, top }: { left: number; top: number },
    currentEvent: Event,
    currentTarget: Element,
    node: any
  ) => {
    if (overrideTooltipPosition) {
      const documentElement = document.documentElement;
      left = Math.min(documentElement.clientWidth - node.clientWidth, left);
      top = Math.min(documentElement.clientHeight - node.clientHeight, top);
      left = Math.max(0, left);
      top = Math.max(0, top);
      return { top, left };
    }
    return { left, top };
  };

  const [hover, setHover] = useState(false);
  const [touchDetected, setTouchDetected] = useState(false);

  const onMouseEnter = (): void => {
    setHover(true);
  };

  const onMouseLeave = (): void => {
    setHover(false);
  };

  const getIcon = (): string | ReactNode => {
    const currentIcon = hover && hoverIcon ? hoverIcon : customIcon ? customIcon : iconInfo;

    if (currentIcon && typeof currentIcon === 'string') {
      return <img src={currentIcon} alt="information tooltip icon" />;
    }

    return currentIcon;
  };

  const detectTouch = () => {
    // without this check (which gets fed to the disable prop below), tooltips appear on click on ipad
    setTouchDetected(true);
  };

  const eventList = hasClickHandling ? 'mouseover mouseenter' : 'mouseover click mouseenter';
  // Fix for Firefox when it's not triggering 'mouseleave' event when reorder elements in DOM so tooltip stuck
  const eventOffList = hasClickHandling ? 'scroll click mouseleave' : 'scroll mouseleave';
  const onClick = () => hasClickHandling && onMouseLeave();

  return (
    <div
      className={classNames('custom-tooltip', { 'custom-tooltip--disabled': !showTooltip })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchEnd={detectTouch}
      onClick={onClick}
    >
      <div
        className={classNames('custom-tooltip__icon', className)}
        data-testid="tooltip-invoker"
        data-tip
        data-for={tooltipId}
      >
        {title ? title : getIcon()}
      </div>
      {showTooltip && (
        <ReactTooltip
          id={tooltipId}
          effect="solid"
          event={eventList}
          eventOff={eventOffList}
          type="light"
          overridePosition={overridePosition}
          className="custom-tooltip__content"
          disable={hasClickHandling && touchDetected}
        >
          {children}
        </ReactTooltip>
      )}
    </div>
  );
};
