import { stringify } from 'qs';
import { takeLatest, put } from 'redux-saga/effects';
import { push, replace } from 'redux-first-history';
import { history } from 'store';
import { Routes } from 'constants/routes.enum';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { IAction } from 'types/actions';
import { doRequest } from 'store/api/actions';
import {
  submitLocation,
  submitLocationActionConstants,
  submitLocationInvoiceOnly,
  submitLocationInvoiceOnlyActionConstants,
} from 'store/customer-login/actions';
import { resetLocationRelatedData } from 'store/root-reducer-actions';
import { getCartInfoSagaHandler } from 'store/shopping-cart/sagas/get-cart-info';
import { getUserInfo } from 'store/auth/actions';
import { resetDeletedLocationStatus } from 'utils/analytics';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';
import { ILocationState } from 'types/router';
import { loadGASessionParams } from './get-session-params';

export interface ISubmitLocationPayload {
  locationNumber?: string;
}

export function* submitLocationSagaHandler({ payload }: IAction<ISubmitLocationPayload>) {
  yield put(doRequest(submitLocation, `${Endpoints.CUSTOMER_LOCATIONS}/${payload.locationNumber}`, 'post'));
}

export function* submitLocationInvoiceOnlySagaHandler({ payload }: IAction<ISubmitLocationPayload>) {
  yield put(doRequest(submitLocationInvoiceOnly, `${Endpoints.CUSTOMER_LOCATIONS}/${payload.locationNumber}`, 'post'));
}

export function* submitLocationSuccessSagaHandler() {
  const currentLocation = window.location.pathname;
  const query = localStorage.getItem('query');

  if (currentLocation === Routes.SelectLocationPage && query) {
    history.push(`${Routes.ProductSearchPage}?${stringify({ query: query })}`);
    localStorage.removeItem('query');
  } else {
    yield loadGASessionParams();

    if (currentLocation === Routes.ShopHomePage) {
      yield put(getUserInfo.request());
    }

    const { from } = (history.location.state || { from: { pathname: Routes.ShopHomePage } }) as ILocationState;

    if (
      from.pathname === Routes.HomePage ||
      from.pathname === Routes.EmailVerificationPage ||
      from.pathname === Routes.ResetPasswordVerificationPage
    ) {
      from.pathname = Routes.ShopHomePage;
    }

    yield put(replace(from));
    yield put(resetLocationRelatedData());
    resetDeletedLocationStatus();
  }
}

function* submitLocationInvoiceOnlySuccessSagaHandler() {
  const { from } = (history.location.state as ILocationState) ?? {};

  yield put(
    push({
      pathname: from?.pathname ?? getLocalizedShopUrl(Routes.AccountPayments),
    })
  );
}

export default function* submitLocationSaga() {
  yield takeLatest(submitLocationActionConstants[Actions.REQUEST], submitLocationSagaHandler);
  yield takeLatest(submitLocationInvoiceOnlyActionConstants[Actions.REQUEST], submitLocationInvoiceOnlySagaHandler);
  yield takeLatest(
    submitLocationInvoiceOnlyActionConstants[Actions.SUCCESS],
    submitLocationInvoiceOnlySuccessSagaHandler
  );
  yield takeLatest(submitLocationActionConstants[Actions.SUCCESS], submitLocationSuccessSagaHandler);
  yield takeLatest(submitLocationActionConstants[Actions.SUCCESS], getCartInfoSagaHandler);
}
