import { createSelector } from 'reselect';
import { isEmpty } from 'lodash-es';
import { IState } from 'store/root-reducer';
import { IOrderCutOffState } from './reducer';

export const selectOrderCutOffEntity = (state: IState): IOrderCutOffState => state.app.orderCutOff;

export const selectOrderCutOffInfo = createSelector(selectOrderCutOffEntity, (orderCutOffEntity) => orderCutOffEntity);

export const selectIsOrderCutoffNotificationVisible = createSelector(
  selectOrderCutOffEntity,
  (orderCutOffEntity) => orderCutOffEntity.isVisible
);

export const selectIsOrderCutoffNotificationExpired = createSelector(
  selectOrderCutOffEntity,
  (orderCutOffEntity) => orderCutOffEntity.isExpired
);

export const selectHasNextOrderDueDate = createSelector(selectOrderCutOffEntity, ({ dueDate }) => !isEmpty(dueDate));
export const selectNextOrderDueDate = createSelector(selectOrderCutOffEntity, ({ dueDate }) => dueDate);
