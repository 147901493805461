export enum BankAccountType {
  BUSINESS = 'CORPORATE',
  PERSONAL = 'CONSUMER',
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

export enum PadBankAccountType {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL',
}

export enum BankAccountHolderType {
  PERSONAL = 'Personal',
  BUSINESS = 'Business',
}

export enum PaymentMethods {
  ACH_BANK_ACCOUNT = 'ACH_BANK_ACCOUNT',
  PAD_BANK_ACCOUNT = 'PAD_BANK_ACCOUNT',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum CountryCodes {
  US = '1030',
  CANADA = '2010',
}
