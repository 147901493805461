export enum Routes {
  HomePage = '/',
  ShopHomePage = '/shop',
  CustomerRegistrationPage = '/shop/registration',
  CustomerRegistrationSuccessPage = '/shop/registration-success',
  AdditionalUserRegistrationPage = '/shop/customer-invite',
  LoginPage = '/shop/login',
  SelectLocationPage = '/shop/select-location',
  ResetPasswordPage = '/shop/reset-password',
  ResetPasswordVerificationPage = '/shop/reset-password/verification',
  UpdatePasswordPage = '/shop/update-password',
  EmailVerificationPage = '/shop/email-verification',
  AccountLocationsPage = '/shop/account/locations',
  AccountOrderHistoryPage = '/shop/account/order-history',
  AccountShoppingListsPage = '/shop/account/shopping-lists',
  AccountQuotesPage = '/shop/account/quotes',
  ShoppingCartPage = '/shop/shopping-cart',
  CheckoutPage = '/shop/shopping-cart/checkout',
  OrderConfirmationPage = '/shop/shopping-cart/checkout/order-confirmation',
  OrderDetails = '/shop/order-details',
  ProfilePage = '/shop/account',
  ProductListPage = '/shop/products',
  ProductSearchPage = '/shop/search',
  DashboardPage = '/shop/dashboard',
  DashboardQuotesPage = '/shop/dashboard/employee/quotes',
  FrequentlyAskedQuestionsPage = '/shop/faqs',
  GuestFrequentlyAskedQuestionsPage = '/faqs',
  AccessDeniedPage = '/shop/access-denied',
  DoNotSellMyInfo = '/privacy-policy/do-not-sell-my-info',
  ProductDetailsPage = '/shop/product',
  ShoppingListDetailsPage = '/shop/shopping-list',
  ArticlePage = '/shop/article',
  NotFoundPage = '/404',
  PromotionalPage = '/shop/promotion',
  NewsAndNotificationsPage = '/shop/news-and-notifications',
  HelpAndResourcesPage = '/shop/help-resources',
  CorporateInsightPage = '/insights',
  CorporateLatamInsightPage = '/blog',
  CorporateFrInsightPage = '/insights',
  CorporateNlInsightPage = '/inzichten',
  CorporateBeDutchInsightPage = '/inzichten',
  CorporateDachInsightPage = '/einblicke',
  CorporateSpainInsightPage = '/ideas',
  CorporatePlInsightPage = '/porady',
  CorporatePtInsightPage = '/informações',
  CorporateHuInsightPage = '/betekintések',
  CorporateBeFrenchInsightPage = '/insights',
  CorporateCaFrInsightPage = '/perspectives',
  CorporateNewsPage = '/about-us/news-press/detail',
  CorporateLatamNewsPage = '/noticias',
  CorporateFrNewsPage = '/actualités',
  CorporateNlNewsPage = '/nieuws',
  CorporateBeDutchNewsPage = '/nieuws',
  CorporateDachNewsPage = '/neuigkeiten',
  CorporateSpainNewsPage = '/actualidad',
  CorporatePlNewsPage = '/wiadomości',
  CorporatePtNewsPage = '/notícias',
  CorporateHuNewsPage = '/hírek',
  CorporateBeFrenchNewsPage = '/actualités',
  CorporateCaFrNewsPage = '/actualites-et-medias',
  CorporateRecipesPage = '/recipes',
  CorporateLatamRecipesPage = '/recetas',
  CorporateFrRecipesPage = '/recettes',
  CorporateNlRecipesPage = '/recepten',
  CorporateBeDutchRecipesPage = '/recepten',
  CorporateDachRecipesPage = '/retept',
  CorporateSpainRecipesPage = '/recetas',
  CorporatePlRecipesPage = '/receptury',
  CorporatePtRecipesPage = '/receitas',
  CorporateHuRecipesPage = '/receptek',
  CorporateBeFrenchRecipesPage = '/recettes',
  CorporateCaFrRecipesPage = '/recettes',
  LivePreviewPage = '/live-preview',
  LivePreviewComponent = '/entries',
  SearchResults = '/search',
  ContactUs = '/contact-us',
  AccountPayments = '/shop/account/payments',
  PaymentSummary = '/shop/payments/payment-summary',
  PaymentDetails = '/shop/payments/payment-details',
}

export const PAYMENTS_ROUTE_PARTIAL = '/payments';
