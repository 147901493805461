import React, { FC, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { CorContentstackLink } from 'corporate-components';
import { COR_NAV_ORANGE } from 'corporate-ui/cor-constants';
import { useMainNavState } from 'components/layout/cor-header/context/headerContext';
import { IMainMenuItemBase } from 'components/layout/cor-header/cor-header.interface';
import { IconMenuArrow } from 'components/icons/icon-menu-arrow';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { detectMobileOrTabletTouchDevice } from 'utils/detect-touch-device';
import { isExternalUrl } from 'utils/is-external-url';
import { HEADER_FUNCTIONAL_CLASSES } from 'components/layout/cor-header/cor-header.constants';

import './main-nav-item.scss';

export const MainNavItem: FC<IMainMenuItemBase> = ({
  name,
  label,
  url,
  hasSubmenu,
  children,
  position = null,
  level,
  customClass,
}) => {
  const { isDesktop, isMobile } = useBreakpoint();
  const {
    setOpenedMenu,
    openedMenu,
    shopNavOpen,
    mobileMenuOpen,
    setMobileMenuOpen,
    setShopNavOpen,
  } = useMainNavState();
  const subNavRef = useRef<any>(undefined);
  const menuIsOpen: boolean =
    isDesktop || level === 1
      ? !!openedMenu[name]
      : !!openedMenu[HEADER_FUNCTIONAL_CLASSES.CORPORATE_NAV_CLASS]?.includes(name);

  const toggleMenu = (val: string) => {
    if (isDesktop && !detectMobileOrTabletTouchDevice()) {
      setOpenedMenu((state) => ({ [val]: [] }));
    }
    return;
  };

  const toggleMobileMenu = (val: string) => {
    if (isMobile || detectMobileOrTabletTouchDevice()) {
      setOpenedMenu((state: any) => {
        if (level === 1) {
          const hasVal = !!state[val];
          return hasVal
            ? Object.entries(state).reduce((memo, [key, value]) => (key !== val ? { ...memo, [key]: value } : memo), {})
            : { ...state, [val]: [] };
        }
        const corp = state[HEADER_FUNCTIONAL_CLASSES.CORPORATE_NAV_CLASS];

        return corp.includes(val)
          ? { ...state, [HEADER_FUNCTIONAL_CLASSES.CORPORATE_NAV_CLASS]: corp.filter((item) => item !== val) }
          : {
              ...state,
              [HEADER_FUNCTIONAL_CLASSES.CORPORATE_NAV_CLASS]: [...corp, val],
            };
      });
    }
    return;
  };

  const closeHamburgerMenuHandler = () => {
    if (isMobile && window.location.pathname === url) {
      mobileMenuOpen && setMobileMenuOpen(false);
      shopNavOpen && setShopNavOpen(false);
    }
  };
  return (
    <div
      className={`main-nav-item${menuIsOpen ? ' is-open' : ''} ${position ? 'main-nav-item--' + position : ''}`}
      onMouseEnter={() => toggleMenu(name)}
      onMouseLeave={() => toggleMenu('')}
    >
      <div
        className={classNames('main-nav-item__menu-link', customClass, {
          ' is-open': menuIsOpen && hasSubmenu,
          ' has-submenu': hasSubmenu,
        })}
      >
        {!hasSubmenu && (
          <CorContentstackLink
            data-testid={'main-subnav-content-link'}
            contentKey={''}
            data={{ href: url, title: label }}
            aria-label={label}
            opensInNewTab={url ? isExternalUrl(url) : false}
            onClick={closeHamburgerMenuHandler}
          >
            {label}
            <IconMenuArrow dir={'right'} color={COR_NAV_ORANGE} height={15} />
          </CorContentstackLink>
        )}
        {hasSubmenu && (
          <button onClick={() => toggleMobileMenu(name)} name={label}>
            {label}
            <IconMenuArrow dir={menuIsOpen ? 'down' : 'up'} color={COR_NAV_ORANGE} height={15} />
          </button>
        )}
      </div>

      {hasSubmenu && (
        <CSSTransition key={name} ref={subNavRef} in={menuIsOpen} timeout={0} classNames="main-subnav">
          <section ref={subNavRef} className={`main-subnav-container${menuIsOpen ? ' is-open' : ''}`}>
            {children}
          </section>
        </CSSTransition>
      )}
    </div>
  );
};
