import { FC, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { isEmpty, omit, omitBy } from 'lodash-es';
import { useSelector } from 'react-redux';
import { MultiValue, SingleValue, components } from 'react-select';
import classNames from 'classnames';
import { selectPaymentFieldTokenData } from 'store/payment-field-token/selectors';
import { useAddOrRemovePaymentMethodSuccesOrErrorModal } from 'hooks/use-add-or-remove-payment-method-success-or-error-modal';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ErrorNotification } from 'components/forms/error-notification';
import { ContentstackText } from 'components/contentstack';
import {
  bankRoutingAndAccountNumberValidator,
  companyNameValidator,
  createValidationSchema,
  filterFormikErrors,
  nameValidator,
  validateFormik,
} from 'utils/validation';
import { useContent } from 'hooks/use-content';
import { useAddPaymentMethod } from 'hooks/use-add-payment-method';
import { TextField } from 'components/forms/text-field';
import { CustomDropdown, IOptionType } from 'components/custom-dropdown';
import { CorButton } from 'components/cor-button';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { BankAccountType } from 'constants/payment-methods.enum';
import { addNewBankAccount, clearAddBankAccountFormErrors } from 'store/payment-methods/actions';
import { getPaymentFieldToken } from 'store/payment-field-token/actions';
import { CollapsibleConsentSection } from 'components/collapsible-consent-section';
import {
  selectAddBankAccountFormServerError,
  selectBankAccountFormIsSubmitting,
} from 'store/payment-methods/selectors';
import { IGetPaymentFieldTokenPayload } from 'store/payment-field-token/sagas/get-payment-field-token';
import { ModalHeader, ModalFooter } from 'components/modals/common/modal/components';
import { LoadingIndicator, LoadingIndicatorType } from 'components/loading-indicator';
import { useScrollToRef } from 'hooks/use-scroll-to-ref';

import './add-new-bank-account-modal.scss';

export interface IBankAccountFormValues {
  firstName: string;
  lastName: string;
  ecpAccountNumber: string;
  ecpRoutingNumber: string;
  ecpAccountType: string;
  accountHolderType: string;
  companyName?: string;
}

export interface IAddNewBankAccountModalProps extends IModalProps {
  isOpen: boolean;
}

export interface IBankAccountDropdownOptions {
  value: string;
  label: string;
}

export const AddNewBankAccountModal: FC<IAddNewBankAccountModalProps> = ({ isOpen, onClose = () => {} }) => {
  const firstLastNameInputMaxLength = 25;
  const accountNumberMinLength = 5;
  const accountNumberMaxLength = 17;
  const routingNumberMaxLength = 9;
  const companyNameMaxLength = 50;

  const isAddNewBankAccountSubmitting = useSelector(selectBankAccountFormIsSubmitting);
  const pfToken = useSelector(selectPaymentFieldTokenData);
  const dataToSubmitRef = useRef<Partial<IBankAccountFormValues>>();
  const [bluesnapAccountHolderType, setBluesnapAccountHolderType] = useState('');
  const [bluesnapAccountType, setBluesnapAccountType] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const { getMessageText, getContentByKey } = useContent();
  const { isMobile } = useBreakpoint();
  const dispatch = useDispatch();
  const contentstackPath = 'modals.0.add_new_bank_account_modal.0';
  const consentTextPath = `${contentstackPath}.consent_section_ach.0.consent_text`;
  const consentCheckboxLabelPath = `${contentstackPath}.consent_section_ach.0.checkbox_label`;
  const { addPaymentMethodSuccessModal } = useAddOrRemovePaymentMethodSuccesOrErrorModal();
  const [submitted, setSubmitted] = useState(false);
  const [hasValidationError, setHasValidationError] = useState(false);
  const [hasBSError, setHasBSError] = useState(false);
  const [isConsentChecked, setConsentStatus] = useState(false);
  const serverErrorId = useSelector(selectAddBankAccountFormServerError);
  const { showAddPaymentMethodFailModal } = useAddPaymentMethod();
  const clientErrorMessage = getMessageText('error', 'MSG190');
  const errorNotificationRef = useRef<HTMLDivElement | null>(null);
  const accountTypeDropdownOptions: IBankAccountDropdownOptions[] = [
    { value: BankAccountType.CHECKING, label: getContentByKey(`${contentstackPath}.checking_account_label`, '') },
    { value: BankAccountType.SAVINGS, label: getContentByKey(`${contentstackPath}.savings_account_label`, '') },
  ];
  const isError = serverErrorId || hasBSError;

  const accountHolderTypeDropdownOptions: IBankAccountDropdownOptions[] = [
    {
      value: BankAccountType.PERSONAL,
      label: getContentByKey(`${contentstackPath}.personal_account_holder_option`, ''),
    },
    {
      value: BankAccountType.BUSINESS,
      label: getContentByKey(`${contentstackPath}.business_account_holder_option`, ''),
    },
  ];

  const validationSchema = createValidationSchema({
    firstName: nameValidator({
      required: getMessageText('error', 'MSG004'),
      wrongFormat: getMessageText('error', 'MSG005'),
    }),
    lastName: nameValidator({
      required: getMessageText('error', 'MSG004'),
      wrongFormat: getMessageText('error', 'MSG005'),
    }),
    companyName: companyNameValidator(
      {
        required: getMessageText('error', 'MSG004'),
      },
      'accountHolderType'
    ),
    ecpRoutingNumber: bankRoutingAndAccountNumberValidator({
      required: getMessageText('error', 'MSG184'),
      wrongFormat: getMessageText('error', 'MSG185'),
      min: routingNumberMaxLength,
      max: routingNumberMaxLength,
    }),
    ecpAccountNumber: bankRoutingAndAccountNumberValidator({
      required: getMessageText('error', 'MSG184'),
      wrongFormat: getMessageText('error', 'MSG185'),
      min: accountNumberMinLength,
      max: accountNumberMaxLength,
    }),
  });

  const formInitialValues: IBankAccountFormValues = {
    firstName: '',
    lastName: '',
    ecpAccountNumber: '',
    ecpRoutingNumber: '',
    ecpAccountType: '',
    accountHolderType: '',
    companyName: '',
  };

  const clearErrors = useCallback(() => {
    setHasBSError(false);

    if (serverErrorId) {
      dispatch(clearAddBankAccountFormErrors());
    }
  }, [dispatch, serverErrorId]);

  const onAddNewBankAccountFail = useCallback((messageId: string) => {
    if (messageId !== 'MSG190' && messageId !== 'MSG202') {
      showAddPaymentMethodFailModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConsentStatusChange = () => {
    setConsentStatus((status) => !status);
  };

  const onBlueSnapErrorCallBack = () => {
    setHasBSError(true);
  };

  const HandleNewBankAccountRequest = () => {
    const formFields = omitBy(
      {
        firstName: dataToSubmitRef.current?.firstName,
        lastName: dataToSubmitRef.current?.lastName,
        companyName: dataToSubmitRef.current?.companyName,
      },
      isEmpty
    );

    dispatch(
      addNewBankAccount.request({
        ...formFields,
        pfToken,
        onSuccessCallBack: addPaymentMethodSuccessModal,
        onFailCallBack: onAddNewBankAccountFail,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getPaymentFieldToken.request<IGetPaymentFieldTokenPayload>({
        onFailCallBack: onBlueSnapErrorCallBack,
      })
    );

    return () => {
      dispatch(clearAddBankAccountFormErrors());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (pfToken) {
      bluesnap.securedPaymentCollectorSetup(pfToken, function (sdkResponse) {
        if (sdkResponse.code === '1') {
          HandleNewBankAccountRequest();
        } else {
          setHasBSError(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pfToken]);

  useScrollToRef({ ref: errorNotificationRef, isError });

  const saveNewBankAccountInfo = () => {
    if (submitted) return;

    setSubmitted(true);

    dataToSubmitRef.current = {
      firstName,
      lastName,
      companyName,
    };

    const data = {
      amount: 0,
      currency: 'USD',
    };

    bluesnap.securedPaymentCollectorSubmitData(data);
  };

  const containsEmptyRequiredFields = (values: IBankAccountFormValues, isCompanyNameRequired) => {
    return Object.values(isCompanyNameRequired ? values : omit(values, 'companyName')).some((value) => !value);
  };

  const validateForm = (values) => {
    validateFormik(values, validationSchema, setHasValidationError);
    setSubmitted(false);
  };

  const renderOption = (className, children, props) => {
    return (
      <components.Option className={className} {...props}>
        <div className={`${className}-dropdown__item`}>{children}</div>
      </components.Option>
    );
  };

  const accountTypeRenderOption = ({ children, ...props }: any) => {
    return renderOption('accountType', children, props);
  };

  const accountHolderTypeRenderOption = ({ children, ...props }: any) => {
    return renderOption('accountHolderType', children, props);
  };

  const modalClassName = classNames('add-new-bank-account-modal', {
    'add-new-bank-account-modal--with-loading-indicator': isAddNewBankAccountSubmitting,
  });

  return (
    <>
      <Modal
        className={modalClassName}
        isOpen={isOpen}
        onClose={onClose}
        hideCloseButton={isAddNewBankAccountSubmitting}
        withBackdropClick={!isAddNewBankAccountSubmitting}
      >
        {isAddNewBankAccountSubmitting && <LoadingIndicator type={LoadingIndicatorType.FULLSIZED} />}
        <>
          <ModalHeader
            className="add-new-bank-account-modal__header"
            titleContentstackPath={`${contentstackPath}.header`}
          />
          <div className="add-new-bank-account-modal__wrapper">
            <div className="add-new-bank-account-modal__description">
              <ContentstackText contentKey={`${contentstackPath}.description`} />
            </div>
            <div className="add-new-bank-account-modal__required-fields-hint">
              <span className="add-new-bank-account-modal__required-field">&#42;</span>
              <ContentstackText contentKey={`${contentstackPath}.required_fields_hint`} />
            </div>
            {isError && (
              <div className="grid-x" ref={errorNotificationRef}>
                <div className="cell">
                  <ErrorNotification
                    className="error-notification"
                    messageId={serverErrorId}
                    message={clientErrorMessage}
                  />
                </div>
              </div>
            )}
            <Formik
              validateOnChange={false}
              onSubmit={saveNewBankAccountInfo}
              initialValues={formInitialValues}
              validationSchema={validationSchema}
              validate={validateForm}
            >
              {({ dirty, isValid, handleSubmit, values, setFieldValue, setErrors, errors }) => {
                const isCompanyNameRequired = values.accountHolderType === BankAccountType.BUSINESS;
                const isCompanyNameRequiredAndEmpty = isCompanyNameRequired && Boolean(!values.companyName?.length);

                return (
                  <Form
                    className="add-new-bank-account-modal__form"
                    onChange={(event: SyntheticEvent) => {
                      clearErrors();
                      const fieldName = (event.target as HTMLInputElement).name;
                      setErrors(filterFormikErrors(errors, fieldName));
                    }}
                    onBlur={(event: SyntheticEvent) => {
                      validateForm(values);
                      setErrors(filterFormikErrors(errors, (event.target as HTMLInputElement).name));
                    }}
                    {...{ handleSubmit, isValid, dirty }}
                  >
                    <div className="add-new-bank-account-modal__contact-name">
                      <TextField
                        className="add-new-bank-account-modal__field"
                        id="firstName"
                        maxLength={firstLastNameInputMaxLength}
                        label={getContentByKey<string>(`${contentstackPath}.first_name`, '')}
                        name="firstName"
                        placeholder={getContentByKey<string>(`${contentstackPath}.first_name_placeholder`, '')}
                        onChange={(event) => {
                          setFirstName(event.target.value);
                          setFieldValue('firstName', event.target.value);
                        }}
                        requiredField
                      />
                      <TextField
                        className="add-new-bank-account-modal__field"
                        id="lastName"
                        maxLength={firstLastNameInputMaxLength}
                        label={getContentByKey<string>(`${contentstackPath}.last_name`, '')}
                        name="lastName"
                        placeholder={getContentByKey<string>(`${contentstackPath}.last_name_placeholder`, '')}
                        onChange={(event) => {
                          setLastName(event.target.value);
                          setFieldValue('lastName', event.target.value);
                        }}
                        requiredField
                      />
                    </div>
                    <div className="add-new-bank-account-modal__bank-info">
                      <TextField
                        className="add-new-bank-account-modal__field"
                        id="ecpRoutingNumber"
                        maxLength={routingNumberMaxLength}
                        label={getContentByKey<string>(`${contentstackPath}.routing_number`, '')}
                        name="ecpRoutingNumber"
                        placeholder={getContentByKey<string>(`${contentstackPath}.routing_number_placeholder`, '')}
                        fromPayments
                        requiredField
                      />
                      <TextField
                        className="add-new-bank-account-modal__field"
                        id="ecpAccountNumber"
                        maxLength={accountNumberMaxLength}
                        label={getContentByKey<string>(`${contentstackPath}.account_number`, '')}
                        name="ecpAccountNumber"
                        placeholder={getContentByKey<string>(`${contentstackPath}.account_number_placeholder`, '')}
                        fromPayments
                        requiredField
                      />
                    </div>
                    <div className="add-new-bank-account-modal__account-dropdown-options">
                      <div className="add-new-bank-account-modal__account-holder-type-block">
                        <p className="add-new-bank-account-modal__account-type-label">
                          <ContentstackText contentKey={`${contentstackPath}.account_holder_type`} />
                          <span className="add-new-bank-account-modal__required-field">&#42;</span>
                        </p>
                        <CustomDropdown
                          inputId="add-new-bank-account-modal__account-holder-dropdown--id"
                          name="accountHolderType"
                          items={accountHolderTypeDropdownOptions}
                          renderOption={accountHolderTypeRenderOption}
                          onChange={(accountHolderTypeOption: MultiValue<IOptionType> | SingleValue<IOptionType>) => {
                            const accountHolderOptionType = accountHolderTypeOption as IOptionType;

                            setBluesnapAccountHolderType(accountHolderOptionType?.value);
                            setFieldValue('accountHolderType', accountHolderOptionType?.value, true);
                          }}
                          placeholder={getContentByKey<string>(`${contentstackPath}.dropdown_placeholder`, '')}
                          className={'add-new-bank-account-modal__account-dropdown'}
                        />
                      </div>
                      <div className="add-new-bank-account-modal__account-type-block">
                        <p className="add-new-bank-account-modal__account-type-label">
                          <ContentstackText contentKey={`${contentstackPath}.account_type`} />
                          <span className="add-new-bank-account-modal__required-field">&#42;</span>
                        </p>
                        <CustomDropdown
                          inputId="add-new-bank-account-modal__account-type-dropdown--id"
                          name="ecpAccountType"
                          items={accountTypeDropdownOptions}
                          renderOption={accountTypeRenderOption}
                          onChange={(accountTypeOption: MultiValue<IOptionType> | SingleValue<IOptionType>) => {
                            const optionAccountType = accountTypeOption as IOptionType;

                            setBluesnapAccountType(optionAccountType?.value);
                            setFieldValue('ecpAccountType', optionAccountType?.value);
                          }}
                          placeholder={getContentByKey<string>(`${contentstackPath}.dropdown_placeholder`, '')}
                          className={'add-new-bank-account-modal__account-dropdown'}
                        />

                        {/* This hidden input is because Bluesnap only accepts the ecpAccountType as an input */}
                        <input
                          type="hidden"
                          value={`${bluesnapAccountHolderType}_${bluesnapAccountType}`}
                          data-bluesnap="ecpAccountType"
                          id="ecpAccountType"
                          name="ecpAccountType"
                        />
                      </div>
                    </div>
                    <TextField
                      className="add-new-bank-account-modal__field"
                      id="companyName"
                      label={getContentByKey<string>(`${contentstackPath}.company_name_field_label`, '')}
                      name="companyName"
                      maxLength={companyNameMaxLength}
                      requiredField={isCompanyNameRequired}
                      onChange={(event) => {
                        setCompanyName(event.target.value);
                        setFieldValue('companyName', event.target.value);
                      }}
                      onBlur={(event) => {
                        const value = event.target.value.trim();

                        setCompanyName(value);
                        setFieldValue('companyName', value);
                      }}
                      placeholder={getContentByKey<string>(`${contentstackPath}.company_name_field_placeholder`, '')}
                    />
                    <div className="add-new-bank-account-modal__field">
                      <CollapsibleConsentSection
                        isChecked={isConsentChecked}
                        onCheckedStatusChange={handleConsentStatusChange}
                        consentTextPath={consentTextPath}
                        checkboxLabelPath={consentCheckboxLabelPath}
                      />
                    </div>
                    <ModalFooter
                      className="add-new-bank-account-modal__footer"
                      contentstackPath={contentstackPath}
                      cancelButtonHandler={onClose}
                      hasCancelButton
                    >
                      <CorButton
                        className="add-new-bank-account-modal__save_button"
                        disabled={
                          containsEmptyRequiredFields(values, isCompanyNameRequired) ||
                          isCompanyNameRequiredAndEmpty ||
                          submitted ||
                          !isConsentChecked ||
                          hasValidationError
                        }
                        type="submit"
                      >
                        <ContentstackText
                          contentKey={`${contentstackPath}.${
                            isMobile ? 'mobile_save_button_label' : 'desktop_save_button_label'
                          }`}
                        />
                      </CorButton>
                    </ModalFooter>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </>
      </Modal>
    </>
  );
};
