import { combineReducers } from 'redux';
import { isEmpty, isEqual } from 'lodash-es';
import { Actions } from 'constants/actions.enum';
import { ISortParams } from 'types/product-sort-options';
import { ICatalogProduct } from 'types/catalog';
import { IFacet, ISelectedFacet } from 'types/facets';
import { ILineItem } from 'types/line-item';
import { Limit } from 'constants/limit.enum';
import { createReducer } from 'store/reducer-creator';
import {
  getSelectedCategoriesList,
  mergeSelectedFacets,
  mergeFacetsFromResponse,
  updateSelectedFacetsOnDawnBrandsReset,
} from 'utils/facet-utils';
import * as shoppingCartActions from 'store/shopping-cart/actions';
import * as actions from './actions';
import types from './action-types';

const initialSelectedFacets = [];

export interface ISearchCategory {
  categoryKey: string;
  categoryName: string;
  level: string;
  children?: ISearchCategory[];
  isSelected: boolean;
  count?: number;
}

export interface ISearchMeta {
  offset: number;
  limit: Limit;
  total: number;
  sortOptions: ISortParams;
  loading: boolean;
  totalSkus?: number;
}

interface ICategories {
  categoriesList: ISearchCategory[];
  selectedCategories: string[];
}

interface ICorporateSearchLink {
  url: string;
  title: string;
  total: number;
}

interface ICorporateSearchLinks {
  searchAllUrl: string;
  searchAllLabel: string;
  links: ICorporateSearchLink[];
}

export interface ISearchProductsState {
  items: ICatalogProduct[];
  exactMatchSku: string | null;
  searchMeta: ISearchMeta;
  dawnBrands: string[];
  facets: IFacet[];
  selectedFacets: ISelectedFacet[];
  categories: ICategories;
  temporaryFacets: IFacet[];
  temporarySelectedFacets: ISelectedFacet[];
  temporaryCategories: ICategories;
  searchSuggestions: string[];
  corporateSearchLinks: ICorporateSearchLinks | null;
}

export const searchMetaInitial = {
  offset: 0,
  limit: Limit.TwentyFour,
  total: 0,
  sortOptions: {},
  loading: false,
  totalSkus: 0,
};

export const searchProductsInitialState: ISearchProductsState = {
  items: [],
  exactMatchSku: null,
  searchMeta: searchMetaInitial,
  dawnBrands: [],
  facets: [],
  selectedFacets: initialSelectedFacets,
  categories: {
    categoriesList: [],
    selectedCategories: [],
  },
  temporaryFacets: [],
  temporarySelectedFacets: initialSelectedFacets,
  temporaryCategories: {
    categoriesList: [],
    selectedCategories: [],
  },
  searchSuggestions: [],
  corporateSearchLinks: null,
};

const getSelectedCategoriesResponse = (categories: ISearchCategory[], state: string[]) => {
  const selectedCategoriesResponse: string[] = getSelectedCategoriesList(categories).map((item) => item.categoryKey);

  if (!isEqual(state, selectedCategoriesResponse)) {
    return selectedCategoriesResponse;
  }

  return state;
};

const items = createReducer<ICatalogProduct[]>(
  {
    [actions.searchProductsActionConstants[Actions.SUCCESS]]: (state, { payload: { plpProducts } }) => [
      ...plpProducts.result,
    ],
    [shoppingCartActions.getCartInfoActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) =>
      state.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.ctProductKey === product.productKey),
      })),
    [shoppingCartActions.addProductToCartActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) =>
      state.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.ctProductKey === product.productKey),
      })),
    [types.CLEAR_SEARCH_STATE]: () => [],
  },
  []
);

const exactMatchSku = createReducer<string | null>(
  {
    [actions.searchProductsActionConstants[Actions.SUCCESS]]: (state, { payload: { plpProducts } }) =>
      !isEmpty(plpProducts.exactMatchSku) ? plpProducts.exactMatchSku : null,
    [types.CLEAR_SEARCH_STATE]: () => null,
  },
  null
);

const searchMeta = createReducer<ISearchMeta>(
  {
    [actions.searchProductsActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.searchProductsActionConstants[Actions.SUCCESS]]: (state, { payload: { plpProducts } }) => ({
      ...state,
      total: plpProducts.total,
      offset: plpProducts.offset,
      limit: plpProducts.limit,
      totalSkus: plpProducts.totalSkus,
      loading: false,
    }),
    [actions.searchProductsActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [types.SEARCH_PRODUCTS_PAGINATION_CHANGE]: (state, { payload }) => ({
      ...state,
      offset: state.limit * payload.current,
    }),
    [types.SET_SEARCH_PRODUCTS_SORT_PARAMS]: (state, { payload }) => ({
      ...state,
      sortOptions: payload.sortParams,
      offset: searchMetaInitial.offset,
    }),
    [types.SET_SEARCH_PRODUCTS_LIMIT]: (state, { payload }) => ({
      ...state,
      limit: payload.limit,
      offset: searchMetaInitial.offset,
    }),
    [types.SET_SEARCH_SELECTED_FACETS]: (state) => ({
      ...state,
      offset: searchMetaInitial.offset,
    }),
    [types.RESET_SEARCH_DAWN_BRANDS]: (state) => ({
      ...state,
      offset: searchMetaInitial.offset,
    }),
    [types.SET_SEARCH_SELECTED_CATEGORIES]: (state) => ({
      ...state,
      offset: searchMetaInitial.offset,
    }),
    [types.CLEAR_SEARCH_SELECTED_FILTERS]: (state) => ({
      ...state,
      offset: searchMetaInitial.offset,
    }),
    [types.SET_SEARCH_SELECTED_FILTERS]: (state, { payload: { searchMeta } }) => searchMeta,
    [types.CLEAR_SEARCH_STATE]: () => searchMetaInitial,
  },
  searchMetaInitial
);

const dawnBrands = createReducer<string[]>(
  {
    [actions.searchProductsActionConstants[Actions.SUCCESS]]: (
      state,
      {
        payload: {
          plpProducts: { dawnBrands },
        },
      }
    ) => {
      if (dawnBrands && !isEqual(state, dawnBrands)) {
        return dawnBrands;
      }
      return state;
    },
    [types.CLEAR_SEARCH_STATE]: () => [],
  },
  []
);

const facets = createReducer<IFacet[]>(
  {
    [actions.searchProductsActionConstants.SUCCESS]: (state, { payload: { plpProducts } }) => plpProducts.facets,
    [types.SET_SEARCH_SELECTED_FILTERS]: (state, { payload: { facets } }) => facets,
    [types.CLEAR_SEARCH_STATE]: () => [],
  },
  []
);

const selectedFacets = createReducer<ISelectedFacet[]>(
  {
    [types.SET_SEARCH_SELECTED_FACETS]: (state, { payload }) => mergeSelectedFacets(state, payload.selectedFacets),
    [actions.searchProductsActionConstants[Actions.SUCCESS]]: (state, { payload: { plpProducts } }) =>
      mergeFacetsFromResponse(state, plpProducts.facets),
    [types.RESET_SEARCH_DAWN_BRANDS]: (state, { payload: { dawnBrands } }) => {
      return updateSelectedFacetsOnDawnBrandsReset(state, dawnBrands);
    },
    [types.CLEAR_SEARCH_SELECTED_FILTERS]: (state, { payload }) =>
      payload.resetToInitial ? initialSelectedFacets : [],
    [types.SET_SEARCH_SELECTED_FILTERS]: (state, { payload: { selectedFacets } }) => selectedFacets,
    [types.CLEAR_SEARCH_STATE]: () => [],
  },
  initialSelectedFacets
);

const categoriesList = createReducer<ISearchCategory[]>(
  {
    [actions.searchProductsActionConstants[Actions.SUCCESS]]: (state, { payload: { plpProducts } }) =>
      !isEmpty(plpProducts.categories) ? [...plpProducts.categories] : [],
    [types.SET_SEARCH_SELECTED_FILTERS]: (state, { payload: { categories } }) => categories.categoriesList,
    [types.CLEAR_SEARCH_STATE]: () => [],
  },
  []
);

const selectedCategories = createReducer<string[]>(
  {
    [actions.searchProductsActionConstants[Actions.SUCCESS]]: (
      state,
      {
        payload: {
          plpProducts: { categories },
        },
      }
    ) => getSelectedCategoriesResponse(categories, state),
    [types.CLEAR_SEARCH_SELECTED_FILTERS]: () => [],
    [types.CLEAR_SEARCH_STATE]: () => [],
    [types.SET_SEARCH_SELECTED_FILTERS]: (state, { payload: { categories } }) => categories.selectedCategories,
  },
  []
);

const categories = (state: ICategories | undefined, action: any) => {
  if (state && action.type === types.SET_SEARCH_SELECTED_CATEGORIES) {
    return {
      categoriesList: state.categoriesList,
      selectedCategories: state.selectedCategories?.includes(action.payload.selectedCategoryKey[0])
        ? []
        : action.payload.selectedCategoryKey,
    };
  }

  return {
    categoriesList: categoriesList(state?.categoriesList, action),
    selectedCategories: selectedCategories(state?.selectedCategories, action),
  };
};

const temporaryFacets = createReducer<IFacet[]>(
  {
    [types.SET_SEARCH_TEMPORARY_SELECTED_FILTERS]: (state, { payload: { facets } }) => facets,
    [types.RESET_SEARCH_TEMPORARY_SELECTED_FILTERS]: () => [],
    [types.CLEAR_SEARCH_STATE]: () => [],
    [actions.getSearchTemporaryFiltersInfoActionConstants[Actions.SUCCESS]]: (state, { payload: { plpProducts } }) =>
      plpProducts.facets,
  },
  []
);
const temporarySelectedFacets = createReducer<ISelectedFacet[]>(
  {
    [types.SET_SEARCH_TEMPORARY_SELECTED_FILTERS]: (state, { payload: { selectedFacets } }) => selectedFacets,
    [types.RESET_SEARCH_TEMPORARY_SELECTED_FILTERS]: () => [],
    [types.CLEAR_SEARCH_TEMPORARY_SELECTED_FILTERS]: () => [],
    [types.CLEAR_SEARCH_STATE]: () => [],
    [types.SET_SEARCH_TEMPORARY_SELECTED_FACETS]: (state, { payload }) =>
      mergeSelectedFacets(state, payload.selectedFacets),
    [actions.getSearchTemporaryFiltersInfoActionConstants[Actions.SUCCESS]]: (state, { payload: { plpProducts } }) =>
      mergeFacetsFromResponse(state, plpProducts.facets),
  },
  []
);

const temporaryCategoriesList = createReducer<ISearchCategory[]>(
  {
    [types.SET_SEARCH_TEMPORARY_SELECTED_FILTERS]: (state, { payload: { categories } }) => categories?.categoriesList,
    [actions.getSearchTemporaryFiltersInfoActionConstants[Actions.SUCCESS]]: (state, { payload: { plpProducts } }) =>
      !isEmpty(plpProducts.categories) ? [...plpProducts.categories] : [],
    [types.CLEAR_SEARCH_STATE]: () => [],
  },
  []
);

const temporarySelectedCategories = createReducer<string[]>(
  {
    [types.SET_SEARCH_TEMPORARY_SELECTED_FILTERS]: (state, { payload: { categories } }) =>
      categories?.selectedCategories,
    [actions.getSearchTemporaryFiltersInfoActionConstants[Actions.SUCCESS]]: (
      state,
      {
        payload: {
          plpProducts: { categories },
        },
      }
    ) => getSelectedCategoriesResponse(categories, state),
    [types.CLEAR_SEARCH_TEMPORARY_SELECTED_FILTERS]: () => [],
    [types.CLEAR_SEARCH_STATE]: () => [],
  },
  []
);

const temporaryCategories = (state: ICategories | undefined, action: any) => {
  if (state && action.type === types.SET_SEARCH_TEMPORARY_SELECTED_CATEGORIES) {
    return {
      categoriesList: state.categoriesList,
      selectedCategories: state.selectedCategories?.includes(action.payload.selectedCategoryKey[0])
        ? []
        : action.payload.selectedCategoryKey,
    };
  }

  return {
    categoriesList: temporaryCategoriesList(state?.categoriesList, action),
    selectedCategories: temporarySelectedCategories(state?.selectedCategories, action),
  };
};

const searchSuggestions = createReducer<string[]>(
  {
    [actions.getSearchSuggestionsActionConstants[Actions.SUCCESS]]: (state, { payload }) => payload.suggestions,
    [types.CLEAR_SEARCH_SUGGESTIONS]: () => [],
    [types.CLEAR_SEARCH_STATE]: () => [],
  },
  []
);

const corporateSearchLinks = createReducer<ICorporateSearchLinks | null>(
  {
    [actions.searchProductsActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { searchAllUrl, searchAllLabel, globalPageTypeContents } }
    ) => ({
      searchAllUrl: searchAllUrl,
      searchAllLabel: searchAllLabel,
      links: [
        ...globalPageTypeContents.map(({ url, total, title }) => ({
          url,
          title,
          total,
        })),
      ],
    }),
  },
  null
);

export default combineReducers({
  items,
  exactMatchSku,
  searchMeta,
  dawnBrands,
  facets,
  selectedFacets,
  categories,
  temporaryFacets,
  temporarySelectedFacets,
  temporaryCategories,
  searchSuggestions,
  corporateSearchLinks,
});
