import { createReducer } from 'store/reducer-creator';
import { Actions } from 'constants/actions.enum';
import types from './action-types';
import * as actions from './actions';

export interface ICreditCards {
  cardLastFourDigits: string;
  cardType: string;
  contactFirstName: string;
  contactLastName: string;
  expirationMonth: string;
  expirationYear: string;
}

export interface IBankAccounts {
  publicAccountNumber: string;
  accountType: string;
  contactFirstName: string;
  contactLastName: string;
  publicRoutingNumber: string;
}

export interface IAddFormWithServerErrors {
  isSubmitting: boolean;
  serverError: string;
  isDirty: boolean;
}

const addCreditCardFormWithServerErrorsInitialState: IAddFormWithServerErrors = {
  isSubmitting: false,
  serverError: '',
  isDirty: false,
};

const addBankAccountFormWithServerErrorsInitialState: IAddFormWithServerErrors = {
  isSubmitting: false,
  serverError: '',
  isDirty: false,
};

export interface IPaymentMethodsState {
  addCreditCardForm: IAddFormWithServerErrors;
  addBankAccountForm: IAddFormWithServerErrors;
  addBankAccountFormEftPad: IAddFormWithServerErrors;
  bankAccounts: IBankAccounts[];
  creditCards: ICreditCards[];
  loading: boolean;
}

export const paymentMethodsInitialState: IPaymentMethodsState = {
  addCreditCardForm: addCreditCardFormWithServerErrorsInitialState,
  addBankAccountForm: addBankAccountFormWithServerErrorsInitialState,
  addBankAccountFormEftPad: addBankAccountFormWithServerErrorsInitialState,
  bankAccounts: [],
  creditCards: [],
  loading: false,
};

const paymentMethodsReducer = createReducer<IPaymentMethodsState>(
  {
    [actions.getPaymentMethodsActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
    }),
    [actions.getPaymentMethodsActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { bankAccounts, creditCards } }
    ) => ({
      ...state,
      bankAccounts: bankAccounts,
      creditCards: creditCards,
    }),
    [actions.getPaymentMethodsActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
    }),
    [actions.addNewBankAccountActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      addBankAccountForm: {
        ...state.addBankAccountForm,
        isSubmitting: true,
        serverError: '',
      },
    }),
    [actions.addNewBankAccountActionConstants[Actions.SUCCESS]]: (
      state: IPaymentMethodsState,
      { payload: { bankAccounts } }
    ) => ({
      ...state,
      bankAccounts: bankAccounts,
    }),
    [actions.addNewBankAccountActionConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      addBankAccountForm: {
        ...state.addBankAccountForm,
        isSubmitting: false,
        serverError: error.errorMessageId || '',
      },
    }),
    [actions.addNewBankAccountEftPadActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      addBankAccountFormEftPad: {
        ...state.addBankAccountFormEftPad,
        isSubmitting: true,
        serverError: '',
      },
    }),
    [actions.addNewBankAccountEftPadActionConstants[Actions.SUCCESS]]: (
      state: IPaymentMethodsState,
      { payload: { bankAccounts } }
    ) => ({
      ...state,
      bankAccounts: bankAccounts,
    }),
    [actions.addNewBankAccountEftPadActionConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      addBankAccountFormEftPad: {
        ...state.addBankAccountFormEftPad,
        isSubmitting: false,
        serverError: error.errorMessageId || '',
      },
    }),
    [types.CLEAR_ADD_BANK_ACCOUNT_FROM_ERRORS]: (state) => ({
      ...state,
      addBankAccountForm: {
        ...state.addBankAccountForm,
        isSubmitting: false,
        serverError: '',
      },
    }),
    [types.CLEAR_ADD_BANK_ACCOUNT_EFT_PAD_FORM_ERRORS]: (state) => ({
      ...state,
      addBankAccountFormEftPad: {
        ...state.addBankAccountFormEftPad,
        isSubmitting: false,
        serverError: '',
      },
    }),
    [actions.removeBankAccountActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.removeBankAccountActionConstants[Actions.SUCCESS]]: (
      state: IPaymentMethodsState,
      { payload: { bankAccounts } }
    ) => ({
      ...state,
      bankAccounts: bankAccounts,
      loading: false,
    }),
    [actions.removeBankAccountActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.removeBankAccountEftPadActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.removeBankAccountEftPadActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.removeBankAccountEftPadActionConstants[Actions.SUCCESS]]: (
      state: IPaymentMethodsState,
      { payload: { bankAccounts } }
    ) => ({
      ...state,
      bankAccounts: bankAccounts,
      loading: false,
    }),
    [actions.addCreditCardActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      addCreditCardForm: {
        ...state.addCreditCardForm,
        isSubmitting: true,
        serverError: '',
      },
    }),
    [actions.addCreditCardActionConstants[Actions.SUCCESS]]: (
      state: IPaymentMethodsState,
      { payload: { bankAccounts, creditCards } }
    ) => ({
      ...state,
      bankAccounts: bankAccounts,
      creditCards: creditCards,
      addCreditCardForm: {
        ...state.addCreditCardForm,
        isSubmitting: false,
        serverError: '',
      },
    }),
    [actions.addCreditCardActionConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      addCreditCardForm: {
        ...state.addCreditCardForm,
        isSubmitting: false,
        serverError: error.errorMessageId || '',
      },
    }),
    [types.CLEAR_ADD_CREDIT_CARD_FROM_ERRORS]: (state) => ({
      ...state,
      addCreditCardForm: {
        ...state.addCreditCardForm,
        serverError: '',
      },
    }),
    [actions.deleteCreditCardActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.deleteCreditCardActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { bankAccounts, creditCards } }
    ) => ({
      ...state,
      bankAccounts: bankAccounts,
      creditCards: creditCards,
      loading: false,
    }),
    [actions.deleteCreditCardActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
  },
  paymentMethodsInitialState
);

export default paymentMethodsReducer;
