import { FC } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { history } from 'store';
import { selectIsAuthorised } from 'store/auth/selectors';
import { Routes } from 'constants/routes.enum';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';

export const PrivateRoute: FC<RouteProps> = ({ render, ...rest }) => {
  const isAuthorised = useSelector(selectIsAuthorised);
  const renderFunction = isAuthorised
    ? render
    : () => (
        <Redirect
          to={{
            pathname: getLocalizedShopUrl(Routes.LoginPage),
            state: { from: history.location },
          }}
        />
      );

  return <Route {...rest} render={renderFunction} />;
};
