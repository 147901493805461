import './cor-submit-button.scss';

import { FC } from 'react';
import { useFormikContext } from 'formik';
import { CorButton } from 'components/cor-button';
import { useContent } from 'hooks/use-content';

interface ICorSubmitButtonProps {
  isDisabled?: boolean;
  onClick?: () => void;
  className?: string;
  submitButtonKey?: string;
}

export const CorSubmitButton: FC<ICorSubmitButtonProps> = ({
  isDisabled,
  onClick,
  className = 'right',
  submitButtonKey = 'form.form.0.button.button',
  ...props
}) => {
  const { getContentByKey } = useContent();
  const submitButton: any = getContentByKey(submitButtonKey, {});
  const position = submitButton?.button_alignment || '';
  const classNames = ['cor-submit-button', className, `cor-submit-button--${position}`]
    .filter((currentClassName) => !!currentClassName)
    .join(' ');

  const { isValid, dirty, isSubmitting } = useFormikContext();
  const isButtonDisabled = isDisabled !== undefined ? isDisabled : !(isValid && dirty) || isSubmitting;

  return !!submitButton?.button?.title ? (
    <CorButton
      color={submitButton?.button_style}
      type="submit"
      disabled={isButtonDisabled}
      className={classNames}
      {...props}
    >
      {submitButton?.button?.title}
    </CorButton>
  ) : null;
};
