import { IBankAccounts } from 'store/payment-methods/reducers';
import {
  usePaymentMethodsErrorModal,
  useRemovePaymentBankAccountModal,
  useRemovePaymentCardModal,
  useSuccessErrorMessageModal,
} from './use-global-modal';

interface IUseRemovePayamentMethodProps {
  cardLastFourDigits?: string;
  cardType?: string;
  expirationMonth?: string;
  expirationYear?: string;
  bankAccount?: IBankAccounts;
}

export const useRemovePaymentMethod = (props: IUseRemovePayamentMethodProps) => {
  const { cardLastFourDigits, cardType, expirationMonth, expirationYear, bankAccount } = props;

  const showRemovePaymentCardModal = useRemovePaymentCardModal({
    cardLastFourDigits: cardLastFourDigits,
    cardType: cardType,
    expirationMonth: expirationMonth,
    expirationYear: expirationYear,
  });

  const showRemoveBankAccountModal = useRemovePaymentBankAccountModal({ bankAccount });

  const showRemovePaymentMethodFailModal = usePaymentMethodsErrorModal({});

  const showRemovePaymentMethodSuccessModal = useSuccessErrorMessageModal({
    type: 'success',
    hasCrossButton: true,
  });

  return {
    showRemovePaymentCardModal,
    showRemovePaymentMethodFailModal,
    showRemovePaymentMethodSuccessModal,
    showRemoveBankAccountModal,
  };
};
