import { FC } from 'react';
import { useSelector } from 'react-redux';
import { IQuote, IQuoteItem } from 'types/quotes';
import { useContent } from 'hooks/use-content';
import { formatCurrency } from 'utils/currency-format';
import { getFormattedDeliveryDate } from 'utils/date-format';
import { CorButton } from 'components/cor-button';
import { DASHBOARD_QUOTES_STATUSES } from 'constants/dashboard-quotes-statuses.enum';
import { ContentstackText } from 'components/contentstack';
import { GeneralWarning } from 'components/general-warning';
import { Modal, IModalProps } from 'components/modals/common/modal';
import {
  selectIsBlockedAccount,
  selectIsImpersonateModeOn,
  selectIsTsrUser,
  selectIsUserLocationDeleted,
} from 'store/auth/selectors';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './view-my-special-order-quote-modal.scss';

export interface IViewMySpecialOrderQuoteModalProps extends IModalProps {
  quote: IQuote;
  quoteItem: IQuoteItem;
  onCheckoutClick: () => void;
  sendMsrRequest: (sku: string) => void;
}

export const ViewMySpecialOrderQuoteModal: FC<IViewMySpecialOrderQuoteModalProps> = ({
  quote,
  quoteItem: { sku, price, quantity },
  isOpen,
  onClose,
  onCheckoutClick,
  sendMsrRequest,
}) => {
  const contentstackPath = 'modals.0.customer_view_quote_modal.0';
  const { getContentByKey } = useContent();
  const isUserLocationDeleted = useSelector(selectIsUserLocationDeleted);
  const isAccountBlocked = useSelector(selectIsBlockedAccount);
  const isImpersonateModeOn = useSelector(selectIsImpersonateModeOn);
  const isTsrUser = useSelector(selectIsTsrUser);

  const renderMessage = (commenter?: string, text?: string) => (
    <div className="view-my-special-order-quote-modal__comment">
      <span className="view-my-special-order-quote-modal__comment-title">{commenter}</span>
      <p className="view-my-special-order-quote-modal__comment-text">{text}</p>
    </div>
  );

  const clickHandler = (e: any) => {
    if (!e.target.closest('a')) {
      return;
    }
    e.preventDefault();
    sendMsrRequest(sku);
  };

  const customerMessage = quote?.quoteComments?.find(({ status }) => status === DASHBOARD_QUOTES_STATUSES.REQUESTED);
  const repsMessage = quote?.quoteComments?.find(({ status }) => status === DASHBOARD_QUOTES_STATUSES.APPROVED_BY_DAWN);

  return (
    <Modal
      isOpen={isOpen}
      className="view-my-special-order-quote-modal"
      size="medium_large"
      onClose={onClose}
      withBackdropClick
    >
      <ModalHeader titleContentstackPath={`${contentstackPath}.customer_view_quote_modal_title`} />
      <div className="view-my-special-order-quote-modal__content-wrapper">
        {customerMessage &&
          renderMessage(getContentByKey(`${contentstackPath}.customers_message_title`, ''), customerMessage.text)}
        <div className="view-my-special-order-quote-modal__quote-info">
          <div className="view-my-special-order-quote-modal__info-block">
            <p className="view-my-special-order-quote-modal__info-label">
              <ContentstackText contentKey={`${contentstackPath}.price_label`} />
            </p>
            <p className="view-my-special-order-quote-modal__info-value">{formatCurrency(price)}</p>
          </div>
          <div className="view-my-special-order-quote-modal__info-block">
            <p className="view-my-special-order-quote-modal__info-label">
              <ContentstackText contentKey={`${contentstackPath}.quantity_label`} />
            </p>
            <p className="view-my-special-order-quote-modal__info-value">{quantity}</p>
          </div>
          <div className="view-my-special-order-quote-modal__info-block">
            <p className="view-my-special-order-quote-modal__info-label">
              <ContentstackText contentKey={`${contentstackPath}.subtotal_label`} />
            </p>
            <p className="view-my-special-order-quote-modal__info-value">{formatCurrency(quote?.subtotal)}</p>
          </div>
          <div className="view-my-special-order-quote-modal__info-block">
            <p className="view-my-special-order-quote-modal__info-label">
              <ContentstackText contentKey={`${contentstackPath}.valid_until_label`} />
            </p>
            <p className="view-my-special-order-quote-modal__info-value">
              {quote?.expireDate && getFormattedDeliveryDate(quote.expireDate, true)}
            </p>
          </div>
          <div className="view-my-special-order-quote-modal__info-block">
            <p className="view-my-special-order-quote-modal__info-label">
              <ContentstackText contentKey={`${contentstackPath}.estimated_delivery_date_label`} />
            </p>
            {quote?.leadDate ? (
              <p className="view-my-special-order-quote-modal__info-value">
                {getFormattedDeliveryDate(quote.leadDate)}
              </p>
            ) : (
              <p className="view-my-special-order-quote-modal__info-value">
                <ContentstackText contentKey={`${contentstackPath}.estimated_delivery_date_status`} />
              </p>
            )}
          </div>
        </div>
        {repsMessage &&
          renderMessage(getContentByKey(`${contentstackPath}.sales_rep_message_title`, ''), repsMessage.text)}
        <div className="view-my-special-order-quote-modal__notification" onClick={clickHandler}>
          <GeneralWarning
            className="view-my-special-order-quote-modal-warning"
            contentstackPath={`${contentstackPath}.estimated_delivery_date_notification`}
            messageType="rich_text_messages"
            messageId="MSG133"
          />
        </div>
      </div>
      <ModalFooter className="view-my-special-order-quote-modal__buttons">
        <CorButton className="text" onClick={onClose}>
          <ContentstackText contentKey={`${contentstackPath}.go_back_button_label`} />
        </CorButton>
        {(!isImpersonateModeOn || (isImpersonateModeOn && !isTsrUser)) && (
          <CorButton
            className="view-my-special-order-quote-modal__checkout"
            onClick={onCheckoutClick}
            disabled={isUserLocationDeleted || isAccountBlocked}
          >
            <ContentstackText contentKey={`${contentstackPath}.checkout_button_label`} />
          </CorButton>
        )}
      </ModalFooter>
    </Modal>
  );
};
