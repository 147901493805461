import { FC } from 'react';
import { IModalProps } from 'components/modals/common/modal';
import { SuccessErrorMessageModal } from 'components/modals/success-error-message-modal';
import { ContentstackMessage } from 'components/contentstack';

import './payment-methods-error-modal.scss';

export interface IPaymentMethodsErrorModalProps extends IModalProps {
  messageId: string;
}

export const PaymentMethodsErrorModal: FC<IPaymentMethodsErrorModalProps> = ({ isOpen, onClose, messageId }) => {
  return (
    <SuccessErrorMessageModal
      isOpen={isOpen}
      onClose={onClose}
      type="error"
      modalTitle="Error"
      autoClose={false}
      hasCrossButton
      modalClassName="payment-methods-error-modal"
      children={<ContentstackMessage type="error" messageId={messageId} />}
    />
  );
};
