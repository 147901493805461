export const enum CorContentStackLanguages {
  ENGLISH_UNITED_STATES = 'en-us',
  ENGLISH_EUROPE = 'en-eu',
  ENGLISH_CANADA = 'en-ca',
  SPANISH_LATAM = 'es-la',
  ENGLISH_UNITED_KINGDOM = 'en-gb',
  FRANCH_FRANCE = 'fr-fr',
  ENGLISH_CEE = 'en-ce',
  ENGLISH_NORDICS = 'en-nd',
  DUTCH_NETHERLANDS = 'nl-nl',
  ENGLISH_MEA_APAC = 'en-ap',
  DUTCH_BELGIUM_NEDERLANDS = 'nl-be',
  DUTCH_GERMAN = 'de-de',
  SPANISH_SPAIN = 'es-es',
  POLISH_POLAND = 'pl-pl',
  PORTUGUESE_PORTUGAL = 'pt-pt',
  HUNGARIAN_HUNGARY = 'hu-hu',
  ENGLISH_GLOBAL = 'en-gl',
  FRENCH_BELGIUM = 'fr-be',
  FRENCH_CANADA = 'fr-ca',
}

export const enum CorContentStackLanguagesURL {
  ENGLISH_UNITED_STATES = '/',
  ENGLISH_EUROPE = '/eu',
  ENGLISH_CANADA = '/ca',
  SPANISH_LATAM = '/mx',
  ENGLISH_UNITED_KINGDOM = '/uk',
  FRANCH_FRANCE = '/fr',
  ENGLISH_CEE = '/cee',
  ENGLISH_NORDICS = '/nordics',
  DUTCH_NETHERLANDS = '/nl',
  ENGLISH_MEA_APAC = '/ameap',
  DUTCH_BELGIUM_NEDERLANDS = '/be',
  DUTCH_GERMAN = '/de',
  SPANISH_SPAIN = '/es',
  POLISH_POLAND = '/pl',
  PORTUGUESE_PORTUGAL = '/pt',
  HUNGARIAN_HUNGARY = '/hu',
  ENGLISH_GLOBAL = '/global',
  FRENCH_BELGIUM = '/be/fr',
  FRENCH_CANADA = '/ca/fr',
}

export const CorContentStackLanguagesMap = new Map<CorContentStackLanguagesURL, string>([
  [CorContentStackLanguagesURL.ENGLISH_UNITED_STATES, CorContentStackLanguages.ENGLISH_UNITED_STATES],
  [CorContentStackLanguagesURL.ENGLISH_EUROPE, CorContentStackLanguages.ENGLISH_EUROPE],
  [CorContentStackLanguagesURL.ENGLISH_CANADA, CorContentStackLanguages.ENGLISH_CANADA],
  [CorContentStackLanguagesURL.SPANISH_LATAM, CorContentStackLanguages.SPANISH_LATAM],
  [CorContentStackLanguagesURL.ENGLISH_UNITED_KINGDOM, CorContentStackLanguages.ENGLISH_UNITED_KINGDOM],
  [CorContentStackLanguagesURL.FRANCH_FRANCE, CorContentStackLanguages.FRANCH_FRANCE],
  [CorContentStackLanguagesURL.ENGLISH_CEE, CorContentStackLanguages.ENGLISH_CEE],
  [CorContentStackLanguagesURL.ENGLISH_NORDICS, CorContentStackLanguages.ENGLISH_NORDICS],
  [CorContentStackLanguagesURL.DUTCH_NETHERLANDS, CorContentStackLanguages.DUTCH_NETHERLANDS],
  [CorContentStackLanguagesURL.ENGLISH_MEA_APAC, CorContentStackLanguages.ENGLISH_MEA_APAC],
  [CorContentStackLanguagesURL.DUTCH_BELGIUM_NEDERLANDS, CorContentStackLanguages.DUTCH_BELGIUM_NEDERLANDS],
  [CorContentStackLanguagesURL.DUTCH_GERMAN, CorContentStackLanguages.DUTCH_GERMAN],
  [CorContentStackLanguagesURL.SPANISH_SPAIN, CorContentStackLanguages.SPANISH_SPAIN],
  [CorContentStackLanguagesURL.POLISH_POLAND, CorContentStackLanguages.POLISH_POLAND],
  [CorContentStackLanguagesURL.PORTUGUESE_PORTUGAL, CorContentStackLanguages.PORTUGUESE_PORTUGAL],
  [CorContentStackLanguagesURL.HUNGARIAN_HUNGARY, CorContentStackLanguages.HUNGARIAN_HUNGARY],
  [CorContentStackLanguagesURL.ENGLISH_GLOBAL, CorContentStackLanguages.ENGLISH_GLOBAL],
  [CorContentStackLanguagesURL.FRENCH_BELGIUM, CorContentStackLanguages.FRENCH_BELGIUM],
  [CorContentStackLanguagesURL.FRENCH_CANADA, CorContentStackLanguages.FRENCH_CANADA],
]);

export const CorContentStackLanguageURLMap = new Map<CorContentStackLanguages, string>([
  [CorContentStackLanguages.ENGLISH_EUROPE, CorContentStackLanguagesURL.ENGLISH_EUROPE],
  [CorContentStackLanguages.ENGLISH_CANADA, CorContentStackLanguagesURL.ENGLISH_CANADA],
  [CorContentStackLanguages.SPANISH_LATAM, CorContentStackLanguagesURL.SPANISH_LATAM],
  [CorContentStackLanguages.ENGLISH_UNITED_KINGDOM, CorContentStackLanguagesURL.ENGLISH_UNITED_KINGDOM],
  [CorContentStackLanguages.FRANCH_FRANCE, CorContentStackLanguagesURL.FRANCH_FRANCE],
  [CorContentStackLanguages.ENGLISH_CEE, CorContentStackLanguagesURL.ENGLISH_CEE],
  [CorContentStackLanguages.ENGLISH_NORDICS, CorContentStackLanguagesURL.ENGLISH_NORDICS],
  [CorContentStackLanguages.DUTCH_NETHERLANDS, CorContentStackLanguagesURL.DUTCH_NETHERLANDS],
  [CorContentStackLanguages.ENGLISH_MEA_APAC, CorContentStackLanguagesURL.ENGLISH_MEA_APAC],
  [CorContentStackLanguages.DUTCH_BELGIUM_NEDERLANDS, CorContentStackLanguagesURL.DUTCH_BELGIUM_NEDERLANDS],
  [CorContentStackLanguages.DUTCH_GERMAN, CorContentStackLanguagesURL.DUTCH_GERMAN],
  [CorContentStackLanguages.SPANISH_SPAIN, CorContentStackLanguagesURL.SPANISH_SPAIN],
  [CorContentStackLanguages.POLISH_POLAND, CorContentStackLanguagesURL.POLISH_POLAND],
  [CorContentStackLanguages.PORTUGUESE_PORTUGAL, CorContentStackLanguagesURL.PORTUGUESE_PORTUGAL],
  [CorContentStackLanguages.HUNGARIAN_HUNGARY, CorContentStackLanguagesURL.HUNGARIAN_HUNGARY],
  [CorContentStackLanguages.ENGLISH_GLOBAL, CorContentStackLanguagesURL.ENGLISH_GLOBAL],
  [CorContentStackLanguages.FRENCH_BELGIUM, CorContentStackLanguagesURL.FRENCH_BELGIUM],
  [CorContentStackLanguages.FRENCH_CANADA, CorContentStackLanguagesURL.FRENCH_CANADA],
]);

export const FONT_PAGES = [CorContentStackLanguages.POLISH_POLAND, CorContentStackLanguages.HUNGARIAN_HUNGARY];
