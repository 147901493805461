import { FC } from 'react';
import classNames from 'classnames';
import { Routes } from 'constants/routes.enum';
import { UtilityNavMobile, UtilityNavDesktop } from 'components/layout/cor-header/components/utility-navigation';
import { ICSHeaderCorpNavMenuOut, IMenuItemProps } from 'components/layout/cor-header/cor-header.interface';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { HEADER_STATIC_LABELS } from 'components/layout/cor-header/cor-header.constants';
import { useGetUserRole } from 'hooks/use-get-user-role';

import './utility-nav.scss';

export interface IUtilityMenuProps {
  className?: string;
  menuItems?: IMenuItemProps[];
  shopButtonLabel?: string;
  userName?: string;
  menuTitle?: string;
  userInfo?: any;
  shopMenu?: ICSHeaderCorpNavMenuOut;
  closeHamburgerMenuHandler?: (url) => void;
}

export const UtilityNav: FC<IUtilityMenuProps> = (props) => {
  const { className, userInfo } = props;
  const { menuOptions } = userInfo;
  const { isDesktop, isMobile } = useBreakpoint();
  const { isSuperAdminOrAdmin } = useGetUserRole();
  const menuOptionsWithoutPayments = menuOptions?.filter((item) => item.url !== Routes.AccountPayments);
  const menuDropdownItems = isSuperAdminOrAdmin ? menuOptions : menuOptionsWithoutPayments;

  return (
    <nav className={classNames(className, 'utility-menu')} aria-label={HEADER_STATIC_LABELS.UTILITY_MENU}>
      {isMobile && <UtilityNavMobile menuDropdownOptions={menuDropdownItems} {...props} />}
      {isDesktop && <UtilityNavDesktop menuDropdownOptions={menuDropdownItems} {...props} />}
    </nav>
  );
};
