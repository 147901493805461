import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { size, isEmpty } from 'lodash-es';
import { IShoppingList, ISku } from 'types/product';
import { ShoppingListTypes } from 'constants/shopping-list-types.enum';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { ContentstackHtml } from 'components/contentstack/contentstack-html';
import {
  selectShoppingListsLoading,
  selectAllCustomShoppingLists,
  selectAllShoppingLists,
  selectItemsBeingAddedToSL,
} from 'store/shopping-lists/selectors';
import { LoadingIndicator } from 'components/loading-indicator';
import { AddToShoppingListModalListItem } from 'components/modals/add-items-to-shopping-list-modal/components/add-to-shopping-list-list-item';
import { GeneralWarning } from 'components/general-warning';
import { selectProductSelectedSkuInfo } from 'store/product-details/selectors';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { CorButton } from 'components/cor-button';

import './add-items-to-shopping-list-modal.scss';

export interface IAddItemsToShoppingListModalProps extends IModalProps {
  openCreateListModalHandler: (sku: ISku) => void;
  addLineItemsToShoppingListsHandler: (
    selectedShoppingLists: string[],
    shoppingListId?: string,
    selectedSku?: ISku
  ) => void;
  getTitle?: () => JSX.Element;
  includesFavorites?: boolean;
  itemsCounter?: number;
  shoppingListId?: string;
  addedSku?: ISku;
}

export const AddItemsToShoppingListModal: FC<IAddItemsToShoppingListModalProps> = ({
  isOpen = false,
  onClose = () => {},
  openCreateListModalHandler,
  addLineItemsToShoppingListsHandler,
  getTitle,
  includesFavorites = false,
  itemsCounter,
  shoppingListId,
  addedSku,
}) => {
  const contentstackPath = 'modals.0.add_to_shopping_list_modal.0';
  const dispatch = useDispatch();
  const allShoppingLists = useSelector(selectAllShoppingLists);
  const allCustomShoppingLists = useSelector(selectAllCustomShoppingLists);
  const shoppingLists = includesFavorites ? allShoppingLists : allCustomShoppingLists;
  const itemsBeingAddedToSL = useSelector(selectItemsBeingAddedToSL);

  const selectedSku = useSelector(selectProductSelectedSkuInfo);
  const loading = useSelector(selectShoppingListsLoading);

  const someListsHaveNoCapacity = shoppingLists.filter((list) => itemsCounter && itemsCounter > list.remainingCapacity);
  const listHasNoCapacity = (listId: string) => someListsHaveNoCapacity.find((list) => list.id === listId);

  const [selectedShoppingLists, selectShoppingList] = useState<string[]>([]);

  const itemToBeAddedtoShoppingList = !addedSku ? selectedSku : addedSku;
  useEffect(() => {
    return () => {
      selectShoppingList([]);
    };
  }, [dispatch]);

  const selectList = (listId: string) => {
    const isListAlreadySelected = selectedShoppingLists.includes(listId);

    if (isListAlreadySelected) {
      selectShoppingList(selectedShoppingLists.filter((list) => list !== listId));
      return;
    }

    selectShoppingList([...selectedShoppingLists, listId]);
  };

  const renderShoppingListItem = ({ totalItems, id, name, type }: IShoppingList) => (
    <AddToShoppingListModalListItem
      name={name}
      totalItems={totalItems}
      key={id}
      id={id}
      disabled={!!listHasNoCapacity(id)}
      onChangeCheckbox={selectList}
      isFavorites={type === ShoppingListTypes.FAVORITES}
    />
  );

  return (
    <>
      <Modal
        size="medium"
        className="add-items-to-shopping-list-modal"
        isOpen={isOpen}
        onClose={onClose}
        withBackdropClick
      >
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="add-items-to-shopping-list-modal__content-wrapper">
              <ModalHeader>
                {getTitle ? (
                  getTitle()
                ) : (
                  <ContentstackText
                    contentKey={`${contentstackPath}.${includesFavorites ? 'heading_including_favorites' : 'heading'}`}
                  />
                )}
              </ModalHeader>
              <div className="add-items-to-shopping-list-modal__shopping-lists-info">
                {!isEmpty(someListsHaveNoCapacity) && (
                  <GeneralWarning
                    className="add-items-to-shopping-list-modal__no-capacity-warning"
                    contentstackPath={contentstackPath}
                    messageType="notifications"
                    messageId="MSG152"
                  />
                )}
                <div className="add-items-to-shopping-list-modal__subheading">
                  <p className="add-items-to-shopping-list-modal__chosen-lists-counter">
                    <ContentstackHtml
                      contentKey={`${contentstackPath}.shopping_lists_counter_text`}
                      component="span"
                      interpolateParams={{
                        quantity: size(selectedShoppingLists),
                      }}
                    />
                  </p>
                  <div className="add-items-to-shopping-list-modal__create-new-list">
                    <CorButton
                      className="add-items-to-shopping-list-modal__create-list-button link-1"
                      onClick={() => openCreateListModalHandler(itemToBeAddedtoShoppingList)}
                    >
                      <ContentstackText contentKey={`${contentstackPath}.create_new_list_button_label`} />
                    </CorButton>
                    <div className="add-items-to-shopping-list-modal__cross" />
                  </div>
                </div>
                {shoppingLists.map(renderShoppingListItem)}
              </div>
            </div>
            <div className="add-items-to-shopping-list-modal__footer">
              <p className="add-items-to-shopping-list-modal__chosen-lists-counter">
                <ContentstackHtml
                  contentKey={`${contentstackPath}.shopping_lists_counter_text`}
                  component="span"
                  interpolateParams={{
                    quantity: size(selectedShoppingLists),
                  }}
                />
              </p>
              <ModalFooter className="add-items-to-shopping-list-modal__footer-buttons">
                <CorButton className="link-1" onClick={onClose}>
                  <ContentstackText contentKey={`${contentstackPath}.cancel_button_label`} />
                </CorButton>
                <CorButton
                  className="add-items-to-shopping-list-modal__add-items"
                  disabled={isEmpty(selectedShoppingLists) || itemsBeingAddedToSL}
                  onClick={() =>
                    addLineItemsToShoppingListsHandler(
                      selectedShoppingLists,
                      shoppingListId,
                      itemToBeAddedtoShoppingList
                    )
                  }
                >
                  <ContentstackText contentKey={`${contentstackPath}.add_item_to_shopping_list_button_label`} />
                </CorButton>
              </ModalFooter>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
