import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from 'constants/routes.enum';
import { Modal, IModalProps } from 'components/modals/common/modal';
import { CorButton } from 'components/cor-button';
import { ContentstackText } from 'components/contentstack';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './locked-account-message-modal.scss';

export const LockedAccountMessageModal: FC<IModalProps> = ({ isOpen, onClose }) => {
  const contentstackPath = 'modals.0.locked_account_warning_modal.0';
  const history = useHistory();
  const { isMobile } = useBreakpoint();

  const handleForgotPasswordButtonClick = () => {
    history.push(Routes.ResetPasswordVerificationPage);
  };

  return (
    <Modal className="locked-account-message-modal" isOpen={isOpen} onClose={onClose} withBackdropClick>
      <ModalHeader
        iconType="error"
        contentstackPathIcon={`${contentstackPath}.icon`}
        titleContentstackPath={`${contentstackPath}.heading`}
      />
      <p className="locked-account-message-modal__description">
        <ContentstackText contentKey={`${contentstackPath}.description`} />
      </p>
      <ModalFooter className="locked-account-message-modal__buttons">
        <CorButton color={isMobile ? 'secondary' : 'text'} onClick={onClose}>
          <ContentstackText contentKey={`${contentstackPath}.try_again_button_label`} />
        </CorButton>
        <CorButton onClick={handleForgotPasswordButtonClick}>
          <ContentstackText contentKey={`${contentstackPath}.forgot_password_button_label`} />
        </CorButton>
      </ModalFooter>
    </Modal>
  );
};
