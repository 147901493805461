import { takeLatest, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { Routes } from 'constants/routes.enum';
import { doRequest } from 'store/api/actions';
import {
  submitAdditionalUserRegistration,
  submitAdditionalUserRegistrationActionConstants,
  validateRegistrationToken,
  validateRegistrationTokenActionConstants,
} from 'store/customer-registration/actions';
import { IAction } from 'types/actions';

export interface IAdditionalUserRegistrationPayload {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  token: string | null;
  password: string;
  confirmPassword: string;
  isAcceptTermsAndConditions: boolean;
  isMarkedForNotifications: boolean;
  userId?: string;
  salesOrg?: string;
  lang?: string | null;
}

export interface ITokenRequestPayload {
  token: string;
}

export interface ITokenResponsePayload {
  email: string;
}

export function* validateRegistrationTokenRequestHandler({ payload }: IAction<string>) {
  yield put(
    doRequest<ITokenRequestPayload>(
      validateRegistrationToken,
      `${Endpoints.VALIDATE_USER_REGISTRATION_TOKEN}/${payload}`,
      'get'
    )
  );
}

export function* validateRegistrationTokenFailureHandler() {
  yield put(push(Routes.LoginPage));
}

export function* registerAdditionalUserSagaHandler({ payload }: IAction<IAdditionalUserRegistrationPayload>) {
  const locale = yield localStorage.getItem('locale');

  yield put(
    doRequest<IAdditionalUserRegistrationPayload>(
      submitAdditionalUserRegistration,
      `${Endpoints.REGISTER_ADDITIONAL_USER}`,
      'post',
      { ...payload, lang: JSON.parse(locale) }
    )
  );
}

export function* registerAdditionalUserSuccessHandler() {
  yield put(push(Routes.CustomerRegistrationSuccessPage));
}

export default function* registerAdditionalUserSaga() {
  yield takeLatest(submitAdditionalUserRegistrationActionConstants[Actions.REQUEST], registerAdditionalUserSagaHandler);
  yield takeLatest(
    submitAdditionalUserRegistrationActionConstants[Actions.SUCCESS],
    registerAdditionalUserSuccessHandler
  );
  yield takeLatest(validateRegistrationTokenActionConstants[Actions.REQUEST], validateRegistrationTokenRequestHandler);
  yield takeLatest(validateRegistrationTokenActionConstants[Actions.FAIL], validateRegistrationTokenFailureHandler);
}
