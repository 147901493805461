import { Actions } from 'constants/actions.enum';
import { createReducer } from 'store/reducer-creator';
import types from './action-types';
import * as actions from './actions';

export interface IOrderCutOffState {
  cutOffNotificationTimer: number;
  deliveryDates: string[];
  dueDate: string;
  isVisible?: boolean;
  isExpired?: boolean;
}

export const orderCutOffInitialState: IOrderCutOffState = {
  cutOffNotificationTimer: 0,
  deliveryDates: [],
  dueDate: '',
  isVisible: false,
  isExpired: false,
};

const orderCutOffInfo = createReducer<IOrderCutOffState>(
  {
    [actions.getCutOffNotificationTimerActionConstants[Actions.SUCCESS]]: (
      state: IOrderCutOffState,
      { payload: { cutOffNotificationTimer, deliveryDates, dueDate } }
    ) => ({
      ...state,
      cutOffNotificationTimer,
      deliveryDates,
      dueDate,
    }),

    [types.CLEAR_CUTOFF_NOTIFICATION_DATETIME]: () => ({ ...orderCutOffInitialState }),
    [types.SET_CUTOFF_NOTIFICATION_VISIBLE]: (state, { payload }) => ({ ...state, isVisible: payload.isVisible }),
    [types.SET_CUTOFF_NOTIFICATION_EXPIRED]: (state, { payload }) => ({ ...state, isExpired: payload.isExpired }),
  },
  orderCutOffInitialState
);

export default orderCutOffInfo;
