import { Link } from 'react-router-dom';
import { stringify } from 'qs';
import { ICSPageType } from 'corporate-interfaces';

import './quick-links.scss';

interface IQuickLinksProps {
  query: string;
  afterClickQuickLink: () => void;
  globalFacets: any;
  localeUrl?: string;
}

interface IQuickLinkProps {
  itemType: string;
  query: string;
  index: number;
  pageType?: ICSPageType;
  isFirst?: boolean;
  afterClickQuickLink: () => void;
  localeUrl?: string;
}

const QuickLink = ({ itemType, query, index, pageType, afterClickQuickLink, isFirst, localeUrl }: IQuickLinkProps) => {
  const url = localeUrl
    ? `${localeUrl}${pageType?.url}&${stringify({ query })}`
    : `${pageType?.url}&${stringify({ query })}`;

  if (!pageType) {
    return null;
  }
  return (
    <>
      {isFirst ? '' : ', '}
      <Link className="quick-link" to={url} key={`type-${index}`} onClick={() => afterClickQuickLink()}>
        <span className="emphasize-text">{pageType?.title || itemType || 'a'}</span>
      </Link>
    </>
  );
};

const QuickLinks = ({ query, afterClickQuickLink, globalFacets, localeUrl }: IQuickLinksProps) => {
  const pageTypes = globalFacets?.page_types as ICSPageType[];

  return (
    <>
      {pageTypes.map((itemType, index) => (
        <QuickLink
          itemType={itemType.type}
          query={query}
          index={index}
          pageType={itemType}
          isFirst={index === 0}
          key={`quick-link-${index}`}
          afterClickQuickLink={afterClickQuickLink}
          localeUrl={localeUrl}
        />
      ))}
    </>
  );
};

export default QuickLinks;
