import React from 'react';
import { stringify } from 'qs';
import { CorCardsMobile, CorSmallCardProduct, CorSmallCardTitleCategory } from 'corporate-components/cor-cards';
import { CorTypography } from 'corporate-ui';
import { useHistory } from 'react-router-dom';
import { useAlgolia, useAlgoliaQueryParams } from 'hooks';
import classNames from 'classnames';
import { parseAlgoliaPagination } from 'corporate-utils/cor-algolia-helpers';
import { template } from 'lodash-es';
import { IAlgoliaContentTypesEnum, IAlgoliaTagsEnum } from 'queries/use-algolia-query';
import { ICSSearchResultFilterButtons } from 'corporate-interfaces';
import { LoadingIndicator } from 'components/loading-indicator';
import { ALGOLIA_DEFAULT_PAGE_NUMBER } from 'hooks/use-algolia';
import { Routes } from 'constants/routes.enum';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { useContent } from 'hooks/use-content';
import { ContentstackContext } from 'context/contentstack';
import { CorButton } from 'components/cor-button';
import { SearchResultsContentTypeComponentsMap, SearchResultsTagsComponentsMap } from '../../search-results.mapper';
import {
  SearchResultsContentTypeCardsClassNameMap,
  SearchResultsContentTypeQuantityMap,
  SearchResultsTagsCardsClassNameMap,
  SearchResultsTitleQuantityMap,
} from '../../search-results.config';
import { CorSearchResultsEmpty } from '../cor-search-results-empty';

const ENTRIES_KEY = 'entries';
const GLOBAL_FACETS_KEY = 'global_facets.0.page_types';
const LABELS_KEY = 'labels.0.key_value_pair.value';

const CorMainPage = () => {
  const { isMobile } = useBreakpoint();
  const { getContentByKey, getLabelsByKey } = useContent();
  const { queryParams, setQueryParams } = useAlgoliaQueryParams();
  const history = useHistory();

  const labels = getLabelsByKey<Record<string, string>>(LABELS_KEY, {});
  const contentStackPageTypes = getContentByKey<ICSSearchResultFilterButtons>(GLOBAL_FACETS_KEY, []);
  const algoliaQuery = useAlgolia({ query: queryParams?.query || '', isTotalShopProductsCounted: true });

  const algoliaMetaData = algoliaQuery?.data?.globalPageTypeMeta;

  const handleShopProductsSeeAllButton = React.useCallback(() => {
    if (!queryParams?.query) return history.push(Routes.ProductListPage);

    history.push(`${Routes.ProductSearchPage}?${stringify({ query: queryParams.query })}`);
  }, [history, queryParams?.query]);

  const filteredGlobalPageTypeContents = algoliaQuery?.data?.globalPageTypeContents;

  if (algoliaQuery.isLoading) return <LoadingIndicator />;
  if (algoliaQuery.isError || (algoliaQuery.isSuccess && !algoliaMetaData?.page?.total_results))
    return <CorSearchResultsEmpty />;
  return (
    <>
      <section className="grid-container search-results-page__container">
        <CorTypography variant="small-caps">
          {template(labels?.pagination_total_results || '<%= total %> TOTAL RESULTS')(
            parseAlgoliaPagination(algoliaMetaData)
          )}
        </CorTypography>
      </section>

      {!!algoliaQuery?.data?.c_shop_product_cards?.length && (
        <section className={classNames('search-results-page__cards-container')}>
          <div className="grid-container search-results-page__container search-results-page__cards-header">
            <CorTypography variant="h2">
              {!!queryParams?.query ? (
                <>
                  <span>{queryParams.query}</span> {labels?.page_type_in || 'in'}{' '}
                  {labels?.shop_products || 'Shop Products'}
                </>
              ) : (
                labels?.shop_products || 'Shop Products'
              )}
            </CorTypography>
            <CorTypography variant="body-large" color="mid-gray" className="search-results-page__cards-results">
              {template(labels?.list_title || '<%= total %> <%= category_name_label %> Results')({
                total: algoliaQuery?.data?.total,
                category_name_label: labels?.shop_products || 'Shop Products',
              })}
            </CorTypography>

            {!isMobile && (
              <CorButton color="secondary" onClick={handleShopProductsSeeAllButton}>
                {labels?.see_all || 'See All'}
              </CorButton>
            )}
          </div>

          <ContentstackContext.Provider value={{ entries: algoliaQuery?.data?.c_shop_product_cards?.slice(0, 5) }}>
            {!isMobile && (
              <div
                className={classNames(
                  'grid-container',
                  'search-results-page__container',
                  'search-results-page__cards',
                  'search-results-page__cards--products'
                )}
              >
                {algoliaQuery?.data?.c_shop_product_cards?.slice(0, 5)?.map((_, index) => (
                  <CorSmallCardProduct contentKeyBase={`${ENTRIES_KEY}[${index}]`} key={index.toString()} />
                ))}
              </div>
            )}
            {isMobile && (
              <CorCardsMobile isSmallCardVariant>
                {algoliaQuery?.data?.c_shop_product_cards?.slice(0, 5)?.map((_, index) => (
                  <CorSmallCardProduct contentKeyBase={`${ENTRIES_KEY}[${index}]`} key={index.toString()} />
                ))}
              </CorCardsMobile>
            )}
          </ContentstackContext.Provider>

          {isMobile && (
            <div className="grid-container search-results-page__container">
              <CorButton
                className="search-results-page__cards-see-all"
                color="secondary"
                onClick={handleShopProductsSeeAllButton}
              >
                {labels?.see_all || 'See All'}
              </CorButton>
            </div>
          )}
        </section>
      )}
      {!!filteredGlobalPageTypeContents?.length &&
        filteredGlobalPageTypeContents.map((entry, index) => {
          if (!entry) return null;

          const categoryName = entry.categories[0].categoryName;
          const categoryTitle = entry.title;
          const categoryTag: any = contentStackPageTypes?.find(
            ({ type, title }) => [categoryName].includes(type) && [categoryTitle].includes(title)
          )?.tag;

          const categoryNameLabel = contentStackPageTypes?.find(
            ({ type, title }) => [categoryName].includes(type) && [categoryTitle].includes(title)
          )?.title;

          const Card =
            ([IAlgoliaContentTypesEnum.StandardPage].includes(categoryName)
              ? SearchResultsTagsComponentsMap.get(categoryTag)
              : SearchResultsContentTypeComponentsMap.get(categoryName)) || CorSmallCardTitleCategory;
          const entries = entry?.c_cards?.slice(
            0,
            ([IAlgoliaContentTypesEnum.StandardPage].includes(categoryName)
              ? SearchResultsTitleQuantityMap.get(categoryTag)
              : SearchResultsContentTypeQuantityMap.get(categoryName)) || 4
          );

          const handleButtonOnClick = () => {
            setQueryParams(() => ({
              page_type: [IAlgoliaContentTypesEnum.StandardPage].includes(categoryName) ? categoryTag : categoryName,
              page: ALGOLIA_DEFAULT_PAGE_NUMBER,
            }));
          };

          return (
            <section
              className={classNames('search-results-page__cards-container', {
                'search-results-page__cards-container--first': [0].includes(index),
              })}
              key={index.toString()}
            >
              <div className="grid-container search-results-page__container search-results-page__cards-header">
                <CorTypography variant="h2">
                  {!!queryParams?.query ? (
                    <>
                      <span>{queryParams.query}</span> {labels?.page_type_in || 'in'} {categoryNameLabel}
                    </>
                  ) : (
                    categoryNameLabel
                  )}
                </CorTypography>
                <CorTypography variant="body-large" color="mid-gray" className="search-results-page__cards-results">
                  {template(labels?.list_title || '<%= total %> <%= category_name_label %> Results')({
                    total: entry.total,
                    category_name_label: categoryNameLabel,
                  })}
                </CorTypography>

                {!isMobile && (
                  <CorButton color="secondary" onClick={handleButtonOnClick}>
                    {labels?.see_all || 'See All'}
                  </CorButton>
                )}
              </div>

              <ContentstackContext.Provider value={{ entries }}>
                {(!isMobile || [IAlgoliaTagsEnum.FAQ].includes(categoryTag)) && (
                  <div
                    className={classNames(
                      'grid-container',
                      'search-results-page__container',
                      'search-results-page__cards',
                      {
                        [SearchResultsContentTypeCardsClassNameMap.get(categoryName) || '']:
                          ![IAlgoliaContentTypesEnum.StandardPage].includes(categoryName) &&
                          SearchResultsContentTypeCardsClassNameMap.has(categoryName),
                      },
                      {
                        [SearchResultsTagsCardsClassNameMap.get(categoryTag) || '']:
                          [IAlgoliaContentTypesEnum.StandardPage].includes(categoryName) &&
                          SearchResultsTagsCardsClassNameMap.has(categoryTag),
                      }
                    )}
                  >
                    {entries?.map((_, index) => (
                      <Card contentKeyBase={`${ENTRIES_KEY}[${index}]`} key={index.toString()} />
                    ))}
                  </div>
                )}
                {isMobile && ![IAlgoliaTagsEnum.FAQ].includes(categoryTag) && (
                  <CorCardsMobile isSmallCardVariant>
                    {entries?.map((_, index) => (
                      <Card contentKeyBase={`${ENTRIES_KEY}[${index}]`} key={index.toString()} />
                    ))}
                  </CorCardsMobile>
                )}
              </ContentstackContext.Provider>

              {isMobile && (
                <div className="grid-container search-results-page__container">
                  <CorButton
                    className="search-results-page__cards-see-all"
                    color="secondary"
                    onClick={handleButtonOnClick}
                  >
                    {labels?.see_all || 'See All'}
                  </CorButton>
                </div>
              )}
            </section>
          );
        })}
    </>
  );
};

export default React.memo(CorMainPage);
