import { FC, SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stringify } from 'qs';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { AutocompleteState, createAutocomplete } from '@algolia/autocomplete-core';
import { IAlgoliaProductsSuggestionsItem } from 'types/algolia';
import { useContent } from 'hooks/use-content';
import { useOutsideClick } from 'hooks/use-outside-click';
import { Routes } from 'constants/routes.enum';
import iconSearch from 'assets/images/icons/icon-search-red.svg';
import { debouncePromise } from 'utils/debounce-promise';
import { selectIsLoggedInUser } from 'store/auth/selectors';
import { ReactComponent as IconClose } from 'assets/images/icons/icon-close-dark.svg';
import { clearProductsMetaState } from 'store/products/actions';
import { useMainNavState } from 'components/layout/cor-header/context/headerContext';
import { HEADER_STATIC_LABELS } from 'components/layout/cor-header/cor-header.constants';
import { useLocalStorage } from 'hooks/use-local-storage';
import { AlgoliaSuggestions } from 'components/layout/cor-header/components/algolia-search/components/algolia-suggestions';
import { useAlgoliaClient } from 'components/layout/cor-header/components/algolia-search/hooks/useAlgoliaClient';
import { CorContentStackLanguageURLMap } from 'constants/cor-locale.enum';
import { isInternationalSite } from 'utils/get-locale-params';
import { useBreakpoint } from 'hooks/use-breakpoint';

import './algolia-search.scss';

export const SEARCH_DEBOUNCE_TIME = 250;

export interface IAlgoliaSearchProps {
  id: string;
  focusOnRender?: boolean;
  afterSearch?: () => void;
  expandable?: boolean;
  isLoggedIn?: boolean;
  isSearchVisibleOnMobile?: boolean;
}

export const AlgoliaSearch: FC<IAlgoliaSearchProps> = ({
  focusOnRender = false,
  afterSearch = () => {},
  expandable = false,
  id,
  isSearchVisibleOnMobile,
}) => {
  const dispatch = useDispatch();
  const { getContentByKey } = useContent();
  const history = useHistory();
  const [autocompleteState, setAutocompleteState] = useState<AutocompleteState<IAlgoliaProductsSuggestionsItem>>();
  const [isSearchInputFocus, setIsSearchInputFocus] = useState(false);
  const { isMobile } = useBreakpoint();

  const globalFacetsData = getContentByKey('globalFacets', {});

  const isLoggedInUser: boolean = useSelector(selectIsLoggedInUser);

  const { getSources } = useAlgoliaClient(isLoggedInUser, 8);
  const [locale] = useLocalStorage('locale', null);
  const localeUrl = CorContentStackLanguageURLMap.get(locale);

  const formRef = useRef<HTMLFormElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchInputToggleRef = useRef<HTMLButtonElement>(null);
  const suggestionsRef = useRef(null);
  const basePath = isLoggedInUser ? Routes.ProductSearchPage : Routes.SearchResults;
  const defaultQueryOnSubmit = !isLoggedInUser ? { page_type: 'all' } : null;
  const { header } = useMainNavState();

  const debouncedItemsPromise = useMemo(
    () => debouncePromise((items) => Promise.resolve(items), SEARCH_DEBOUNCE_TIME),
    []
  );

  const autocomplete = useMemo(
    () =>
      createAutocomplete<IAlgoliaProductsSuggestionsItem, SyntheticEvent, SyntheticEvent, SyntheticEvent>({
        onStateChange({ state }) {
          setAutocompleteState(state);
        },
        getSources() {
          return debouncedItemsPromise(getSources());
        },
        onSubmit({ state }) {
          if (state?.query?.trim()) {
            debouncePromise(() => {
              handleSearchInputBlur();
            }, SEARCH_DEBOUNCE_TIME)().then(() => {
              isInternationalSite(locale)
                ? history.push(`${localeUrl}${basePath}?${stringify({ query: state.query, ...defaultQueryOnSubmit })}`)
                : history.push(`${basePath}?${stringify({ query: state.query, ...defaultQueryOnSubmit })}`);
            });
          }
          afterSearch();
        },
        autoFocus: focusOnRender,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSearchInputFocus = useCallback(() => {
    setIsSearchInputFocus(true);
  }, []);

  const resetForm = useCallback(() => {
    formRef?.current?.reset();
    setIsSearchInputFocus(false);
    searchInputRef?.current?.blur();
  }, []);

  const handleSearchInputBlur = useCallback(() => {
    resetForm();
    autocomplete.setIsOpen(false);
  }, [autocomplete, resetForm]);

  const handleSuggestionClick = () => {
    dispatch(clearProductsMetaState());
    handleSearchInputBlur();
    afterSearch();
  };

  const onOuterClick = (e?: MouseEvent | TouchEvent) => {
    if (searchInputToggleRef.current && !searchInputToggleRef.current.contains(e?.target as Node)) {
      handleSuggestionClick();
    }
  };

  useOutsideClick(searchInputRef, expandable, onOuterClick, true, suggestionsRef);

  const collections = autocompleteState?.collections || [];

  const autocompleteResults = collections[0];

  const collapseInputOnOuterClick = () => {
    if (isMobile && expandable && isSearchVisibleOnMobile && isSearchInputFocus) {
      handleSearchInputBlur();
    }
  };

  const ref = useRef(null);
  useOutsideClick(ref, expandable, collapseInputOnOuterClick, true);

  const onLoginLinkClick = () => {
    if (autocompleteState?.query) {
      localStorage.setItem('query', autocompleteState?.query);
      handleSearchInputBlur();
    }
  };

  return (
    <div
      className={classNames('algolia-search', {
        'algolia-search--expandable': expandable,
        'algolia-search--search-input-visible': isSearchInputFocus,
        'algolia-search--search-input-empty': autocompleteState?.query?.length === 0,
      })}
      ref={ref}
      {...autocomplete.getRootProps({})}
    >
      <form
        className="algolia-search__input-wrapper"
        ref={formRef}
        {...autocomplete.getFormProps({ inputElement: searchInputRef.current })}
      >
        <fieldset>
          <label htmlFor={id}>
            <span className={'algolia-search__input-label'}>
              {header?.search_bar?.search_bar_placeholder_text || HEADER_STATIC_LABELS.SEARCH}
            </span>
            <input
              className="algolia-search__input"
              ref={searchInputRef}
              {...autocomplete.getInputProps({ inputElement: searchInputRef.current })}
              onFocus={handleSearchInputFocus}
              placeholder={header?.search_bar?.search_bar_placeholder_text || HEADER_STATIC_LABELS.SEARCH}
              id={id}
            />
          </label>
          <button type="submit" className="algolia-search__search-button">
            <img src={iconSearch} alt="search button icon" className="algolia-search__search-icon" />
          </button>
        </fieldset>
      </form>

      {expandable && (
        <button type="button" className="algolia-search--close button-reset" onClick={handleSearchInputBlur}>
          <IconClose />
        </button>
      )}
      <AlgoliaSuggestions
        suggestions={autocompleteResults}
        query={autocompleteState?.query || ''}
        handleSuggestionClick={handleSuggestionClick}
        isOpen={!!autocompleteState?.isOpen}
        suggestionsRef={suggestionsRef}
        panelProps={autocomplete.getPanelProps({})}
        listProps={autocomplete.getListProps()}
        isLoggedInUser={isLoggedInUser}
        getItemProps={autocomplete.getItemProps}
        afterClickQuickLink={handleSuggestionClick}
        globalFacets={globalFacetsData}
        onLoginLinkClick={onLoginLinkClick}
      />
    </div>
  );
};
