enum AvailabilityEnum {
  Us = 'us',
  Shop = 'shop',
  International = 'international',
}

type gtmScriptType = { scriptId: string; availableFor: AvailabilityEnum[]; scriptUrl: string };

export const updateGTMScript = (isUsaPages: boolean, isShopPages: boolean) => {
  const gtmScripts: gtmScriptType[] = [
    {
      scriptId: 'TVXXWBX',
      availableFor: [AvailabilityEnum.Us, AvailabilityEnum.Shop],
      scriptUrl: 'https://www.googletagmanager.com/gtm.js?id=GTM-TVXXWBX&l=dataLayer',
    },
    {
      scriptId: 'KQP9L79',
      availableFor: [AvailabilityEnum.Us, AvailabilityEnum.Shop],
      scriptUrl: 'https://www.googletagmanager.com/gtm.js?id=GTM-KQP9L79',
    },
    {
      scriptId: 'M4WWQG',
      availableFor: [AvailabilityEnum.Us, AvailabilityEnum.International],
      scriptUrl: 'https://www.googletagmanager.com/gtm.js?id=GTM-M4WWQG',
    },
  ];

  const insertScript = (script) => {
    const scriptsOnThePage = Array.from(document.querySelectorAll('script.gtm-script'));
    if (scriptsOnThePage.find((item) => item.id === script.scriptId)) return; // already exist

    const scriptElement = document.createElement('script');
    scriptElement.src = script.scriptUrl;
    scriptElement.id = script.scriptId;
    scriptElement.className = 'gtm-script';
    scriptElement.async = true;

    const head = document.head || document.getElementsByTagName('head')[0];
    head.appendChild(scriptElement);
  };

  const applyGTMs = (env) => {
    const scriptsOnThePage = Array.from(document.querySelectorAll('script.gtm-script'));

    gtmScripts.forEach((script: gtmScriptType) => {
      if (script.availableFor.includes(env)) {
        insertScript(script);
      } else {
        scriptsOnThePage?.forEach((item) => item.id === script.scriptId && item.remove()); // if exists but shouldn't
      }
    });
  };

  // WSI-1386
  // TVXXWBX and KQP9L79 should only be installed on the US site and Shop
  // M4WWQG should be installed on ALL sites, including the US site, but NOT shop
  if (isUsaPages) {
    if (isShopPages) {
      applyGTMs(AvailabilityEnum.Shop);
    } else {
      applyGTMs(AvailabilityEnum.Us);
    }
  } else {
    applyGTMs(AvailabilityEnum.International);
  }
};
