import { action } from 'typesafe-actions';
import { Limit } from 'constants/limit.enum';
import { ISortParams } from 'types/product-sort-options';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import { IFilter } from 'types/facets';
import types from './action-types';
import { IProductsState } from './reducer';

export const getProductsActionConstants = createConstants(types.GET_PRODUCTS);
export const getCarouselProductsActionConstants = createConstants(types.GET_CAROUSEL_PRODUCTS);
export const getProductsBySkusActionConstants = createConstants(types.GET_PRODUCTS_BY_SKUS);
export const getTemporaryFiltersInfoActionConstants = createConstants(types.GET_TEMPORARY_FILTERS_INFO);

export const getProducts = createRequestActions(getProductsActionConstants);
export const getTemporaryFiltersInfo = createRequestActions(getTemporaryFiltersInfoActionConstants);
export const getCarouselProducts = createRequestActions(getCarouselProductsActionConstants);
export const getProductsBySkus = createRequestActions(getProductsBySkusActionConstants);

export const changePaginationCurrent = (current: number) => {
  return action(types.PRODUCTS_PAGINATION_CHANGE, {
    current,
  });
};

export const setProductsSortParams = (selectedSortField: ISortParams) => {
  return action(types.SET_PRODUCTS_SORT_PARAMS, {
    sortParams: selectedSortField,
  });
};

export const setProductsLimit = (selectedLimit: Limit) => {
  return action(types.SET_PRODUCTS_LIMIT, {
    limit: selectedLimit,
  });
};

export const setSelectedFacets = (selectedFacets: IFilter[]) =>
  action(types.SET_SELECTED_FACETS, {
    selectedFacets,
  });

export const preselectSelectedFacetsFromUrl = (preselectedFacets: IFilter[]) =>
  action(types.PRESELECT_SELECTED_FACETS_FROM_URL, {
    preselectedFacets,
  });

export const clearSelectedFacets = () => action(types.CLEAR_SELECTED_FACETS, {});

export const resetSelectedFacets = () => action(types.RESET_SELECTED_FACETS, {});

export const setSelectedCategoryKey = (categoryKey: string) => action(types.SET_SELECTED_CATEGORY_KEY, categoryKey);
export const updateSelectedCategoryKey = (categoryKey: string) =>
  action(types.UPDATE_SELECTED_CATEGORY_KEY, categoryKey);

export const resetDawnBrands = (dawnBrands: string[]) => action(types.RESET_DAWN_BRANDS, { dawnBrands });

export const setSelectedFilters = (payload: IProductsState) => action(types.SET_SELECTED_FILTERS, payload);

export const clearProductsMetaState = () => action(types.CLEAR_PRODUCTS_META_STATE);

export const initTemporarySelectedFilters = () => action(types.INIT_TEMPORARY_SELECTED_FILTERS);

export const setTemporarySelectedFilters = (payload: IProductsState) =>
  action(types.SET_TEMPORARY_SELECTED_FILTERS, payload);

export const resetTemporarySelectedFilters = () => action(types.RESET_TEMPORARY_SELECTED_FILTERS);

export const confirmTemporarySelectedFilters = () => action(types.CONFIRM_TEMPORARY_SELECTED_FILTERS);

export const setTemporarySelectedCategory = (payload: any) => action(types.SET_TEMPORARY_SELECTED_CATEGORY, payload);

export const setTemporarySelectedFacets = (selectedFacets: IFilter[]) =>
  action(types.SET_TEMPORARY_SELECTED_FACETS, {
    selectedFacets,
  });

export const clearTemporarySelectedFacets = () => action(types.CLEAR_TEMPORARY_SELECTED_FACETS);
