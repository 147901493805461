import { FC, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Routes } from 'constants/routes.enum';
import { IProduct } from 'types/product';
import { ISkuInformation } from 'types/order-details';
import { useContent } from 'hooks/use-content';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { addProductToCart } from 'store/shopping-cart/actions';
import { IAddProductToCartPayload } from 'store/shopping-cart/sagas/add-product-to-cart';
import { selectIsProductBeingUpdated } from 'store/shopping-cart/selectors';
import { CustomTooltip } from 'components/custom-tooltip';
import { CorButton } from 'components/cor-button';
import { ContentstackMessage, ContentstackText } from 'components/contentstack';
import { PermissionAccess } from 'components/permission-access';
import { Permissions } from 'constants/permissions';
import { AccessDefinitions } from 'constants/access-definitions.enum';
import { useLocalStorage } from 'hooks/use-local-storage';
import { ProductImage } from 'components/product-image';
import { stripLeadingZeros } from 'utils/string-format';
import {
  selectIsImpersonateModeOn,
  selectIsKeyAccountUser,
  selectIsTsrUser,
  selectIsUserLocationDeleted,
} from 'store/auth/selectors';
import { getSkuReplacements } from 'store/items-replacements/actions';
import { IGetSkuReplacementsPayload } from 'store/items-replacements/sagas/get-sku-replacements';
import { ProductStatusLabels } from 'components/product-status-labels';
import { ProductIndicators } from 'components/product-indicators';
import { useProductSeasonalIndicator } from 'hooks/use-product-seasonal-indicator';
import iconInfo from 'assets/images/icons/icon-info.svg';
import iconInfoDisabled from 'assets/images/icons/icon-info-disabled.svg';
import iconAddedToCart from 'assets/images/icons/icon-added-to-cart.svg';
import { QuantityControl } from 'components/quantity-control';
import { HierarchyAccountsSpecialOrderMessage } from 'components/hierarchy-accounts-special-order-message';

import './product-card.scss';

export interface ISkuProductCardProps extends Partial<IProduct> {
  packing?: string;
  added?: boolean;
  isSpecialOrder?: boolean;
  isDiscontinued?: boolean;
  isPhasingOut?: boolean;
  seasonalBadgeId?: string;
  showCategory?: boolean;
  category?: string;
  overrideTooltipPosition?: boolean;
  className?: string;
  usageContext?: string;
  onRequestQuoteCtaClick: (skuKey: string) => void;
  substitutionSku?: string[];
  maximumQuantity?: number;
  contentStackPath?: string;
}

const DEFAULT_PRODUCT_QUANTITY = 1;
const imageDesktopSize = { width: 304, height: 304 };

export const SkuProductCard: FC<ISkuProductCardProps> = ({
  imageUrl,
  name,
  brand,
  packing,
  productKey,
  sku,
  flavor,
  color,
  added,
  isDiscontinued,
  isSpecialOrder,
  isPhasingOut,
  price,
  segmentationPrice,
  overrideTooltipPosition = true,
  className,
  onRequestQuoteCtaClick,
  usageContext,
  substitutionSku,
  maximumQuantity,
  seasonalBadgeId,
  isInCart,
  contentStackPath,
}) => {
  const { getContentByKey } = useContent();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isDesktop } = useBreakpoint();
  const seasonalIndicator = useProductSeasonalIndicator(seasonalBadgeId);

  const contentStackProductPath = contentStackPath ? contentStackPath : 'page_content.product_list.product_item[0]';
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const isProductBeingUpdated = useSelector(selectIsProductBeingUpdated);
  const isUserLocationDeleted = useSelector(selectIsUserLocationDeleted);
  const isKeyAccountUser = useSelector(selectIsKeyAccountUser);

  const isShortSupplySku = maximumQuantity === 0;
  const [showSegmentationPrice] = useLocalStorage('showSegmentationPrice', false);
  const segmentationPriceLabel = useMemo(
    () =>
      getContentByKey<string>(
        `${contentStackProductPath}.${isDesktop ? 'desktop' : 'mobile'}_segmentation_price_label`,
        ''
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getContentByKey, isDesktop]
  );

  const productCardClass = classNames(
    'product-card',
    'product-card--sku-product',
    {
      'product-card--discontinued': isDiscontinued,
      'product-card--added': added,
      'product-card--special-order': isSpecialOrder,
      'product-card--phasing-out': isPhasingOut,
    },
    className
  );

  const isDiscountinuedWithReplacement = isDiscontinued && !isEmpty(substitutionSku);
  const isImpersonateModeOn = useSelector(selectIsImpersonateModeOn);
  const isTsrUser = useSelector(selectIsTsrUser);
  const quantityInputRef = useRef<HTMLInputElement>(null);

  const addProduct = () => {
    if (sku && quantityInputRef.current) {
      dispatch(
        addProductToCart.request<IAddProductToCartPayload>({
          quantity: Number(quantityInputRef?.current.value),
          sku,
        })
      );
    }
  };

  const onProductButtonClick = () => {
    setIsButtonActive(true);
    addProduct();
    setButtonDisabled(true);
    setTimeout(() => {
      setIsButtonActive(false);
      setButtonDisabled(false);
    }, 1000);
  };

  const redirectToReplacementPDP = (data: ISkuInformation[]) => {
    history.push(`${Routes.ProductDetailsPage}/${data[0].productKey}?selectedSku=${data[0].sku}`);
  };

  const onAlternativeButtonClick = () => {
    if (substitutionSku) {
      dispatch(
        getSkuReplacements.request<IGetSkuReplacementsPayload>({
          skus: substitutionSku[0],
          onSuccessCallback: redirectToReplacementPDP,
        })
      );
    }
  };

  const addToCartButtonClass = classNames('product-card__add-to-cart', {
    active: isButtonActive,
    disabled: isProductBeingUpdated,
  });

  const footerPriceClass = classNames('product-card__footer-price', { segmentation: segmentationPrice });

  const renderFooterPrice = (
    <div className={footerPriceClass}>
      <div className="product-card__footer-price-group">
        {price && !isShortSupplySku && (
          <p className="product-card__price">
            {price.currencySymbol}
            {price.amount}
          </p>
        )}
        {showSegmentationPrice && segmentationPrice && (
          <p className="product-card__segmentation-price">
            <span className="product-card__segmentation-price-label">{segmentationPriceLabel}</span>
            {': '}
            <span>
              {segmentationPrice.currencySymbol}
              {segmentationPrice.amount}
            </span>
          </p>
        )}
      </div>

      {isInCart && <img className="icon-added-to-cart" src={iconAddedToCart} alt="added to cart" />}
    </div>
  );

  return (
    <section className={productCardClass}>
      <Link className="product-card__link" to={`${Routes.ProductDetailsPage}/${productKey}?selectedSku=${sku}`}>
        <div className="product-card__media">
          <ProductImage src={imageUrl} altText={name} desktopSize={imageDesktopSize} />
          <ProductIndicators
            size="large"
            seasonalLabel={seasonalIndicator?.title}
            seasonalLabelColor={seasonalIndicator?.color}
          />

          {(isDiscontinued || isSpecialOrder || isPhasingOut) && (
            <div className="product-card__statuses-wrapper">
              <ProductStatusLabels
                isDiscontinued={isDiscontinued}
                isSpecialOrder={isSpecialOrder}
                isPhasingOut={isPhasingOut}
              />
            </div>
          )}
        </div>
        <div className="product-card__info">
          {brand && <p className="product-card__brand">{brand}</p>}
          {name && (
            <h4 className="product-card__name" title={name}>
              {name}
            </h4>
          )}
          {packing && (
            <p className="product-card__packing">
              <ContentstackText contentKey={`${contentStackProductPath}.selling_unit_label`} /> {packing}
            </p>
          )}
          <p className="product-card__sku">
            <ContentstackText contentKey={`${contentStackProductPath}.item_number_label`} />{' '}
            {stripLeadingZeros(String(sku))}
          </p>
          {color && (
            <p className="product-card__color">
              <ContentstackText contentKey={`${contentStackProductPath}.color_label`} /> {color}
            </p>
          )}
          {flavor && (
            <p className="product-card__flavor">
              <ContentstackText contentKey={`${contentStackProductPath}.flavor_label`} /> {flavor}
            </p>
          )}
        </div>
      </Link>
      <div className="product-card__footer">
        {!isSpecialOrder && !isDiscontinued && !isShortSupplySku && (
          <div className="product-card__quantity">
            <span className="product-card__quantity-label">
              <ContentstackText contentKey={`${contentStackProductPath}.quantity_label`} />
            </span>
            <QuantityControl
              quantity={DEFAULT_PRODUCT_QUANTITY}
              maxQuantity={maximumQuantity}
              contentStackPath={contentStackProductPath}
              inputRef={quantityInputRef}
            />
          </div>
        )}
        {!isSpecialOrder && !isDiscontinued && renderFooterPrice}
        {isDiscountinuedWithReplacement && (
          <PermissionAccess requiredPermissions={Permissions[AccessDefinitions.SHOPPING_CART_ACTIONS]}>
            <CorButton
              color="tertiary"
              disabled={false}
              className="product-card--alternative-button"
              onClick={onAlternativeButtonClick}
            >
              <ContentstackText contentKey={`${contentStackProductPath}.see_alternative_button_label`} />
            </CorButton>
          </PermissionAccess>
        )}

        {!isSpecialOrder && !isDiscountinuedWithReplacement && (
          <PermissionAccess requiredPermissions={Permissions[AccessDefinitions.SHOPPING_CART_ACTIONS]}>
            <CorButton
              color="tertiary"
              disabled={buttonDisabled || isUserLocationDeleted || isDiscontinued || isShortSupplySku}
              className={addToCartButtonClass}
              onClick={onProductButtonClick}
            >
              {isButtonActive ? (
                <ContentstackText contentKey={`${contentStackProductPath}.add_to_cart_button_label_active`} />
              ) : (
                <ContentstackText contentKey={`${contentStackProductPath}.add_to_cart_button_label`} />
              )}
            </CorButton>
          </PermissionAccess>
        )}

        {isSpecialOrder && !isDiscountinuedWithReplacement && isKeyAccountUser && (
          <HierarchyAccountsSpecialOrderMessage />
        )}
        {isSpecialOrder && !isDiscountinuedWithReplacement && !isKeyAccountUser && (
          <div className="product-card__special-order-actions">
            {(!isImpersonateModeOn || (isImpersonateModeOn && !isTsrUser)) && (
              <>
                <CorButton
                  color="tertiary"
                  disabled={buttonDisabled || isUserLocationDeleted || isDiscontinued || isShortSupplySku}
                  className="product-card__request-quote-button"
                  onClick={() => sku && onRequestQuoteCtaClick(sku)}
                >
                  <ContentstackText contentKey={`${contentStackProductPath}.request_quote_button_label`} />
                </CorButton>
                <CustomTooltip
                  customIcon={!(isUserLocationDeleted || isDiscontinued) ? iconInfo : iconInfoDisabled}
                  showTooltip={!(isUserLocationDeleted || isDiscontinued)}
                  tooltipId={`${usageContext}-productCardSpecialOrder-${sku}`}
                  overrideTooltipPosition={overrideTooltipPosition}
                >
                  <ContentstackMessage type="tooltips" messageId="MSG107" />
                </CustomTooltip>
              </>
            )}
          </div>
        )}
      </div>
    </section>
  );
};
