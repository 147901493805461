import { FC } from 'react';
import { Link } from 'react-router-dom';
import { CorContentStackLanguageURLMap } from 'constants/cor-locale.enum';
import { useLocalStorage } from 'hooks/use-local-storage';
import { isInternationalSite } from 'utils/get-locale-params';
import { transformItem } from 'components/layout/cor-header/components/algolia-search/utils/collections';
import { QuickLinks } from 'components/layout/cor-header/components/algolia-search/components/quick-links';
import { useMainNavState } from 'components/layout/cor-header/context/headerContext';
import { Routes } from 'constants/routes.enum';

interface IAlgoliaSuggestionsProps {
  suggestions: any;
  isOpen: boolean;
  suggestionsRef: any;
  panelProps: any;
  listProps: any;
  isLoggedInUser: boolean;
  query: string;
  getItemProps: (...props) => any;
  handleSuggestionClick: () => any;
  afterClickQuickLink: () => void;
  globalFacets: any;
  onLoginLinkClick?: () => void;
}

const AlgoliaSuggestions: FC<IAlgoliaSuggestionsProps> = ({
  suggestions,
  isOpen,
  suggestionsRef,
  panelProps,
  listProps,
  isLoggedInUser,
  query,
  getItemProps,
  handleSuggestionClick,
  afterClickQuickLink,
  globalFacets,
  onLoginLinkClick,
}) => {
  const { header } = useMainNavState();
  const { source, items } = suggestions || {};
  const [locale] = useLocalStorage('locale', null);
  const loginLinkLabel = globalFacets?.login_link;
  const localeUrl = CorContentStackLanguageURLMap.get(locale);

  const highlightText = (text: string, query: string) => {
    const queryRegExp = new RegExp(`(${query})`, 'gi');
    const highlightedText = text?.replace(queryRegExp, '<span class="highlight-text">$1</span>');
    return { __html: highlightedText };
  };

  return (
    <>
      {isOpen && (
        <div className="search-field-suggestions" ref={suggestionsRef} {...panelProps}>
          <ul className="search-field-suggestions__list" {...listProps}>
            {items.map((item) => {
              const transformedItem = transformItem(item, isLoggedInUser);
              return (
                transformedItem?.itemName &&
                transformedItem?.itemUrl && (
                  <li
                    className="search-field-suggestions__item"
                    key={item.objectID}
                    {...getItemProps({
                      item,
                      source,
                    })}
                    onClick={handleSuggestionClick}
                  >
                    <Link
                      to={
                        isInternationalSite(locale)
                          ? `${localeUrl}${transformedItem.itemUrl}`
                          : `${transformedItem.itemUrl}`
                      }
                    >
                      <span
                        className="link-text-item"
                        dangerouslySetInnerHTML={highlightText(transformedItem.itemName, query)}
                      />
                    </Link>
                  </li>
                )
              );
            })}
            {items.length > 0 && (
              <li className="search-field-suggestions__item divider">
                <hr />
              </li>
            )}
            <li className="search-field-suggestions__item">
              <div className="custom-item">
                <span
                  className="emphasize-text"
                  dangerouslySetInnerHTML={highlightText(
                    `${query} ${header?.header_labels?.page_type_in || 'in '} `,
                    query
                  )}
                />
                <QuickLinks
                  query={query}
                  afterClickQuickLink={afterClickQuickLink}
                  globalFacets={globalFacets}
                  localeUrl={isInternationalSite(locale) && localeUrl}
                />
              </div>
            </li>
            {!isLoggedInUser && !isInternationalSite(locale) && (
              <li className="search-field-suggestions__item search-field-suggestions__login-link">
                <Link to={Routes.LoginPage} onClick={onLoginLinkClick}>
                  {loginLinkLabel}
                </Link>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default AlgoliaSuggestions;
