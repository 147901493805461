import React, { FC } from 'react';
import Slider, { CustomArrowProps } from 'react-slick';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { BuyItAgainSkusSliderItem } from 'features/order-updates/components/buy-it-again-skus-slider-item';
import { selectBuyItAgainProducts } from 'store/home/selectors';

import './buy-it-again-skus-slider.scss';

export const BuyItAgainSkusSliderArrow: FC<CustomArrowProps> = ({ className, onClick }) => {
  return <button className={className} onClick={onClick} />;
};

export interface IBuyItAgainSkusSliderProps {
  onRequestQuoteCtaClick?: (skuKey: string, isOpenedWithinQuickShop: boolean) => void;
}

export const BuyItAgainSkusSlider: React.FC<IBuyItAgainSkusSliderProps> = ({ onRequestQuoteCtaClick }) => {
  const buyItAgainProducts = useSelector(selectBuyItAgainProducts);

  const sliderSettings = {
    speed: 500,
    touchMove: false,
    dots: true,
    dotsClass: 'buy-it-again-skus-slider__dots slick-dots',
    prevArrow: <BuyItAgainSkusSliderArrow />,
    nextArrow: <BuyItAgainSkusSliderArrow />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          touchMove: true,
        },
      },
    ],
  };

  return (
    <Slider
      className={classNames('buy-it-again-skus-slider', {
        'buy-it-again-skus-slider--single-order': buyItAgainProducts?.length === 1,
      })}
      {...sliderSettings}
    >
      {buyItAgainProducts?.map((item, index) => (
        <BuyItAgainSkusSliderItem
          key={item.number}
          index={index}
          onRequestQuoteCtaClick={onRequestQuoteCtaClick}
          {...item}
        />
      ))}
    </Slider>
  );
};
